import React from "react";
import { CSS_VARS } from "../../common/css-variables";
import { LightHeader } from "../../common/light-header";
import { NavBar } from "../../common/nav-bar";
import { SummaryTitle } from "../../common/summary-title";
import { TextInput } from "../../common/text-input";
import { useTextInputHook } from "../../common/use-text-input-hook";
import { PageProps } from "../../interfaces";
import { isStringAnEmail } from "../../utils/email";
import { countryOptions, StateSelect } from "../home/state-select";
import { RadioSelect } from "../../common/radio-select";
import { STATUS, useLazyFetchHook } from "../../utils/use-fetch-hook";
import { isAddressValid } from "../../utils/address";
import { API, PATH } from "../../path";
import { useNavigate } from "react-router-dom";
import { COUNTRY, CountrySelect } from "../home/country-select";

const cameToChristQuestion =
  "Did you come to Christ through one of our Gospel books?";
const needBibleQuestion = "Do you need a Bible? (address required)";
const pastorToConnectQuestion = "Do you want a pastor to contact you?";

export function Welcome({ setTheme }: PageProps) {
  React.useEffect(() => {
    setTheme(CSS_VARS["--off-white"], false);
  }, [setTheme]);

  const [firstName, handleFirstNameChange] = useTextInputHook();
  const [lastName, handleLastNameChange] = useTextInputHook();
  const [email, handleEmailChange] = useTextInputHook();
  const [address, handleAddressChange] = useTextInputHook();
  const [city, handleCityChange] = useTextInputHook();
  const [country, setCountry] = React.useState(COUNTRY.US);
  const [state, handleStateChange] = React.useState(
    countryOptions[COUNTRY.US][0]
  );
  const [zip, handleZipChange] = useTextInputHook();
  const [phoneNumber, handlePhoneNumberChange] = useTextInputHook();

  const [cameToChrist, setCameToChrist] = React.useState("");
  const [needBible, setNeedBible] = React.useState("");
  const [pastorToConnect, setPastorToConnect] = React.useState("");

  const handleCountryChange = React.useCallback((newCountry: string) => {
    setCountry(newCountry as COUNTRY);
    handleStateChange(countryOptions[newCountry as COUNTRY][0]);
  }, []);

  const validEmail = Boolean(email && isStringAnEmail(email));
  const canSubmit = Boolean(
    firstName &&
      lastName &&
      validEmail &&
      (needBible !== "Yes" ||
        isAddressValid({
          country,
          address1: address,
          city,
          state,
          zip,
        })) &&
      needBible &&
      cameToChrist &&
      pastorToConnect
  );

  const [submissionState, sendForm] = useLazyFetchHook();

  const submitForm = React.useCallback(() => {
    if (canSubmit) {
      sendForm({
        method: "post",
        url: API.WELCOME_FORM,
        body: {
          "First Name": firstName,
          "Last Name": lastName,
          Email: email,
          Address: address,
          City: city,
          State: state,
          "Zip Code": zip,
          "Phone Number": phoneNumber,
          [cameToChristQuestion]: cameToChrist,
          [needBibleQuestion]: needBible,
          [pastorToConnectQuestion]: pastorToConnect,
        },
      });
    }
  }, [
    address,
    cameToChrist,
    canSubmit,
    city,
    email,
    firstName,
    lastName,
    needBible,
    pastorToConnect,
    phoneNumber,
    sendForm,
    state,
    zip,
  ]);

  const navigate = useNavigate();
  React.useEffect(() => {
    if (submissionState.status === STATUS.DONE) {
      navigate(PATH.FAQ);
    }
  }, [navigate, submissionState]);

  return (
    <div className="u-light" style={{ paddingBottom: 80 }}>
      <NavBar />
      <LightHeader
        title="Welcome, we're glad you're here."
        subTitle="Get a Bible, connect with a pastor, or just find a great local church."
      />
      <section
        className="rounded-section"
        style={{
          maxWidth: 500,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <SummaryTitle fontSize={18}>
          Tell us a little about yourself
        </SummaryTitle>
        <div className="rule rule--extra-thick rule--gray u-marginTop3gu u-marginBottom6gu" />
        <div className="u-flex u-flexCol">
          <div className="u-flex u-marginBottom5gu">
            <TextInput
              id="first-name"
              placeholder="*First Name"
              className="u-size1of2 u-marginRight3gu"
              value={firstName}
              onChange={handleFirstNameChange}
            />
            <TextInput
              id="last-name"
              placeholder="*Last Name"
              className="u-size1of2"
              value={lastName}
              onChange={handleLastNameChange}
            />
          </div>
          <TextInput
            id="email"
            placeholder="*Email"
            className="u-sizeFull u-marginBottom5gu"
            value={email}
            onChange={handleEmailChange}
          />
          <TextInput
            id="address"
            placeholder="Mailing Address"
            className="u-sizeFull u-marginBottom5gu"
            value={address}
            onChange={handleAddressChange}
          />
          <CountrySelect
            country={country}
            onCountryChange={handleCountryChange}
            wrapperClassName="u-marginBottom5gu"
            id="state"
          />
          <div className="u-flex u-marginBottom5gu">
            <TextInput
              id="city"
              placeholder="City"
              className="u-sizeFill"
              value={city}
              onChange={handleCityChange}
            />
            <div className="u-sm-hidden recipient-form__spacer" />
            <StateSelect
              className="u-size1of4"
              state={state}
              country={country}
              onStateChange={handleStateChange}
              id="state"
            />
            <div className="u-sm-hidden recipient-form__spacer" />
            <TextInput
              type="text"
              id="zip"
              placeholder="Zip"
              className="u-size1of4"
              inputProps={
                country === COUNTRY.US
                  ? {
                      inputMode: "numeric",
                      pattern: "[0-9]{5}",
                    }
                  : undefined
              }
              value={zip}
              onChange={handleZipChange}
            />
          </div>
          <TextInput
            id="phone-number"
            placeholder="Phone Number"
            className="u-sizeFull u-marginBottom6gu"
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
          />
          <RadioSelect
            answers={["Yes", "No", "Not Sure"]}
            id="came-to-christ"
            onSelect={setCameToChrist}
            question={cameToChristQuestion}
            selected={cameToChrist}
          />
          <RadioSelect
            answers={["Yes", "No, I have one"]}
            id="need-bible"
            onSelect={setNeedBible}
            question={needBibleQuestion}
            selected={needBible}
          />
          <RadioSelect
            answers={["By email", "By Phone", "No"]}
            id="pastor-to-connect"
            onSelect={setPastorToConnect}
            question={pastorToConnectQuestion}
            selected={pastorToConnect}
          />
        </div>
        <button
          className="button button--primary button--large"
          disabled={!canSubmit}
          onClick={submitForm}
        >
          Next
        </button>
      </section>
    </div>
  );
}
