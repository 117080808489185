import React from "react";
import { hasCorrectPassword } from "./common/with-preview-auth";

interface UserState {
  email: string | null;
  update: (email: string | null) => void;
  isPreviewAuthenticated: boolean;
  setIsPreviewAuthenticated: (isPreviewAuthenticated: boolean) => void;
}

export const UserContext = React.createContext<UserState>({
  email: null,
  update: () => undefined,
  isPreviewAuthenticated: true,
  setIsPreviewAuthenticated: () => undefined,
});

export function useUserState() {
  return React.useContext(UserContext);
}

const EMAIL_KEY = "EMAIL_KEY";

export function useProvideUserState(): UserState {
  const [isPreviewAuthenticated, setIsPreviewAuthenticated] =
    React.useState(hasCorrectPassword);
  const [email, setEmail] = React.useState<string | null>(() =>
    localStorage.getItem(EMAIL_KEY)
  );

  React.useEffect(() => {
    if (email) {
      localStorage.setItem(EMAIL_KEY, email);
    } else {
      localStorage.removeItem(EMAIL_KEY);
    }
  }, [email]);

  return {
    email,
    update: setEmail,
    isPreviewAuthenticated,
    setIsPreviewAuthenticated,
  };
}
