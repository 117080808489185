import React from "react";
import { STATUS } from "../../../../utils/use-fetch-hook";
import { TextInput } from "../../../../common/text-input";
import { FileInput, FILE_TYPE } from "../file-input";
import { useTextInputHook } from "../../../../common/use-text-input-hook";
import { AddOn } from "../../../home/interfaces";
import { useFormUploadHook } from "../use-form-upload-hook";
import { API } from "../../../../path";
import { getAddOnImageSrc } from "../../../../common/get-add-on-image-src";

interface AddOnEditorProps {
  onDone: () => void;
  onCancel: () => void;
  addOn?: AddOn;
}

interface AddOnUpload {
  title: string;
  description: string;
  price: string;
  ounces: string;
  abbreviation: string;
  image?: File;
}

export function AddOnEditor({ onDone, onCancel, addOn }: AddOnEditorProps) {
  const [title, handleTitleChange] = useTextInputHook(addOn?.title);
  const [description, handleDescriptionChange] = useTextInputHook(
    addOn?.description
  );
  const [price, handlePriceChange] = useTextInputHook(
    addOn ? String(addOn.price) : ""
  );
  const [ounces, handleOuncesChange] = useTextInputHook(
    addOn ? String(addOn.ounces) : ""
  );
  const [abbreviation, handleAbbreviationChange] = useTextInputHook(
    addOn?.abbreviation
  );
  const [image, handleImageChange] = React.useState<File>();

  const [uploadState, uploadAddOn] = useFormUploadHook<AddOnUpload, boolean>(
    API.ADD_ON,
    addOn?.id
  );

  const isDoneUploading =
    uploadState.status === STATUS.DONE && uploadState.payload;
  React.useEffect(() => {
    if (isDoneUploading) {
      onDone();
    }
  }, [isDoneUploading, onDone]);

  const isFilesReady = Boolean(addOn || image);
  const isReady = Boolean(
    title && description && price && isFilesReady && ounces
  );

  const addAddOn = React.useCallback(() => {
    if (title && description && price && abbreviation) {
      uploadAddOn({
        title,
        description,
        price,
        ounces,
        image,
        abbreviation,
      });
    }
  }, [uploadAddOn, title, description, price, ounces, image, abbreviation]);

  return (
    <div className="u-flex u-sizeFull">
      <div>
        <h4>Add New Add-On</h4>
        {uploadState.status === STATUS.ERROR && (
          <div
            className="u-flex u-flexAlignItemsCenter notification notification--warning"
            style={{
              margin: "0px -20px 35px",
              width: "calc(100% + 40px)",
            }}
          >
            <div>
              ERROR:{" "}
              {uploadState.error.toString
                ? uploadState.error.toString()
                : String(uploadState.error)}
            </div>
          </div>
        )}
        <TextInput
          id="title"
          placeholder="Title"
          className="u-marginBottom6gu"
          onChange={handleTitleChange}
          value={title}
        />
        <TextInput
          id="description"
          placeholder="Description"
          className="u-marginBottom6gu"
          onChange={handleDescriptionChange}
          value={description}
        />
        <TextInput
          id="price"
          placeholder="Price"
          className="u-marginBottom6gu"
          inputProps={{ inputMode: "numeric" }}
          value={price}
          onChange={handlePriceChange}
        />
        <TextInput
          id="ounces"
          placeholder="Weight in ounces"
          className="u-marginBottom6gu"
          inputProps={{ inputMode: "numeric" }}
          value={ounces}
          onChange={handleOuncesChange}
        />
        <TextInput
          id="abbreviation"
          placeholder="Abbreviation"
          className="u-marginBottom6gu"
          value={abbreviation}
          onChange={handleAbbreviationChange}
        />
        <FileInput
          id="add-on-image"
          label="Image"
          className="u-marginBottom6gu"
          accept={[FILE_TYPE.JPG, FILE_TYPE.PNG]}
          value={image}
          onChange={handleImageChange}
        />

        <button onClick={onCancel} className="button button--secondary">
          Cancel
        </button>
        <button
          disabled={!isReady}
          onClick={addAddOn}
          className="button button--primary"
        >
          Save
        </button>
      </div>
      {addOn && !image && (
        <div className="u-flexJustifyCenter u-flexAlignItemsCenter u-flex">
          <img
            style={{ maxHeight: 500 }}
            src={getAddOnImageSrc(addOn.id)}
            alt={addOn.title}
          />
        </div>
      )}
    </div>
  );
}
