import React from "react";
import { CSS_VARS } from "../../common/css-variables";
import { LightHeader } from "../../common/light-header";
import { NavBar } from "../../common/nav-bar";
import { SummaryTitle } from "../../common/summary-title";
import { TextInput } from "../../common/text-input";
import { useTextInputHook } from "../../common/use-text-input-hook";
import { PageProps } from "../../interfaces";
import { API } from "../../path";
import { isStringAnEmail } from "../../utils/email";
import { STATUS, useLazyFetchHook } from "../../utils/use-fetch-hook";

export function Support({ setTheme }: PageProps) {
  React.useEffect(() => {
    setTheme(CSS_VARS["--off-white"], false);
  }, [setTheme]);

  const [orderNumber, handleOrderNumberChange] = useTextInputHook();
  const [firstName, handleFirstNameChange] = useTextInputHook();
  const [lastName, handleLastNameChange] = useTextInputHook();
  const [email, handleEmailChange] = useTextInputHook();
  const [question, handleQuestionChange] = useTextInputHook();

  const validEmail = Boolean(email && isStringAnEmail(email));
  const canSubmit = Boolean(firstName && lastName && validEmail && question);

  const [submissionState, sendForm] = useLazyFetchHook();

  const submitForm = React.useCallback(() => {
    if (canSubmit) {
      sendForm({
        method: "post",
        url: API.SUPPORT_FORM,
        body: {
          "Order Number": orderNumber,
          "First Name": firstName,
          "Last Name": lastName,
          Email: email,
          Question: question,
        },
      });
    }
  }, [canSubmit, email, firstName, lastName, orderNumber, question, sendForm]);

  return (
    <div
      className="u-light"
      style={{ minHeight: "calc(100vh - 56px)", paddingBottom: 80 }}
    >
      <NavBar />
      <LightHeader
        title="How can we help?"
        subTitle="We usually respond within a few hours"
      />
      <section
        className="rounded-section"
        style={{
          maxWidth: 500,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {(submissionState.status === STATUS.DONE ||
          submissionState.status === STATUS.LOADING) && (
          <div className="u-textCenter">
            <SummaryTitle fontSize={24}>
              {submissionState.status === STATUS.DONE
                ? "Sent! Someone will contact you shortly."
                : "Sending..."}
            </SummaryTitle>
          </div>
        )}
        {(submissionState.status === STATUS.IDLE ||
          submissionState.status === STATUS.ERROR) && (
          <React.Fragment>
            <SummaryTitle fontSize={18}>Support Ticket</SummaryTitle>
            <div className="rule rule--extra-thick rule--gray u-marginTop3gu u-marginBottom6gu" />
            <div className="u-flex u-flexCol">
              <div className="u-flex u-marginBottom5gu">
                <TextInput
                  id="first-name"
                  placeholder="First Name"
                  className="u-size1of2 u-marginRight3gu"
                  value={firstName}
                  onChange={handleFirstNameChange}
                />
                <TextInput
                  id="last-name"
                  placeholder="Last Name"
                  className="u-size1of2"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </div>
              <TextInput
                id="email"
                placeholder="Email"
                className="u-sizeFull u-marginBottom5gu"
                value={email}
                onChange={handleEmailChange}
              />
              <TextInput
                id="order-number"
                placeholder="Order Number (if applicable)"
                className="u-sizeFull u-marginBottom5gu"
                value={orderNumber}
                onChange={handleOrderNumberChange}
              />
              <textarea
                spellCheck="true"
                className="u-sizeFull u-marginBottom5gu"
                name="support-question"
                rows={4}
                id="support-question"
                placeholder="What is your support question"
                value={question}
                onChange={handleQuestionChange}
              />
            </div>
            <div className="u-sizeFull rule rule--black u-marginBottom4gu" />
            <button
              className="button button--primary"
              disabled={!canSubmit}
              onClick={submitForm}
            >
              Submit
            </button>
            {submissionState.status === STATUS.ERROR && (
              <div className="u-textCenter">
                <SummaryTitle fontSize={24}>
                  Uh Oh! Unable to submit!
                </SummaryTitle>
              </div>
            )}
          </React.Fragment>
        )}
      </section>
    </div>
  );
}
