import React from "react";
import { CSS_VARS } from "../../common/css-variables";
import { LightHeader } from "../../common/light-header";
import { NavBar } from "../../common/nav-bar";
import { SummaryTitle } from "../../common/summary-title";
import { PageProps } from "../../interfaces";

export function PrivacyPolicy({ setTheme }: PageProps) {
  React.useEffect(() => {
    setTheme(CSS_VARS["--off-white"], false);
  }, [setTheme]);
  return (
    <div
      className="u-light"
      style={{ minHeight: "calc(100vh - 56px)", paddingBottom: 80 }}
    >
      <NavBar />
      <LightHeader
        title="Privacy Policy"
        subTitle="Privacy Policy for Mail the Gospel"
      />
      <section
        className="rounded-section"
        style={{
          marginLeft: 30,
          marginRight: 30,
        }}
      >
        <SummaryTitle fontSize={18}>OUR COMMITMENT TO PRIVACY</SummaryTitle>
        <p>
          Mail the Gospel knows that you care how information about you is used
          and shared, and we appreciate you trusting that we will do so
          carefully and sensibly. This Privacy Policy explains our practices and
          the choices you can make about the way your information is collected
          and used by Mail the Gospel. It applies to both user and donor
          information that we collect both online and offline.
        </p>

        <SummaryTitle fontSize={18}>
          WHAT INFORMATION IS COLLECTED BY MAIL THE GOSPEL?
        </SummaryTitle>
        <p>
          We collect personal information when you voluntarily submit it to us.
          For example, users or donors may provide us with personal information
          (such as name, address, email address, telephone numbers, and/or
          credit/debit card information). If a user or donor, or a website
          visitor, contacts us by email they may provide us with their email
          address, and name. Providing data is required in these contexts in
          order for us to process your purchases, donations, requests, or
          respond to inquiries. In addition to the personal information that you
          submit to us, we may collect personal information about you from
          reputable third parties, or automatically collect information using
          the following technologies:
          <ol>
            <li>
              <b>Web Server Logs.</b>
              Our web servers may collect certain information such as IP
              address, pages visited, time of visits, and referring website.
            </li>
            <li>
              <b>Cookies.</b> Cookies are simple text files stored by your web
              browser that provide a method of distinguishing between users,
              donors, and visitors to the website. Mail the Gospel uses cookies
              to identify your browser as you visit pages on the Mail the Gospel
              website or sites in the Relearn.org media network. Cookies also
              allow Mail the Gospel to provide more relevant, targeted content
              as you travel through sites in the Relearn.org media network as
              appropriate and are used on our ordering website to keep track of
              the items in your shopping cart.
            </li>
          </ol>
        </p>

        <SummaryTitle fontSize={18}>
          WHERE DO WE TRANSFER AND STORE INFORMATION?
        </SummaryTitle>
        <p>
          Mail the Gospel is based in the United States, and any information
          transmitted to us may be stored, accessed, or processed in the United
          States. Residents of some other countries, including residents of the
          European Union, should be aware that the laws of the United States do
          not offer the same protections as the laws of your home country.
        </p>
        <SummaryTitle fontSize={18}>HOW DO WE USE INFORMATION?</SummaryTitle>
        <p>
          We collect information primarily to accept donations, send charitable
          products, and provide you with information concerning our programs,
          projects, and activities. We also use the information for internal
          purposes (to better understand our supporter network) and for
          analytical purposes (to respond to requests for information or
          merchandise and to improve our website, donor base, and outreach). We
          may inform you of other purposes at the time that we collect some
          forms of information. For example, if you apply for a job we may
          collect information in order to process your job application.
        </p>
        <SummaryTitle fontSize={18}>
          WITH WHOM DO WE SHARE INFORMATION?
        </SummaryTitle>
        <p>
          We do not trade, share, or sell information that you provide to us
          with other organizations who intend to use it only to market their
          products or services to you.
        </p>
        <p>
          There may be instances in which we share your information with others.
          We may share your information, for example, in conjunction with your
          sponsorship of a certain project, as required by law, with law
          enforcement, to comply with legal process, to protect our legal
          rights, to protect the safety or security of a person, or to protect
          against fraud, criminal acts, or misconduct. We may also share your
          information with companies that provide us with services, or with a
          successor legal entity.
        </p>
        <SummaryTitle fontSize={18}>
          OUR COMMITMENT TO DATA SECURITY
        </SummaryTitle>
        <p>
          To help prevent unauthorized access, maintain data accuracy, and
          ensure the correct use of information, we have put in place
          appropriate physical, electronic, and managerial procedures to
          safeguard and secure the information we collect. For example, we
          secure our machines with mandated encryption protocols to secure our
          user/company data. We also use industry-accepted tools and techniques
          such as SSL and two-factor authentication to protect against
          unauthorized access to our systems. Additionally, all sensitive
          information is stored in secure repositories both in-house as well as
          secure cloud-based, AES encrypted web apps.
        </p>
        <SummaryTitle fontSize={18}>
          YOUR ACCESS TO DATA, AND YOUR CHOICES
        </SummaryTitle>
        <p>
          If you would like to access the data that we received from you or make
          corrections to it, you may contact us at the email or mailing
          addresses provided at the end of this policy.
        </p>
        <p>
          If you registered to receive communications from us and later change
          your mind, you may ask that your name and contact information be
          removed from our distribution list. You can send such a request to the
          email or mailing addresses at the end of this policy.
        </p>
        <p>
          Some web browsers and devices permit you to broadcast a preference
          that you not be "tracked" online. At this time we do not modify your
          experience based upon whether such a signal is broadcast.
        </p>
        <SummaryTitle fontSize={18}>ADDITIONAL INFORMATION</SummaryTitle>
        <ol>
          <li>
            <b>Behavioral Advertising.</b> We may participate in
            behavioral-based advertising. This means that a third party may use
            technology (e.g., a cookie) to collect information about your use of
            our website so that they can provide advertising about products and
            services tailored to your interest. That advertising may appear
            either on our website or on other websites.
          </li>
          <li>
            <b>Links.</b> Our website may contain links to other websites.
            Please note that when you click on one of these links, you are
            entering another site that is governed by that site's privacy
            policy.
          </li>
        </ol>
        <p>
          We implement Google's Invisible reCAPTCHA to protect our site from
          spam and abuse. Use of this service is subject to Google's privacy
          policy and terms.
        </p>
        <p>
          Changes to this privacy statement. We may modify this privacy policy
          from time to time. Any changes will be reflected on this website. If
          we make a material change to this policy and need to apply it to
          personal information that you provided to us before the change was
          made, we will attempt to contact you and obtain your consent to the
          change.
        </p>
        <p>
          If we need or are required to, contact you about the privacy or
          security of your information we may do so by telephone, email, or
          mail. If you have any questions, concerns, requests, or comments about
          privacy, you can contact us by email, telephone, or mail at:
          <a href="mailto:support@mailthegospel.org">
            support@mailthegospel.org
          </a>
        </p>
        <b>Relearn.org (Parent ministry for Mail the Gospel)</b>
        <b>240 Jennifer Drive #102</b>
        <b>Cottonwood, AZ 86326</b>
      </section>
    </div>
  );
}
