import React from "react";
import ReactDom from "react-dom";
import "./modal.css";

const modalRoot = document.getElementById("modal-root");

export function Modal({ children }: { children: React.ReactElement }) {
  if (!modalRoot) {
    return <></>;
  }
  return ReactDom.createPortal(
    <div className="overlay">{children}</div>,
    modalRoot
  );
}
