import React from "react";
import { createAddress } from "../../../utils/address";
import { RecipientUpdate } from "../../../interfaces";
import { StepProps } from "../interfaces";
import { FooterNav } from "../../../common/footer-nav";
import { TractPreview } from "../../../common/tract/preview";
import { TractTitle } from "../../../common/tract/title";
import { AddOnSlider } from "./add-on-slider";
import { RecipientForm } from "./recipient-form";
import { MoreTracts } from "./more-tracts";
import "./who-is-receiving.css";
import { STATUS } from "../../../utils/use-fetch-hook";
import { getTractBackground } from "../../../common/get-tract-image-url";
import { RecipientPayload } from "../../../../../server/src/contracts/recipient";
import { defaultShipping } from "../default-shipping";
import { hasAllRecipientsFilledOut } from "../../../common/order-validation";

let count = 0;

function createRecipient(): RecipientPayload {
  return {
    ...createAddress(),
    id: String(Date.now()) + count++,
    lastName: "",
    firstName: "",
    personalizedMessage: "",
    hasPersonalizedMessage: true,
    addOnId: null,
    shippingCost: defaultShipping.price,
    displayShippingCost: defaultShipping.displayPrice,
    shippingServiceType: defaultShipping.serviceType,
  };
}

export function WhoIsReceiving({
  setTheme,
  onNext,
  onPrev,
  productsState,
  orderInformation,
  onRecipientUpdate,
  setOrderInformation,
}: StepProps) {
  const addRecipient = React.useCallback(
    (tractId: string) => {
      setOrderInformation((prev) => ({
        ...prev,
        tracts: prev.tracts.map((tract) => ({
          ...tract,
          recipients:
            tract.tractId === tractId
              ? [...tract.recipients, createRecipient()]
              : [...tract.recipients],
        })),
      }));
    },
    [setOrderInformation]
  );

  /** add at least one recipient per tract */
  React.useEffect(() => {
    setOrderInformation((prev) => {
      return {
        ...prev,
        tracts: prev.tracts.map((tract) => {
          if (tract.recipients.length === 0) {
            tract.recipients.push(createRecipient());
          }
          return tract;
        }),
      };
    });
  }, [setOrderInformation]);

  const products =
    productsState.status === STATUS.DONE ? productsState.payload : undefined;
  const tracts = products?.tracts;
  const addOns = products?.addOns;

  const findTractById = React.useCallback(
    (id: string) => tracts?.find((tract) => tract.id === id),
    [tracts]
  );

  const firstTract = findTractById(orderInformation.tracts[0].tractId);
  React.useEffect(() => {
    if (firstTract) {
      setTheme(
        getTractBackground(firstTract),
        true,
        firstTract.backgroundColorEnd
      );
    }
  }, [setTheme, firstTract]);

  const removeRecipient = React.useCallback(
    ({ recipientId, tractId }: RecipientUpdate) => {
      setOrderInformation((prevOrder) => {
        const tractOrder = prevOrder.tracts.find(
          (tract) => tract.tractId === tractId
        );

        // if last recipient for this tract, remove tract entirely
        if (tractOrder?.recipients.length === 1) {
          return {
            ...prevOrder,
            tracts: prevOrder.tracts.filter(
              (tract) => tract.tractId !== tractId
            ),
          };
        }
        // otherwise, just remove the tract
        return {
          ...prevOrder,
          tracts: prevOrder.tracts.map((tract) => {
            if (tract.tractId === tractId) {
              tract.recipients = tract.recipients.filter(
                (recipient) => recipient.id !== recipientId
              );
            }
            return tract;
          }),
        };
      });
    },
    [setOrderInformation]
  );

  const handleTractAdd = React.useCallback(
    (tractId: string) => {
      setOrderInformation((prev) => ({
        ...prev,
        tracts: prev.tracts.concat({
          tractId,
          recipients: [createRecipient()],
        }),
      }));
    },
    [setOrderInformation]
  );

  if (productsState.status === STATUS.LOADING) {
    return <h3 className="u-text-offWhite step-header">Loading tracts...</h3>;
  }

  if (productsState.status === STATUS.ERROR || !tracts || !addOns) {
    return (
      <h3 className="u-text-offWhite step-header">
        Uh oh! Something went wrong!
      </h3>
    );
  }

  const notChosenTracts = tracts.filter((tract) =>
    orderInformation.tracts.every(
      (tractOrder) => tractOrder.tractId !== tract.id
    )
  );

  const totalRecipients = orderInformation.tracts.reduce(
    (total, tractOrder) => total + tractOrder.recipients.length,
    0
  );

  return (
    <>
      <h3 className="u-text-offWhite step-header">Who's receiving</h3>
      {orderInformation.tracts.map(({ tractId, recipients }, index) => {
        const tract = findTractById(tractId);

        const nextTractOrder =
          index + 1 === orderInformation.tracts.length
            ? undefined
            : orderInformation.tracts[index + 1];

        const nextTract = nextTractOrder
          ? findTractById(nextTractOrder.tractId)
          : undefined;

        if (!tract) {
          return <React.Fragment key={tractId} />;
        }
        return (
          <React.Fragment key={tractId}>
            <div className="u-flex u-flexCol u-flexAlignItemsCenter u-textCenter">
              <TractTitle {...tract} />
              <div className="u-flex u-sm-flexCol u-flexAlignSelfStart u-sizeFull">
                <div className="u-sm-hidden u-size7of12">
                  <TractPreview {...tract} hasExpandMargin />
                </div>
                <div className="u-size5of12 forms-container">
                  {recipients.map((recipient, index) => (
                    <React.Fragment key={recipient.id}>
                      {index !== 0 && (
                        <div className="rule rule--thick rule--transparent forms-container__divider" />
                      )}
                      <RecipientForm
                        key={recipient.id}
                        tractId={tractId}
                        recipient={recipient}
                        onChange={onRecipientUpdate}
                        hasClose={totalRecipients !== 1}
                        onRemove={removeRecipient}
                      />
                      {addOns.length > 0 && (
                        <AddOnSlider
                          addOns={addOns}
                          onChange={onRecipientUpdate}
                          tractId={tractId}
                          recipient={recipient}
                        />
                      )}
                    </React.Fragment>
                  ))}
                  <button
                    onClick={() => {
                      addRecipient(tractId);
                    }}
                    className="u-sizeFull button button--primary add-recipient"
                  >
                    Add Recipient
                  </button>
                  {!nextTract &&
                    orderInformation.tracts.length !== tracts.length && (
                      <MoreTracts
                        tracts={notChosenTracts}
                        onSelect={handleTractAdd}
                      />
                    )}
                </div>
              </div>
            </div>
            {nextTract && (
              <div
                style={{
                  height: 100,
                  width: "100%",
                }}
              >
                <div className="rule rule--offWhite u-opacity5 tract-divider" />
              </div>
            )}
          </React.Fragment>
        );
      })}
      <FooterNav
        onNext={onNext}
        prevButton={
          <button
            onClick={onPrev}
            className="button button--secondary button--large button--sm-arrow-left u-marginRight2gu"
          >
            <div className="u-md-hidden u-lg-hidden">&nbsp;</div>
            <div className="u-sm-hidden">Back</div>
          </button>
        }
        isReadyToGoNext={hasAllRecipientsFilledOut(orderInformation)}
      />
    </>
  );
}
