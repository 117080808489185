import { API } from "../path";
import { useFetchHook } from "../utils/use-fetch-hook";
import { Products } from "../pages/home/interfaces";

export function useGetProductsHook() {
  const productsState = useFetchHook<null, Products>({
    method: "get",
    url: API.PRODUCTS,
  });
  return productsState;
}
