import React from "react";

export function ViewDate(props: { date: string }) {
  const date = React.useMemo(() => {
    const d = new Date(props.date);
    const dateString = d.toLocaleDateString("en-US");
    const timeString = d.toLocaleTimeString("en-US");
    return `${dateString} ${timeString}`;
  }, [props.date]);
  return <>{date}</>;
}
