import React from "react";
import { TextInput } from "../../../common/text-input";

export function SearchBar({
  onSearchChange,
  initialEmailSearch,
}: {
  initialEmailSearch: string;
  onSearchChange: (newEmailSearch: string) => void;
}) {
  const [emailSearch, setEmailSearch] = React.useState(initialEmailSearch);

  return (
    <div
      className="u-bg-white u-padding3gu u-flex u-flexAlignItemsCenter"
      style={{ borderBottom: "1px solid black" }}
    >
      <h5 className="u-marginRight2gu">Email</h5>
      <TextInput
        id="evangelist-email"
        onChange={(e) => {
          setEmailSearch(e.target.value);
        }}
        placeholder="Search by email"
        value={emailSearch}
        type="text"
      />

      <button
        onClick={() => {
          onSearchChange(emailSearch);
        }}
        className="button button--primary"
      >
        Search
      </button>
    </div>
  );
}
