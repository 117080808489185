import React from "react";
import "./footer-nav.css";

interface FooterNavProps {
  prevButton?: React.ReactElement;
  continueButton?: React.ReactElement;
  isReadyToGoNext: boolean;
  onNext: () => void;
}

export function FooterNav({
  isReadyToGoNext,
  onNext,
  prevButton,
  continueButton,
}: FooterNavProps) {
  return (
    <footer className="u-sizeFull u-flex u-flexAlignItemsCenter u-flexJustifyEnd footer-nav">
      {prevButton}
      {continueButton || (
        <button
          disabled={!isReadyToGoNext}
          onClick={onNext}
          className="button button--primary button--large u-sm-sizeFull"
        >
          Continue
        </button>
      )}
    </footer>
  );
}
