import React from "react";

export function PreviewContainer({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div
      className="rounded u-border-cream"
      style={{ padding: "30px 30px 15px", marginBottom: 15 }}
    >
      {children}
    </div>
  );
}

export function PreviewTitle({
  message,
  ...rest
}: { message: string } & Omit<
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLHeadingElement>,
    HTMLHeadingElement
  >,
  "style"
>) {
  return (
    <h4 {...rest} style={{ margin: "0 45px 0 0", minWidth: "7ch" }}>
      {message}
    </h4>
  );
}

export function PreviewRule() {
  return <div className="rule rule--extra-thick rule--gray u-marginVert3gu" />;
}
