import React from "react";
import { OrderSearchAll } from "../../../../../server/src/contracts/orders";
import { TextInput } from "../../../common/text-input";
import { Select } from "../../../common/select";
import { Mode } from ".";

const statuses: Array<"Unfulfilled" | "Shipped"> = ["Unfulfilled", "Shipped"];

const modeOptions: Mode[] = ["date-range", "id", "status"];

const modeDisplay: Record<Mode, string> = {
  "date-range": "Date Range",
  id: "Order ID",
  status: "Order Status",
};

function formatModeOption(mode: Mode): string {
  return modeDisplay[mode];
}

export function SearchBar({
  mode,
  initialSearch,
  onSearchChange,
  onModeChange,
  onPrint,
}: {
  mode: Mode;
  initialSearch: OrderSearchAll;
  onSearchChange: (newSearch: OrderSearchAll) => void;
  onModeChange: (newMode: Mode) => void;
  onPrint: () => void;
}) {
  const [searchId, setSearchId] = React.useState<string>(initialSearch.orderId);
  const handleSearchIdChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        setSearchId(e.target.value);
      },
      [setSearchId],
    );

  const [startDate, setStartDate] = React.useState(initialSearch.startDate);
  const handleStartDateChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        setStartDate(e.target.value);
      },
      [setStartDate],
    );

  const [endDate, setEndDate] = React.useState(initialSearch.endDate);
  const handleEndDateChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        setEndDate(e.target.value);
      },
      [setEndDate],
    );

  const [displayStatus, setDisplayStatus] = React.useState<
    "Unfulfilled" | "Shipped"
  >(initialSearch.displayStatus);

  return (
    <div
      className="u-bg-white u-padding3gu u-flex u-flexAlignItemsCenter"
      style={{ borderBottom: "1px solid black" }}
    >
      <h5 className="u-marginRight2gu">Search By</h5>
      <Select
        id="transaction-search-mode"
        onChange={onModeChange}
        options={modeOptions}
        formatOption={formatModeOption}
        value={mode}
        selectStyle={{ width: 200 }}
      />
      {mode === "id" && (
        <TextInput
          id="transaction-id"
          onChange={handleSearchIdChange}
          placeholder="Order Number"
          value={searchId}
          type="text"
        />
      )}
      {mode === "status" && (
        <Select<"Unfulfilled" | "Shipped">
          id="transaction-status"
          onChange={setDisplayStatus}
          value={displayStatus}
          options={statuses}
        />
      )}
      {mode === "date-range" && (
        <>
          <label>
            Start Date:
            <input
              type="date"
              name="transaction-start-date"
              value={startDate}
              onChange={handleStartDateChange}
            />
          </label>
          <label>
            End Date:
            <input
              type="date"
              name="transaction-start-date"
              value={endDate}
              onChange={handleEndDateChange}
            />
          </label>
        </>
      )}
      <button
        onClick={() => {
          onSearchChange({
            page: 1,
            orderId: searchId,
            startDate,
            endDate,
            displayStatus,
          });
        }}
        className="button button--primary"
      >
        Search
      </button>
      <button
        onClick={() => {
          onPrint();
        }}
        className="button button--secondary u-marginLeft2gu"
      >
        Print
      </button>
    </div>
  );
}
