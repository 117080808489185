import React from "react";
import { Address } from "../../../../../server/src/contracts/address";
import { TextInput } from "../../../common/text-input";
import { isAddressValid } from "../../../utils/address";
import { isStringAnEmail } from "../../../utils/email";
import { AddressForm } from "../../order-review/address-form";
import { CustomerInfo } from "./types";

interface EnterBillingProps {
  initialCustomerInfo: CustomerInfo;
  onDone: (newCustomerInfo: CustomerInfo) => void;
}

export function CollectCustomerInfo({
  initialCustomerInfo,
  onDone,
}: EnterBillingProps) {
  const [customerInfo, setCustomerInfo] = React.useState({
    ...initialCustomerInfo,
  });
  const handleFullNameChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        setCustomerInfo((p) => ({
          ...p,
          fullName: e.target.value,
        }));
      },
      [setCustomerInfo]
    );

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        setCustomerInfo((p) => ({
          ...p,
          email: e.target.value,
        }));
      },
      [setCustomerInfo]
    );

  const handleAddressChange = React.useCallback(
    (newAddress: Address) => {
      setCustomerInfo((p) => ({
        ...p,
        billingAddress: newAddress,
      }));
    },
    [setCustomerInfo]
  );

  return (
    <div className="u-flex u-flexCol">
      <h4>Enter Customer Information</h4>
      <div>
        <TextInput
          id="full-name"
          onChange={handleFullNameChange}
          placeholder="Full Name"
          value={customerInfo.fullName}
        />
        <TextInput
          id="email"
          onChange={handleEmailChange}
          placeholder="Email"
          value={customerInfo.email}
          className="u-marginTop6gu"
        />
      </div>
      <AddressForm
        initialAddress={customerInfo.billingAddress}
        onAddressChange={handleAddressChange}
        addressPlaceholder="Address"
      />
      <button
        disabled={Boolean(
          !customerInfo.fullName ||
            !isStringAnEmail(customerInfo.email) ||
            !isAddressValid(customerInfo.billingAddress)
        )}
        onClick={() => {
          onDone(customerInfo);
        }}
        className="button button--primary button--large u-marginTop3gu u-flexExpandLeft"
      >
        Next
      </button>
    </div>
  );
}
