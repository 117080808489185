import React from "react";
import { Select } from "../../common/select";

export enum COUNTRY {
  US = "US",
  CA = "CA",
}

const countryCodeMap: Record<COUNTRY, string> = {
  [COUNTRY.US]: "United States",
  [COUNTRY.CA]: "Canada",
};

export function displayCountry(country: string): string {
  return countryCodeMap[country as COUNTRY] || country;
}

interface CountrySelectProps {
  id: string;
  country: string;
  onCountryChange: (newState: string) => void;
  wrapperClassName?: string;
}

function getWrapperClassName(className?: string): string {
  if (className) {
    return `${className} u-sizeFull`;
  } else {
    return "u-sizeFull";
  }
}

export function CountrySelect({
  id,
  country,
  onCountryChange,
  wrapperClassName,
}: CountrySelectProps) {
  const unique = `country-select-input-${id}`;

  return (
    <Select<COUNTRY>
      id={unique}
      onChange={onCountryChange}
      options={Object.keys(COUNTRY) as COUNTRY[]}
      formatOption={displayCountry}
      value={country as COUNTRY}
      wrapperClassName={getWrapperClassName(wrapperClassName)}
      selectClassName="u-sizeFull"
    />
  );
}
