import React from "react";
import { getTractCoverImage } from "../get-tract-image-url";
import { STATUS } from "../../utils/use-fetch-hook";
import { useFileReader } from "../../utils/use-file-reader";
import { KNOWN_TITLES } from "./known-titles";
import "./preview.css";
import { Tract } from "../../../../server/src/contracts/tract";
import { TractImageGallery } from "./image-gallery";

const titleToTractGraphic: Record<KNOWN_TITLES, string> = {
  [KNOWN_TITLES.HOPE]: "/assets/tract-backgrounds/hope.svg",
  [KNOWN_TITLES.JOY]: "/assets/tract-backgrounds/joy.svg",
  [KNOWN_TITLES.LOVE]: "/assets/tract-backgrounds/love.svg",
};

export function TractPreview({
  id,
  file,
  subTitle,
  isNewTract,
  images,
  hasExpandMargin = false,
  hasGallery = false,
}: Tract & {
  file?: File;
  isNewTract?: boolean;
  hasExpandMargin?: boolean;
  hasGallery?: boolean;
}) {
  const tractGraphic = titleToTractGraphic[subTitle as KNOWN_TITLES];
  const readerResult = useFileReader(file);

  const hasTractImage = Boolean(
    readerResult.status === STATUS.DONE ||
      (!isNewTract && readerResult.status !== STATUS.ERROR)
  );

  return (
    <div
      className={`u-flex u-flexJustifyCenter u-bg-transition tract-preview${
        hasExpandMargin ? " tract-preview--expand-margin" : ""
      }${hasGallery ? " tract-preview--with-gallery" : ""}`}
    >
      {hasTractImage && tractGraphic && (
        <img
          className="tract-preview__background"
          src={tractGraphic}
          alt=""
          height="30"
        />
      )}
      {readerResult.status === STATUS.DONE ? (
        <img
          className="tract-preview__cover"
          src={readerResult.result}
          alt="cover of tract"
        />
      ) : readerResult.status === STATUS.ERROR ? (
        <>Unable to process uploaded image</>
      ) : readerResult.status === STATUS.LOADING ? (
        <>Processing image...</>
      ) : isNewTract ? (
        <></>
      ) : hasGallery ? (
        <>
          <div
            className="u-flex u-flexJustifyCenter"
            style={{ position: "relative" }}
          >
            <TractImageGallery
              className="tract-preview__gallery"
              images={images}
            />
            <img
              className="tract-preview__cover"
              src={getTractCoverImage(id)}
              alt="cover of tract"
            />
          </div>
        </>
      ) : (
        <img
          className="tract-preview__cover"
          src={getTractCoverImage(id)}
          alt="cover of tract"
        />
      )}
    </div>
  );
}
