import React from "react";
import { RecipientUpdate } from "../../../interfaces";
import { RadioInput } from "../../../common/radio-input";
import "./recipient-form.css";
import { StateSelect } from "../state-select";
import {
  MESSAGE_LIMIT,
  useEditRecipientHook,
} from "../use-edit-recipient-hook";
import { PersonalizedMessageModal } from "../../../personalized-message-modal";
import { RecipientPayload } from "../../../../../server/src/contracts/recipient";
import { COUNTRY, CountrySelect } from "../country-select";
import { MAX_LENGTH } from "../../../input-limits";

interface RecipientFormProps {
  recipient: RecipientPayload;
  tractId: string;
  onChange: (recipientUpdate: RecipientUpdate) => void;
  hasClose: boolean;
  onRemove: (recipientUpdate: RecipientUpdate) => void;
}

export function RecipientForm({
  recipient,
  tractId,
  onChange,
  hasClose,
  onRemove,
}: RecipientFormProps) {
  const recipientId = recipient.id;

  const {
    handleAddress1Change,
    handleAddress2Change,
    handleCityChange,
    handleFirstNameChange,
    handleLastNameChange,
    handleStateChange,
    handleCountryChange,
    handlePersonalizedMessage,
    handleZipChange,
    setAnonymous,
    setPersonalized,
  } = useEditRecipientHook({ tractId, recipientId, onChange });

  const [isViewingMessage, setIsViewingMessage] = React.useState(false);
  const stopViewingMessage = React.useCallback(() => {
    setIsViewingMessage(false);
  }, [setIsViewingMessage]);
  const startViewingMessage = React.useCallback(() => {
    setIsViewingMessage(true);
  }, [setIsViewingMessage]);

  return (
    <div className="recipient-form rounded u-flex u-flexWrap u-flexJustifyBetween u-flexAlignItemsCenter">
      {hasClose && (
        <button
          onClick={() => {
            onRemove({ recipientId, tractId, partialRecipient: {} });
          }}
          className="button--close u-flexExpandLeft"
        />
      )}
      <input
        type="text"
        name="first-name"
        id={`${recipientId}-first-name`}
        placeholder="First Name"
        maxLength={MAX_LENGTH.CustomerName / 2}
        value={recipient.firstName}
        className="u-sizeFill"
        onChange={handleFirstNameChange}
      />
      <div className="u-sm-hidden recipient-form__spacer" />
      <input
        type="text"
        name="last-name"
        id={`${recipientId}-last-name`}
        placeholder="Last Name"
        className="u-sizeFill"
        maxLength={MAX_LENGTH.CustomerName / 2}
        value={recipient.lastName}
        onChange={handleLastNameChange}
      />
      <input
        type="text"
        name="address-1"
        id={`${recipientId}-address-1`}
        placeholder="Address 1"
        maxLength={MAX_LENGTH.Address1}
        className="u-sizeFull"
        value={recipient.address1}
        onChange={handleAddress1Change}
      />
      <input
        type="text"
        name="address-2"
        id={`${recipientId}-address-2`}
        placeholder="Address 2"
        className="u-sizeFull"
        maxLength={MAX_LENGTH.Address2}
        value={recipient.address2}
        onChange={handleAddress2Change}
      />
      <CountrySelect
        id={recipientId}
        onCountryChange={handleCountryChange}
        country={recipient.country}
      />
      <input
        type="text"
        name="city"
        id={`${recipientId}-city`}
        placeholder="City"
        className="u-sizeFill"
        maxLength={MAX_LENGTH.City}
        value={recipient.city}
        onChange={handleCityChange}
      />
      <div className="u-sm-hidden recipient-form__spacer" />
      <StateSelect
        className="u-size1of4"
        state={recipient.state}
        country={recipient.country as COUNTRY}
        onStateChange={handleStateChange}
        id={recipientId}
      />
      <div className="u-sm-hidden recipient-form__spacer" />
      <input
        type="text"
        name="zip"
        id={`${recipientId}-zip`}
        placeholder="Zip"
        className="u-size1of4"
        maxLength={MAX_LENGTH.PostalCode}
        value={recipient.zip}
        onChange={handleZipChange}
        {...(recipient.country === COUNTRY.US
          ? {
              inputMode: "numeric",
              pattern: "[0-9]{5}",
            }
          : {})}
      />
      <div className="rule rule--marginBottom rule--marginTop rule--transparent u-sizeFull" />
      <RadioInput
        id={`${recipientId}-send-personalized`}
        className="u-sizeFull u-textLeft"
        name={`${recipientId}-how-to-send`}
        label="Send Personalized"
        isChecked={recipient.hasPersonalizedMessage}
        onChange={setPersonalized}
      />
      <RadioInput
        id={`${recipientId}-send-anonymously`}
        className="u-sizeFull u-textLeft"
        name={`${recipientId}-how-to-send`}
        label="Send Anonymously"
        isChecked={!recipient.hasPersonalizedMessage}
        onChange={setAnonymous}
      />
      <div className="rule rule--marginBottom rule--marginTop rule--transparent u-sizeFull" />
      {recipient.hasPersonalizedMessage && (
        <>
          <textarea
            spellCheck="true"
            className="u-sizeFull"
            name="personalized-message"
            rows={4}
            id={`${recipientId}-personalized-message`}
            placeholder="Personalized Message (be sure to include your name)"
            maxLength={MESSAGE_LIMIT}
            value={recipient.personalizedMessage}
            onChange={handlePersonalizedMessage}
          />

          <span className="u-sizeFull u-textRight u-text-offWhite u-opacity5">
            {recipient.personalizedMessage.length}/{MESSAGE_LIMIT}
          </span>

          <div className="u-sizeFull rule rule--offWhite rule--thick" />
          <button
            onClick={startViewingMessage}
            className="button button--secondary u-marginTop3gu u-flexExpandLeft"
          >
            Preview
          </button>
        </>
      )}
      {isViewingMessage && (
        <PersonalizedMessageModal
          isImage
          message={recipient.personalizedMessage}
          onRequestClose={stopViewingMessage}
        />
      )}
    </div>
  );
}
