import React from "react";
import Slider from "react-slick";
import { BulkPrice } from "../../../../server/src/contracts/bulk-price";
import { BulkTractOrder } from "../../../../server/src/contracts/payment-intent";
import { Tract } from "../../../../server/src/contracts/tract";
import { currencyFormatter } from "../../common/currency-formatter";
import { getTractBackground } from "../../common/get-tract-image-url";
import { TractPreview } from "../../common/tract/preview";
import { TractTitle } from "../../common/tract/title";
import { TractDetails } from "../../common/tract/tract-details";
import { PageProps } from "../../interfaces";
import { useToggleHook } from "../../utils/use-toggle-hook";

interface TractSelectorProps
  extends Pick<
    PageProps,
    "setTheme" | "orderInformation" | "setOrderInformation"
  > {
  tracts: Tract[];
}

function getPriceEach(bulkPrice: BulkPrice): string {
  return currencyFormatter.format(bulkPrice.price / bulkPrice.quantity);
}

function getBulkTractOrder(tract: Tract, quantity: number): BulkTractOrder {
  const bulkPrice =
    tract.bulkPrices.find((p) => p.quantity === quantity) ||
    tract.bulkPrices[0];
  return {
    tractId: tract.id,
    price: bulkPrice.price,
    displayPrice: bulkPrice.displayPrice,
    quantity: bulkPrice.quantity,
  };
}

export function TractSelector({
  setTheme,
  tracts,
  orderInformation,
  setOrderInformation,
}: TractSelectorProps) {
  const [tractIndex, setTractIndex] = React.useState(0);

  const [showTract, toggleShowTract, setShowTract] = useToggleHook(false);
  const activeTract = tracts[tractIndex];

  const currentSelection = orderInformation.bulkTractOrders.find(
    (bulkTractOrder) => bulkTractOrder.tractId === activeTract.id
  );

  const isSelected = Boolean(currentSelection);

  const [selectedQuantity, setSelectedQuantity] = React.useState(() =>
    currentSelection
      ? currentSelection.quantity
      : activeTract.bulkPrices[0].quantity
  );
  const bulkPrice =
    activeTract.bulkPrices.find((p) => p.quantity === selectedQuantity) ||
    activeTract.bulkPrices[0];

  const handleQuantityChange: React.ChangeEventHandler<HTMLSelectElement> =
    React.useCallback(
      (e) => {
        const newBulkPrice = activeTract.bulkPrices.find(
          (bp) => String(bp.quantity) === e.target.value
        );
        if (newBulkPrice) {
          setSelectedQuantity(newBulkPrice.quantity);

          if (isSelected) {
            const bulkTractOrder = getBulkTractOrder(
              activeTract,
              newBulkPrice.quantity
            );
            setOrderInformation((o) => ({
              ...o,
              bulkTractOrders: o.bulkTractOrders.map((order) =>
                order.tractId === bulkTractOrder.tractId
                  ? bulkTractOrder
                  : order
              ),
            }));
          }
        }
      },
      [activeTract, isSelected, setOrderInformation]
    );

  const toggleSelect = React.useCallback(() => {
    setOrderInformation((o) => {
      const bulkTractOrder = getBulkTractOrder(activeTract, selectedQuantity);
      const nextBulkTractOrders = isSelected
        ? o.bulkTractOrders.filter((order) => order.tractId !== activeTract.id)
        : o.bulkTractOrders.concat([bulkTractOrder]);

      if (!isSelected) {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }

      return {
        ...o,
        bulkTractOrders: nextBulkTractOrders,
        tractPackShippingRate:
          nextBulkTractOrders.length === 0 ? null : o.tractPackShippingRate,
      };
    });
  }, [setOrderInformation, activeTract, selectedQuantity, isSelected]);

  React.useEffect(() => {
    if (activeTract) {
      setTheme(
        getTractBackground(activeTract),
        true,
        activeTract.backgroundColorEnd
      );
    }
    setShowTract(false);
    if (currentSelection) {
      setSelectedQuantity(currentSelection.quantity);
    }
  }, [setTheme, activeTract, currentSelection, setShowTract]);

  return (
    <>
      <h3 className="u-text-offWhite step-header">Tract Packs</h3>
      <TractTitle {...activeTract} />
      <div className="cyt__slider-container">
        <Slider
          adaptiveHeight
          infinite
          centerMode
          centerPadding="50px"
          className="center"
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          initialSlide={0}
          beforeChange={(_, newActive) => {
            setTractIndex(newActive);
          }}
        >
          {tracts.map((tract, i) => (
            <TractPreview
              key={tract.id}
              {...tract}
              hasGallery={tractIndex === i}
            />
          ))}
        </Slider>
      </div>
      <TractDetails
        tract={activeTract}
        showTract={showTract}
        toggleShowTract={toggleShowTract}
        headerControls={
          <div
            className="u-flex u-flexAlignItemsCenter u-flexJustifyCenter u-sizeFull u-text-white u-marginTop4gu"
            // hack to account for padding inside elements
            // 60px total horizontal padding on button
            style={{ zIndex: 2, paddingLeft: 60 }}
          >
            <div className="custom-select">
              <select
                name="bulk-tract-quantity-select"
                id="bulk-tract-quantity-select"
                value={selectedQuantity}
                onChange={handleQuantityChange}
                className="u-sizeFull u-price"
              >
                {activeTract.bulkPrices.map((bulkPrice) => (
                  <option key={bulkPrice.price} value={bulkPrice.quantity}>
                    {bulkPrice.displayQuantity.padStart(2, "0")}
                  </option>
                ))}
              </select>
              <div className="u-text-white u-marginTop1gu u-text-small">
                Quantity
              </div>
            </div>
            <div style={{ width: 176 }}>
              <div style={{ fontSize: 22 }} className="u-price">
                {bulkPrice.displayPrice}
              </div>
              <div className="u-price u-text-small">
                (<span>{getPriceEach(bulkPrice)} </span>
                Each)
              </div>
            </div>
            <button
              onClick={toggleSelect}
              className={`button button--primary${
                isSelected ? " button--selected" : ""
              }`}
            >
              {isSelected ? "Selected" : "Select"}
            </button>
          </div>
        }
      />
    </>
  );
}
