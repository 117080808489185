import React from "react";

interface SelectThingProps<Thing extends { id: string }> {
  id: string;
  value: Thing;
  wrapperClassName?: string;
  selectClassName?: string;
  selectStyle?: React.CSSProperties;
  isDisabled?: boolean;
  onChange: (newValue: Thing) => void;
  formatOption: (selectedOption: Thing) => string;
  options: Thing[];
}

export function SelectThing<Thing extends { id: string }>({
  id,
  value,
  onChange,
  formatOption,
  selectStyle,
  wrapperClassName,
  selectClassName,
  isDisabled = false,
  options,
}: SelectThingProps<Thing>) {
  const unique = `select-input-${id}`;

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> =
    React.useCallback(
      (e) => {
        const thing = options.find((option) => option.id === e.target.value);
        if (!thing) {
          console.error("Unable to find an option");
        } else {
          onChange(thing);
        }
      },
      [onChange, options]
    );

  return (
    <div
      className={`select-wrapper${
        isDisabled ? " select-wrapper--disabled" : ""
      }${wrapperClassName ? " " + wrapperClassName : ""}`}
    >
      <select
        name={unique}
        id={unique}
        value={value.id}
        disabled={isDisabled}
        style={selectStyle}
        onChange={handleSelectChange}
        className={selectClassName}
      >
        {options.map((o) => (
          <option key={o.id} value={o.id}>
            {formatOption(o)}
          </option>
        ))}
      </select>
    </div>
  );
}
