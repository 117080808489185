import React from "react";

export function useBooleanHook(
  initialValue: boolean
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] {
  const [value, setValue] = React.useState(initialValue);
  const toggleValue = React.useCallback(() => {
    setValue((prev) => !prev);
  }, [setValue]);

  return [value, toggleValue, setValue];
}
