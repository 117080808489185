import React from "react";
import { createAddress } from "../../utils/address";
import { countryOptions, StateSelect } from "../home/state-select";
import { COUNTRY, CountrySelect } from "../home/country-select";
import { Address } from "../../../../server/src/contracts/address";
import "./address-form.css";

interface ShippingAddressInputProps {
  initialAddress: Address;
  onAddressChange: (newAddress: Address) => void;
  addressPlaceholder?: string;
}

export function AddressForm({
  initialAddress,
  onAddressChange,
  addressPlaceholder = "Your Address",
}: ShippingAddressInputProps) {
  const [address, setAddress] = React.useState({
    ...createAddress(),
    ...initialAddress,
  });

  const publishAddressChange = React.useCallback(
    (partialAddress?: Partial<Address>) => {
      onAddressChange({ ...address, ...partialAddress });
    },
    [onAddressChange, address]
  );

  const updateAddress = React.useCallback(
    (
      partialAddress: Partial<Address>,
      shouldUpdateOrderInformation = false
    ) => {
      setAddress((prev) => ({
        ...createAddress(),
        ...prev,
        ...partialAddress,
      }));
      if (shouldUpdateOrderInformation) {
        publishAddressChange(partialAddress);
      }
    },
    [setAddress, publishAddressChange]
  );

  const handleAddress1Change: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        updateAddress({ address1: e.target.value });
      },
      [updateAddress]
    );

  const handleAddress2Change: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        updateAddress({ address2: e.target.value });
      },
      [updateAddress]
    );

  const handleCityChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        updateAddress({ city: e.target.value });
      },
      [updateAddress]
    );

  const handleStateChange = React.useCallback(
    (newState: string) => {
      updateAddress({ state: newState }, true);
    },
    [updateAddress]
  );

  const handleZipChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        updateAddress({ zip: e.target.value });
      },
      [updateAddress]
    );

  const handleCountryChange = React.useCallback(
    (country: string) => {
      updateAddress(
        {
          country,
          state: countryOptions[country as COUNTRY][0],
        },
        true
      );
    },
    [updateAddress]
  );

  return (
    <form
      className="u-flex u-flexCol u-sizeFull u-marginTop6gu address-form"
      onBlur={() => {
        publishAddressChange();
      }}
    >
      <input
        type="text"
        name="address"
        id="bulk-order-address"
        placeholder={addressPlaceholder}
        className="u-sizeFull"
        value={address.address1}
        onChange={handleAddress1Change}
      />
      <input
        type="text"
        name="address-2"
        id="bulk-order-address-2"
        placeholder="Address 2"
        className="u-sizeFull"
        value={address.address2}
        onChange={handleAddress2Change}
      />
      <input
        type="text"
        name="city"
        id="bulk-order-city"
        placeholder="City"
        className="u-sizeFill"
        value={address.city}
        onChange={handleCityChange}
      />
      <CountrySelect
        country={address.country}
        id="bulk-order-country"
        onCountryChange={handleCountryChange}
      />
      <div className="u-flex u-flexCol u-lg-flexRow u-flexJustifyBetween">
        <StateSelect
          className="u-lg-size1of4 u-sizeFull"
          country={address.country as COUNTRY}
          state={address.state}
          onStateChange={handleStateChange}
          id="bulk-order-state"
        />
        <input
          type="text"
          name="zip"
          id="bulk-order-zip"
          placeholder="Zip"
          className="u-lg-size1of4 u-sizeFull"
          value={address.zip}
          onChange={handleZipChange}
          {...(address.country === COUNTRY.US
            ? {
                pattern: "[0-9]{5}",
                inputMode: "numeric",
              }
            : {})}
        />
      </div>
    </form>
  );
}
