import React from "react";
import joy from "./joy.json";
import love from "./love.json";
import hope from "./hope.json";
import "./full-message.css";
import { KNOWN_TITLES } from "../known-titles";

const divider = (
  <img className="u-sizeFull" src="/assets/divider.svg" alt="divider" />
);

interface TractHTMLData {
  element: "h2" | "h3" | "h4" | "p" | "blockquote" | "strong";
  text: string;
}

function getElement(e: TractHTMLData["element"]): string {
  if (e === "p" || e === "strong" || e === "blockquote") {
    return e;
  } else if (e === "h3") {
    return "h4";
  } else {
    return "h2";
  }
}

function parseTractJSON(htmlData: TractHTMLData[]): JSX.Element {
  const sections = htmlData.reduce((acc, node, index) => {
    const lastIndex = acc.length - 1;
    if (
      (index === 1 && node.element === "h3") ||
      node.element === "p" ||
      node.element === "blockquote" ||
      node.element === "strong"
    ) {
      acc[lastIndex].push(node);
    } else {
      acc.push([node]);
    }

    return acc;
  }, [] as TractHTMLData[][]);

  const output = sections.reduce((acc, nodes, index) => {
    return (
      <>
        {acc}
        <section key={index}>
          {index !== 0 && divider}
          {nodes.map((node, nodeIndex) =>
            React.createElement(
              getElement(node.element),
              { key: nodeIndex },
              node.text
            )
          )}
        </section>
      </>
    );
  }, <></>);

  return (
    <>
      {output}
      <div className="u-marginTop3gu">
        <i>
          A scripture index along with footnotes can be seen in the final book
        </i>
      </div>
    </>
  );
}

export const fullMessages: Record<KNOWN_TITLES, JSX.Element> = {
  [KNOWN_TITLES.HOPE]: parseTractJSON(hope as TractHTMLData[]),
  [KNOWN_TITLES.JOY]: parseTractJSON(joy as TractHTMLData[]),
  [KNOWN_TITLES.LOVE]: parseTractJSON(love as TractHTMLData[]),
};

export const subTitlesWithFullMessages = Object.keys(fullMessages);
