import { Route, Routes } from "react-router-dom";
import { EvangelistTable } from "./evangelist-table";
import { ViewEvangelist } from "./view-evangelist";

export function Evangelists() {
  return (
    <Routes>
      <Route path="/" element={<EvangelistTable />} />
      <Route path="/:evangelistId" element={<ViewEvangelist />} />
    </Routes>
  );
}
