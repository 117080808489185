import React from "react";
import { STATUS } from "../../../../utils/use-fetch-hook";
import { TextInput } from "../../../../common/text-input";
import { FileInput, FILE_TYPE } from "../file-input";
import { useTextInputHook } from "../../../../common/use-text-input-hook";
import { Tract } from "../../../../../../server/src/contracts/tract";
import { API } from "../../../../path";
import { useFormUploadHook } from "../use-form-upload-hook";
import { TractUpload } from "../interfaces";
import { TractPreview } from "../../../../common/tract/preview";
import { getTractBackground } from "../../../../common/get-tract-image-url";
import { subTitlesWithFullMessages } from "../../../../common/tract/full-messages";
import { TractTitle } from "../../../../common/tract/title";
import { TractDetails } from "../../../../common/tract/tract-details";
import { ManageBulkPrices } from "./manage-bulk-prices";
import { ManageGalleryImages } from "./manage-gallery-images";

interface TractEditorProps {
  onDone: () => void;
  onCancel: () => void;
  tract?: Tract;
}

export function TractEditor({ onDone, onCancel, tract }: TractEditorProps) {
  const [title, handleTitleChange] = useTextInputHook(tract?.title);
  const [subTitle, handleSubTitleChange] = useTextInputHook(tract?.subTitle);
  const [price, handlePriceChange] = useTextInputHook(
    tract ? String(tract.price) : ""
  );
  const [tractImage, handleTractImageChange] = React.useState<File>();
  const [galleryImageFiles, setGalleryImageFiles] = React.useState(
    [] as File[]
  );
  const [tractImages, setTractImages] = React.useState(tract?.images || []);
  const [bgColorStart, handleBgColorStartChange] = useTextInputHook(
    tract?.backgroundColorStart
  );
  const [bgColorEnd, handleBgColorEndChange] = useTextInputHook(
    tract?.backgroundColorEnd
  );
  const [whoIsThisFor, handleWhoIsThisForChange] = useTextInputHook(
    tract?.whoIsThisFor
  );
  const [targetAudience, handleTargetAudienceChange] = useTextInputHook(
    tract?.targetAudience
  );
  const [estimatedReading, handleEstimatedReadingChange] = useTextInputHook(
    tract?.estimatedReading
  );
  const [productDimensions, handleProductDimensionsChange] = useTextInputHook(
    tract?.productDimensions
  );
  const [bindingType, handleBindingTypeChange] = useTextInputHook(
    tract?.bindingType
  );
  const [author, handleAuthorChange] = useTextInputHook(tract?.author);
  const [pages, handlePagesChange] = useTextInputHook(tract?.pages);
  const [abbreviation, handleAbbreviationChange] = useTextInputHook(
    tract?.abbreviation
  );
  const [urlPart, handleUrlPartChange] = useTextInputHook(tract?.urlPart);
  const [bulkPrices, setBulkPrices] = React.useState(tract?.bulkPrices || []);

  const [uploadState, uploadTract] = useFormUploadHook<TractUpload, boolean>(
    API.TRACT,
    tract?.id
  );

  const isDoneUploading =
    uploadState.status === STATUS.DONE && uploadState.payload;
  React.useEffect(() => {
    if (isDoneUploading) {
      onDone();
    }
  }, [isDoneUploading, onDone]);

  const isFilesReady = Boolean(tract || tractImage);

  const isReady = Boolean(
    title &&
      subTitle &&
      price &&
      isFilesReady &&
      whoIsThisFor &&
      targetAudience &&
      estimatedReading &&
      productDimensions &&
      bindingType &&
      pages &&
      urlPart &&
      bulkPrices.length > 0 &&
      bulkPrices.every(
        ({ price, quantity, shipping }) =>
          !Number.isNaN(+price) &&
          !Number.isNaN(quantity) &&
          !Number.isNaN(shipping)
      )
  );

  const addTract = React.useCallback(() => {
    if (
      title &&
      subTitle &&
      price &&
      bgColorStart &&
      bgColorEnd &&
      whoIsThisFor &&
      targetAudience &&
      estimatedReading &&
      productDimensions &&
      bindingType &&
      author &&
      pages
    ) {
      uploadTract({
        title,
        subTitle,
        price: +price,
        tractImage,
        galleryImageFiles,
        images: tractImages,
        backgroundColorStart: bgColorStart,
        backgroundColorEnd: bgColorEnd,
        whoIsThisFor,
        targetAudience,
        estimatedReading,
        productDimensions,
        bindingType,
        author,
        pages,
        abbreviation,
        urlPart,
        bulkPrices,
      });
    }
  }, [
    uploadTract,
    title,
    subTitle,
    price,
    tractImage,
    galleryImageFiles,
    tractImages,
    bgColorStart,
    bgColorEnd,
    whoIsThisFor,
    targetAudience,
    estimatedReading,
    productDimensions,
    bindingType,
    author,
    pages,
    abbreviation,
    urlPart,
    bulkPrices,
  ]);

  const [showTract, setShowTract] = React.useState(false);

  const toggleShowTract = React.useCallback(() => {
    setShowTract((prev) => !prev);
  }, [setShowTract]);

  React.useEffect(() => {
    setShowTract(false);
  }, [subTitle]);

  const editedTract: Tract = {
    title,
    author,
    images: tractImages,
    backgroundColorStart: bgColorStart || "red",
    backgroundColorEnd: bgColorEnd || "blue",
    bindingType,
    displayPrice: price,
    estimatedReading,
    id: tract?.id || "new-tract",
    pages,
    price: +price,
    productDimensions,
    subTitle,
    whoIsThisFor,
    targetAudience,
    abbreviation,
    urlPart,
    bulkPrices,
  };

  const backgroundImage = getTractBackground(editedTract);

  return (
    <div className="u-flex u-sizeFull">
      <div>
        <h4>{tract ? "Edit Tract" : "Add New Tract"}</h4>
        {uploadState.status === STATUS.ERROR && (
          <div
            className="u-flex u-flexAlignItemsCenter notification notification--warning"
            style={{
              margin: "0px -20px 35px",
              width: "calc(100% + 40px)",
            }}
          >
            <div>
              ERROR:{" "}
              {uploadState.error.toString
                ? uploadState.error.toString()
                : String(uploadState.error)}
            </div>
          </div>
        )}
        <TextInput
          id="title"
          placeholder="Title"
          className="u-marginBottom6gu"
          onChange={handleTitleChange}
          value={title}
        />
        <TextInput
          id="sub-title"
          placeholder="Sub Title"
          className="u-marginBottom6gu"
          onChange={handleSubTitleChange}
          value={subTitle}
        />
        <div style={{ margin: 20 }}>
          <i>
            Only the following Sub Titles will have a "Full Tract Text" preview:{" "}
          </i>
          <ul style={{ marginLeft: 20 }}>
            {subTitlesWithFullMessages.map((subTitleWithFullMessage) => (
              <li key={subTitleWithFullMessage}>
                <i>{subTitleWithFullMessage}</i>
              </li>
            ))}
          </ul>
        </div>
        <TextInput
          id="price"
          placeholder="Price"
          className="u-marginBottom6gu"
          inputProps={{ inputMode: "numeric" }}
          value={price}
          onChange={handlePriceChange}
        />
        <div className="u-flex u-flexAlignItemsCenter u-marginBottom6gu">
          <FileInput
            id="tract-image"
            label="Tract Cover Image"
            accept={[FILE_TYPE.JPG, FILE_TYPE.PNG]}
            value={tractImage}
            onChange={handleTractImageChange}
          />
        </div>
        <div className="u-marginBottom6gu">
          <label htmlFor="background-color-start">Background Color Start</label>
          <input
            type="color"
            name="background-color-start"
            id="background-color-start"
            value={bgColorStart}
            onChange={handleBgColorStartChange}
          />
          <input
            type="text"
            name="background-color-start-text"
            id="background-color-start-text"
            value={bgColorStart}
            onChange={handleBgColorStartChange}
          />
        </div>
        <div className="u-marginBottom6gu">
          <label htmlFor="background-color-end">Background Color End</label>
          <input
            type="color"
            name="background-color-end"
            id="background-color-end"
            value={bgColorEnd}
            onChange={handleBgColorEndChange}
          />
          <input
            type="text"
            name="background-color-end-text"
            id="background-color-end-text"
            value={bgColorEnd}
            onChange={handleBgColorEndChange}
          />
        </div>
        <TextInput
          id="target-audience"
          placeholder="Who Is This For"
          className="u-marginBottom6gu"
          value={whoIsThisFor}
          onChange={handleWhoIsThisForChange}
        />
        <TextInput
          id="target-audience-short"
          placeholder="Target Audience"
          className="u-marginBottom6gu"
          value={targetAudience}
          onChange={handleTargetAudienceChange}
        />
        <TextInput
          id="estimated-reading"
          placeholder="Estimated Reading"
          className="u-marginBottom6gu"
          value={estimatedReading}
          onChange={handleEstimatedReadingChange}
        />
        <TextInput
          id="product-dimensions"
          placeholder="Product Dimensions"
          className="u-marginBottom6gu"
          value={productDimensions}
          onChange={handleProductDimensionsChange}
        />
        <TextInput
          id="binding-type"
          placeholder="Binding Type"
          className="u-marginBottom6gu"
          value={bindingType}
          onChange={handleBindingTypeChange}
        />
        <TextInput
          id="pages"
          placeholder="Pages"
          className="u-marginBottom6gu"
          value={pages}
          onChange={handlePagesChange}
        />
        <TextInput
          id="author"
          placeholder="Written By"
          className="u-marginBottom6gu"
          value={author}
          onChange={handleAuthorChange}
        />
        <TextInput
          id="abbreviation"
          placeholder="Abbreviation"
          className="u-marginBottom6gu"
          value={abbreviation}
          onChange={handleAbbreviationChange}
        />
        <TextInput
          id="url-part"
          placeholder="URL Part"
          className="u-marginBottom6gu"
          value={urlPart}
          onChange={handleUrlPartChange}
        />
        <ManageGalleryImages
          tractId={tract?.id || "new-tract"}
          tractImages={tractImages}
          galleryImageFiles={galleryImageFiles}
          onGalleryImageFilesChange={setGalleryImageFiles}
          onTractImagesChange={setTractImages}
        />
        <ManageBulkPrices bulkPrices={bulkPrices} onChange={setBulkPrices} />

        <button onClick={onCancel} className="button button--secondary">
          Cancel
        </button>
        <button
          disabled={!isReady}
          onClick={addTract}
          className="button button--primary"
        >
          Save
        </button>
      </div>
      <div
        className="u-flexAlignItemsCenter u-flex u-flexCol"
        style={{
          marginLeft: 100,
          paddingTop: 80,
          flexGrow: 1,
          backgroundImage,
        }}
      >
        {editedTract.title && editedTract.subTitle ? (
          <TractTitle {...editedTract} />
        ) : (
          <h3 style={{ color: "lightgray" }}>Enter title and subtitle</h3>
        )}
        <TractPreview
          file={tractImage}
          {...editedTract}
          isNewTract={!Boolean(tract)}
        />
        <TractDetails
          tract={editedTract}
          showTract={showTract}
          toggleShowTract={toggleShowTract}
        />
      </div>
    </div>
  );
}
