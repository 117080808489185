import React from "react";
import { TableHeader } from "../table";
import { API } from "../../../path";
import { STATUS, useLazyFetchHook } from "../../../utils/use-fetch-hook";
import {
  MarkPaidPayload,
  MarkPaidResponse,
  Order,
  OrderPageResult,
  OrderSearch,
  OrderSearchAll,
} from "../../../../../server/src/contracts/orders";
import { SearchBar } from "./search-bar";
import { useToggleHook } from "../../../utils/use-toggle-hook";
import { BulkMessageModal } from "./bulk-message-modal";
import { EditModal } from "./edit-modal";
import { useLocation, useSearchParams } from "react-router-dom";
import { OrderTable } from "../order-table";

function formatDate(date: Date): string {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0");
  const dd = String(date.getDate()).padStart(2, "0");

  return `${yyyy}-${mm}-${dd}`;
}

const d = new Date();

const today = formatDate(d);
d.setDate(d.getDate() - 7);
const oneWeekAgo = formatDate(d);

export type Mode = "id" | "status" | "date-range";

function useProcessTransactionHook(): boolean {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [updateStatus, markTransactionPaid] = useLazyFetchHook<
    MarkPaidPayload,
    MarkPaidResponse
  >();

  React.useEffect(() => {
    if (searchParams) {
      const transactionId = searchParams.get("transactionId");
      const paymentIntentId = searchParams.get("payment_intent");
      if (transactionId && paymentIntentId) {
        markTransactionPaid({
          method: "post",
          url: API.TRANSACTION_MARK_PAID,
          body: {
            transactionId,
            paymentIntentId,
          },
        });
        setSearchParams("");
      }
    }
  }, [location, markTransactionPaid, searchParams, setSearchParams]);

  return updateStatus.status === STATUS.DONE;
}

export function ViewOrders() {
  const [mode, setMode] = React.useState<Mode>("status");
  const [search, setSearch] = React.useState<OrderSearchAll>({
    page: 1,
    orderId: "",
    startDate: oneWeekAgo,
    endDate: today,
    displayStatus: "Unfulfilled",
  });

  const [orderState, getOrders] = useLazyFetchHook<
    OrderSearch,
    OrderPageResult
  >();
  const [selectedOrders, setSelectedOrders] = React.useState<
    Record<string, Order>
  >({});
  const [isViewingBulkMessages, toggleIsViewingBulkMessages] =
    useToggleHook(false);

  const [isEditModalOpen, toggleIsEditModalOpen] = useToggleHook(false);

  React.useEffect(() => {
    function handleKeyUp(e: KeyboardEvent): void {
      if (
        !(e.target instanceof HTMLInputElement) &&
        !(e.target instanceof HTMLSelectElement) &&
        e.key === "d"
      ) {
        toggleIsEditModalOpen();
      }
    }
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [toggleIsEditModalOpen]);

  const processedTransaction = useProcessTransactionHook();

  React.useEffect(() => {
    getOrders({
      method: "post",
      url: API.TRANSACTION,
      body:
        mode === "id"
          ? { search: mode, orderId: search.orderId }
          : mode === "date-range"
            ? {
                search: mode,
                startDate: search.startDate,
                endDate: search.endDate,
                page: search.page,
              }
            : {
                search: mode,
                displayStatus: search.displayStatus,
                page: search.page,
              },
    });
    setSelectedOrders({});
  }, [getOrders, mode, search, setSelectedOrders, processedTransaction]);

  const orders =
    orderState.status === STATUS.DONE ? orderState.payload.results : null;
  const hasAllOrdersSelected = Boolean(
    orders && Object.keys(selectedOrders).length === orders.length,
  );
  const handleSelectAllChange = React.useCallback(() => {
    setSelectedOrders(() => {
      if (orders && !hasAllOrdersSelected) {
        return orders.reduce(
          (acc, order) => {
            acc[order.number] = order;
            return acc;
          },
          {} as Record<string, Order>,
        );
      } else {
        return {};
      }
    });
  }, [setSelectedOrders, orders, hasAllOrdersSelected]);

  const SelectAllOrders = React.useCallback(
    () => (
      <input
        type="checkbox"
        name="select-all"
        id="select-all"
        checked={hasAllOrdersSelected}
        onChange={handleSelectAllChange}
      />
    ),
    [handleSelectAllChange, hasAllOrdersSelected],
  );

  const SelectOrder: React.ComponentType<{ data: Order }> = React.useCallback(
    ({ data }: { data: Order }) => {
      const isSelected = Boolean(selectedOrders[data.number]);
      return (
        <input
          type="checkbox"
          name={data.number}
          id={data.number}
          checked={isSelected}
          onChange={() => {
            const next = { ...selectedOrders };
            if (isSelected) {
              delete next[data.number];
            } else {
              next[data.number] = data;
            }
            return setSelectedOrders(next);
          }}
        />
      );
    },
    [setSelectedOrders, selectedOrders],
  );

  const [headers, setHeaders] = React.useState<TableHeader<Order>[]>(() => [
    {
      key: "date",
      displayName: "",
      Component: SelectOrder,
      HeaderComponent: SelectAllOrders,
    },
  ]);

  React.useEffect(() => {
    setHeaders([
      {
        key: "date",
        displayName: "",
        Component: SelectOrder,
        HeaderComponent: SelectAllOrders,
      },
    ]);
  }, [SelectOrder, SelectAllOrders]);

  return (
    <div className="u-sizeFull">
      <h4 className="u-margin3gu">Orders</h4>
      <SearchBar
        initialSearch={search}
        mode={mode}
        onModeChange={setMode}
        onSearchChange={setSearch}
        onPrint={toggleIsViewingBulkMessages}
      />
      {isViewingBulkMessages && (
        <BulkMessageModal
          orders={Object.values(selectedOrders)}
          onRequestClose={toggleIsViewingBulkMessages}
        />
      )}
      {orderState.status === STATUS.LOADING && <h6>Loading...</h6>}
      {orderState.status === STATUS.ERROR && <h6>Error</h6>}
      {orderState.status === STATUS.DONE &&
      orderState.payload.results.length > 0 ? (
        <div className="u-flex u-flexCol u-flexGrow1 u-textLeft u-margin3gu">
          <OrderTable orders={orderState.payload.results} headers={headers} />
          <div className="u-flex u-flexJustifyEnd u-marginRight2gu">
            <button
              className="button button--small button--primary u-marginRight1gu"
              disabled={orderState.payload.pagination.previousPage === null}
              onClick={() => {
                const prevPage = orderState.payload.pagination.previousPage;
                if (prevPage !== null) {
                  setSearch((search) => ({ ...search, page: prevPage }));
                }
              }}
            >
              Previous
            </button>
            <button
              className="button button--small button--primary"
              disabled={orderState.payload.pagination.nextPage === null}
              onClick={() => {
                const nextPage = orderState.payload.pagination.nextPage;
                if (nextPage !== null) {
                  setSearch((search) => ({ ...search, page: nextPage }));
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <h6 className="u-margin3gu">No orders found!</h6>
      )}
      <EditModal
        isOpen={isEditModalOpen}
        toggleIsOpen={toggleIsEditModalOpen}
        selectedOrders={selectedOrders}
      />
    </div>
  );
}
