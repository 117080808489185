import React from "react";
import "./text-input.css";

interface TextInputProps {
  id: string;
  placeholder: string;
  type?: "text" | "password" | "email" | "tel";
  className?: string;
  inputProps?: Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "type" | "id" | "name" | "value" | "onChange" | "maxLength"
  >;
  maxLength?: number;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export function TextInput({
  id,
  placeholder,
  type = "text",
  className = "",
  maxLength,
  value,
  inputProps,
  onChange,
}: TextInputProps) {
  const [isFocused, setIsFocused] = React.useState(false);
  return (
    <fieldset
      className={`text-input ${
        isFocused || value.length > 0
          ? "text-input--active"
          : "text-input--empty"
      } u-flex ${className}`}
    >
      <legend>{placeholder}</legend>
      <input
        {...inputProps}
        type={type}
        name={id}
        id={id}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        className={`u-sizeFull ${inputProps?.className || ""}`}
      />
    </fieldset>
  );
}
