import React from "react";

interface SelectProps<T extends string> {
  id: string;
  value: T;
  wrapperClassName?: string;
  selectClassName?: string;
  selectStyle?: React.CSSProperties;
  isDisabled?: boolean;
  onChange: (newValue: T) => void;
  formatOption?: (selectedOption: T) => string;
  options: T[];
}

export function Select<T extends string>({
  id,
  value,
  onChange,
  formatOption,
  selectStyle,
  wrapperClassName,
  selectClassName,
  isDisabled = false,
  options,
}: SelectProps<T>) {
  const unique = `select-input-${id}`;

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> =
    React.useCallback(
      (e) => {
        onChange(e.target.value as T);
      },
      [onChange]
    );

  return (
    <div
      className={`select-wrapper${
        isDisabled ? " select-wrapper--disabled" : ""
      }${wrapperClassName ? " " + wrapperClassName : ""}`}
    >
      <select
        name={unique}
        id={unique}
        value={value}
        disabled={isDisabled}
        style={selectStyle}
        onChange={handleSelectChange}
        className={selectClassName}
      >
        {options.map((o) => (
          <option key={o} value={o}>
            {formatOption ? formatOption(o) : o}
          </option>
        ))}
      </select>
    </div>
  );
}
