import { Link, Navigate, useParams } from "react-router-dom";
import { Order as OrderDTO } from "../../../../../server/src/contracts/orders";
import {
  EvangelistSearchSingle,
  EvangelistWithTransactionsDTO,
} from "../../../../../server/src/contracts/evangelist";
import { API } from "../../../path";
import { STATUS, useFetchHook } from "../../../utils/use-fetch-hook";
import { DonationTable } from "./donation-table";
import { TransactionTable } from "./transactions-table";
import { OrderTable } from "../order-table";
import { EvangelistLink } from "../evangelist-link";

export function ViewEvangelist() {
  const params = useParams();
  if (!params.evangelistId) {
    return <Navigate to="../" />;
  }
  return (
    <EvangelistInfo
      key={params.evangelistId}
      evangelistId={params.evangelistId}
    />
  );
}

function EvangelistInfo({ evangelistId }: { evangelistId: string }) {
  const urlParams: EvangelistSearchSingle = {
    id: evangelistId,
    view: "single",
  };

  const evangelistState = useFetchHook<
    EvangelistSearchSingle,
    EvangelistWithTransactionsDTO
  >({
    method: "get",
    url: API.EVANGELIST,
    urlParams,
  });

  switch (evangelistState.status) {
    case STATUS.IDLE:
    case STATUS.LOADING: {
      return <>Loading...</>;
    }
    case STATUS.ERROR: {
      return <>Error</>;
    }
    case STATUS.DONE: {
      const evangelist = evangelistState.payload;
      const orders = evangelist.transactions.reduce((acc, transaction) => {
        return acc.concat(transaction.orders);
      }, [] as OrderDTO[]);
      return (
        <div className="u-sizeFull u-paddingHoriz2gu u-textLeft">
          <h4 className="u-margin3gu">
            <Link to="../">Evangelists</Link> /{" "}
            <span title={evangelist.fullName}>
              {evangelist.displayFullName}
            </span>
          </h4>
          <h4>{evangelist.email}</h4>
          {evangelist.duplicates.length > 0 && (
            <div className="u-bg-white u-border-cream u-padding3gu">
              {evangelist.duplicates.length === 1
                ? "A possible duplicate record was found"
                : "A few possible duplicate records were found"}
              <ul>
                {evangelist.duplicates.map((duplicate) => (
                  <li key={duplicate.id}>
                    <EvangelistLink
                      id={duplicate.id}
                      displayFullName={duplicate.displayFullName}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}
          <hr />
          <h4>Donations</h4>
          {evangelist.donations.length === 0 ? (
            <div>None</div>
          ) : (
            <DonationTable donations={evangelist.donations} />
          )}
          <h4>Transactions</h4>
          {evangelist.transactions.length === 0 ? (
            <div>None</div>
          ) : (
            <TransactionTable transactions={evangelist.transactions} />
          )}
          <h4>Orders</h4>
          {orders.length === 0 ? (
            <div>None</div>
          ) : (
            <OrderTable orders={orders} omitEvangelist />
          )}
        </div>
      );
    }
  }
}
