import React from "react";
import { RecipientPayload } from "../../../../server/src/contracts/recipient";
import {
  ShippingRate,
  ShippingRequestPayload,
} from "../../../../server/src/contracts/shipping";
import { State, STATUS } from "../../utils/use-fetch-hook";
import { defaultShipping } from "../home/default-shipping";
import { RadioInput } from "../../common/radio-input";
import "./shipping-selector.css";

interface ShippingSelectorProps {
  idPrefix: string;
  onShippingChange: (newShippingRate: ShippingRate) => void;
  shippingRatesState: State<ShippingRequestPayload, ShippingRate[]>;
  shippingCost?: number;
  shippingServiceType?: RecipientPayload["shippingServiceType"];
}

const title = <h4>Shipping</h4>;

export function ShippingSelector({
  shippingServiceType,
  shippingCost,
  idPrefix,
  shippingRatesState,
  onShippingChange,
}: ShippingSelectorProps) {
  const [isInitialDefaultShipping] = React.useState(
    () =>
      (shippingServiceType === undefined && shippingCost === undefined) ||
      (shippingServiceType === defaultShipping.serviceType &&
        shippingCost === defaultShipping.price)
  );
  React.useEffect(() => {
    if (shippingRatesState.status === STATUS.DONE) {
      const shippingRate =
        shippingRatesState.payload.find(
          (shippingRate) => shippingRate.serviceType === shippingServiceType
        ) || shippingRatesState.payload[0];
      if (shippingRate && shippingRate.shipmentCost !== shippingCost) {
        onShippingChange(shippingRate);
      }
    } else if (
      shippingRatesState.status === STATUS.ERROR &&
      !isInitialDefaultShipping
    ) {
      onShippingChange({
        displayShipmentCost: defaultShipping.displayPrice,
        estimatedDelivery: "",
        serviceName: defaultShipping.serviceName,
        serviceType: defaultShipping.serviceType,
        shipmentCost: defaultShipping.price,
      });
    }
  }, [
    onShippingChange,
    shippingRatesState,
    isInitialDefaultShipping,
    shippingCost,
    shippingServiceType,
  ]);

  switch (shippingRatesState.status) {
    case STATUS.LOADING: {
      return (
        <>
          {title}
          <h4>Loading shipping rates...</h4>
        </>
      );
    }
    case STATUS.ERROR: {
      return <></>;
    }
    case STATUS.DONE: {
      return (
        <>
          {title}
          <div className="u-flex u-flexCol u-sizeFull rounded u-border-cream shipping">
            {shippingRatesState.payload.map((shippingRate: ShippingRate) => (
              <RadioInput
                key={shippingRate.serviceType}
                isChecked={shippingRate.serviceType === shippingServiceType}
                onChange={() => onShippingChange(shippingRate)}
                className="u-flex shipping__option radio--light-green"
                id={`${idPrefix}-${shippingRate.serviceType}`}
                name={`${idPrefix}-shipping-type`}
                label={
                  <span className="u-flex u-sizeFull u-padding3gu">
                    <div className="u-flex u-flexCol">
                      <span className="u-text-black">
                        {shippingRate.serviceName}{" "}
                      </span>
                      {shippingRate.estimatedDelivery !== null && (
                        <span className="u-text-lightGray">
                          {shippingRate.estimatedDelivery} business days
                        </span>
                      )}
                    </div>
                    <div className="u-flexExpandLeft u-price u-text-black">
                      {shippingRate.displayShipmentCost}
                    </div>
                  </span>
                }
              />
            ))}
          </div>
        </>
      );
    }
  }
  return <></>;
}
