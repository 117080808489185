import React from "react";
import { OrderInformation } from "../../../../server/src/contracts/payment-intent";
import { currencyFormatter } from "../../common/currency-formatter";
import { isAddressValid } from "../../utils/address";
import { Products } from "./interfaces";

interface TotalSummaryProps {
  taxRate?: number;
  orderInformation: OrderInformation;
  products: Products;
  hasTaxRate?: boolean;
  hasCondensedShipping?: boolean;
}

function computeTotals(
  taxRate: number,
  orderInformation: OrderInformation,
  products: Products
): {
  shipping: number;
  subTotal: number;
  tax: number;
  donation: number;
  total: number;
} {
  const [bulkOrderSubTotal, bulkOrderTax] =
    orderInformation.bulkTractOrders.reduce(
      (acc, bulkTractOrder) => [
        acc[0] + bulkTractOrder.price,
        acc[1] + computeTax(bulkTractOrder.price, taxRate),
      ],
      [0, 0]
    );
  const [tractSubTotal, tractShipping, tractTax] =
    orderInformation.tracts.reduce(
      (acc, tractOrder) => {
        const tract = products.tracts.find(
          (tract) => tract.id === tractOrder.tractId
        );
        if (!tract) {
          return acc;
        }
        return tractOrder.recipients.reduce((recAcc, recipient) => {
          const addOnPrice =
            recipient.addOnId !== null
              ? products.addOns.find((addOn) => addOn.id === recipient.addOnId)
                  ?.price || 0
              : 0;
          const orderSubTotal = addOnPrice + tract.price;
          const orderTax = computeTax(orderSubTotal, taxRate);
          const orderShipping = recipient.shippingCost;
          return [
            recAcc[0] + orderSubTotal,
            recAcc[1] + orderShipping,
            recAcc[2] + orderTax,
          ];
        }, acc);
      },
      [0, 0, 0]
    );

  const donation = orderInformation.donation?.amount || 0;

  const bulkOrderShipping =
    orderInformation.tractPackShippingRate?.shipmentCost || 0;

  const subTotal = bulkOrderSubTotal + tractSubTotal;
  const tax = bulkOrderTax + tractTax;
  const shipping = bulkOrderShipping + tractShipping;

  return {
    shipping,
    subTotal: bulkOrderSubTotal + tractSubTotal,
    tax: bulkOrderTax + tractTax,
    donation,
    total: shipping + subTotal + tax + donation,
  };
}

interface ShippingTotal {
  name: string;
  price: number;
  displayPrice: string;
  displayPriceClassName?: string;
}

function getCondensedShippingTotal(shipping: number): [ShippingTotal] {
  return [
    {
      name: "",
      price: shipping,
      displayPrice: currencyFormatter.format(shipping),
    },
  ];
}

function getShippingTotals(
  orderInformation: OrderInformation
): ShippingTotal[] {
  const hasTractPacks = orderInformation.bulkTractOrders.length !== 0;

  const isShippingAddressValid = isAddressValid(
    orderInformation.shippingAddress
  );

  const tractPackTotal: ShippingTotal = orderInformation.tractPackShippingRate
    ? {
        name: "Tract Packs",
        price: orderInformation.tractPackShippingRate.shipmentCost,
        displayPrice:
          orderInformation.tractPackShippingRate.displayShipmentCost,
      }
    : {
        name: "Tract Packs",
        price: 0,
        displayPrice: isShippingAddressValid
          ? "Loading..."
          : "Please enter valid address",
        displayPriceClassName: isShippingAddressValid
          ? undefined
          : "u-text-darkGreen",
      };

  const shippingTotals = orderInformation.tracts.reduce(
    (shippingPrices, tractOrder) => {
      shippingPrices.push(
        ...tractOrder.recipients.map(
          (recipient) =>
            ({
              name: recipient.firstName,
              price: recipient.shippingCost,
              displayPrice: recipient.displayShippingCost,
            } as ShippingTotal)
        )
      );
      return shippingPrices;
    },
    [] as ShippingTotal[]
  );

  return hasTractPacks ? shippingTotals.concat(tractPackTotal) : shippingTotals;
}

function computeTax(subTotal: number, taxRate: number): number {
  return +(subTotal * taxRate).toFixed(2);
}

const taxDefaultMessage = "Calculated in next step ";
export function TotalSummary({
  orderInformation,
  products,
  taxRate,
  hasTaxRate,
  hasCondensedShipping,
}: TotalSummaryProps) {
  const { subTotal, tax, total, donation, shipping } = computeTotals(
    taxRate || 0,
    orderInformation,
    products
  );
  const formattedSubTotal = currencyFormatter.format(subTotal);
  const formattedTotal = currencyFormatter.format(total);
  const formattedTax = hasTaxRate
    ? currencyFormatter.format(tax)
    : taxDefaultMessage;
  const formattedDonation = currencyFormatter.format(donation);
  const shippingTotals = hasCondensedShipping
    ? getCondensedShippingTotal(shipping)
    : getShippingTotals(orderInformation);

  return (
    <>
      <div className="u-flex u-flexJustifyBetween u-marginVert3gu">
        <span>Subtotal:</span>
        <span className="u-price">{formattedSubTotal}</span>
      </div>
      {shippingTotals.map((shippingTotal, index) => (
        <div
          key={index}
          className="u-flex u-flexJustifyBetween u-marginVert3gu"
        >
          <span>Shipping: {shippingTotal.name}</span>
          <span
            className={`u-price ` + (shippingTotal.displayPriceClassName || "")}
          >
            {shippingTotal.displayPrice}
          </span>
        </div>
      ))}
      <div className="u-flex u-flexJustifyBetween u-marginVert3gu">
        <span>Donation:</span>
        <span className="u-price">{formattedDonation}</span>
      </div>
      <div className="u-flex u-flexJustifyBetween u-marginVert3gu">
        <span>Tax:</span>
        <span className="u-price">{formattedTax}</span>
      </div>
      <div className="rule rule--thick rule--gray u-marginVert3gu" />
      <h4 className="u-flex u-flexJustifyBetween u-marginVert3gu">
        <span>Total:</span>
        <span className="u-price">{formattedTotal}</span>
      </h4>
    </>
  );
}
