import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useAnalytics } from "use-analytics";
import { Footer } from "./footer";
import { AdminPanel } from "./pages/admin-panel";
import { Home } from "./pages/home";
import { PageProps, RecipientUpdate, STORAGE_KEY } from "./interfaces";
import { About } from "./pages/about";
import { Give } from "./pages/give";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { Support } from "./pages/support";
import { TermsAndConditions } from "./pages/terms-and-conditions";
import { PATH } from "./path";
import { useProvideUserState, UserContext } from "./user.context";
import { OrderConfirmed } from "./pages/order-confirmed";
import {
  CreatePaymentIntentResponse,
  OrderInformation,
} from "../../server/src/contracts/payment-intent";
import { withPreviewAuth } from "./common/with-preview-auth";
import { getEmptyOrderInformation } from "./utils/get-empty-order-information";
import { Welcome } from "./pages/welcome";
import { Faq } from "./pages/faq";
import { DonateConfirmed } from "./pages/donate-confirmed";
import { Pack } from "./pages/pack/";
import { OrderReview } from "./pages/order-review";
import { Checkout } from "./pages/checkout";

type SiteMap = Record<
  PATH,
  { Component: React.ComponentType<PageProps>; hasDeepLinks?: boolean }
>;

const siteMap: SiteMap = Object.entries({
  [PATH.ABOUT]: { Component: About },
  [PATH.PACK]: { Component: Pack, hasDeepLinks: true },
  [PATH.ORDER_REVIEW]: { Component: OrderReview },
  [PATH.CHECKOUT]: { Component: Checkout },
  [PATH.ADMIN_PANEL_ROOT]: { Component: AdminPanel, hasDeepLinks: true },
  [PATH.GIVE]: { Component: Give },
  [PATH.HOME]: { Component: Home, hasDeepLinks: true },
  [PATH.ORDER_CONFIRMED]: { Component: OrderConfirmed },
  [PATH.DONATION_CONFIRMED]: { Component: DonateConfirmed },
  [PATH.PRIVACY_POLICY]: { Component: PrivacyPolicy },
  [PATH.SUPPORT]: { Component: Support },
  [PATH.TERMS_AND_CONDITIONS]: { Component: TermsAndConditions },
  [PATH.WELCOME]: { Component: Welcome },
  [PATH.FAQ]: { Component: Faq },
} as SiteMap).reduce((acc, [path, { Component, hasDeepLinks }]) => {
  acc[path as PATH] = { Component: withPreviewAuth(Component), hasDeepLinks };
  return acc;
}, {} as SiteMap);

function getInitialOrderInformation(): OrderInformation {
  const savedOrderInformationSerialized = localStorage.getItem(
    STORAGE_KEY.ORDER_INFORMATION
  );
  const emptyOrder = getEmptyOrderInformation();
  if (savedOrderInformationSerialized) {
    try {
      const savedOrderInformation = JSON.parse(
        savedOrderInformationSerialized
      ) as OrderInformation;
      return {
        ...emptyOrder,
        ...savedOrderInformation,
        shippingAddress: {
          ...emptyOrder.shippingAddress,
          ...savedOrderInformation.shippingAddress,
        },
        billingAddress: {
          ...emptyOrder.billingAddress,
          ...savedOrderInformation.billingAddress,
        },
      };
    } catch (e) {}
  }

  return emptyOrder;
}

function getInitialPaymentIntent(): CreatePaymentIntentResponse | null {
  const savedPaymentIntentSerialized = localStorage.getItem(
    STORAGE_KEY.PAYMENT_INTENT
  );
  if (savedPaymentIntentSerialized) {
    try {
      const savedPaymentIntent = JSON.parse(
        savedPaymentIntentSerialized
      ) as CreatePaymentIntentResponse;
      return {
        ...savedPaymentIntent,
        taxRates: savedPaymentIntent.taxRates || {},
      };
    } catch (e) {}
  }

  return null;
}

export default function App() {
  const location = useLocation();
  const analytics = useAnalytics();

  React.useEffect(() => {
    // send page view on route change
    analytics.page();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const [backgroundColor, setBackgroundColor] = React.useState("white");
  const [footerColor, setFooterColor] = React.useState(backgroundColor);
  const [isDark, setIsDark] = React.useState(false);
  const [hasFooter, setHasFooter] = React.useState(true);

  const setTheme = React.useCallback(
    (
      newColor: string,
      newIsDark: boolean,
      newFooterColor = newColor,
      newHasFooter = true
    ) => {
      setBackgroundColor(newColor);
      setIsDark(newIsDark);
      setFooterColor(newFooterColor);
      setHasFooter(newHasFooter);
    },
    [setBackgroundColor, setIsDark, setFooterColor, setHasFooter]
  );

  const [orderInformation, setOrderInformation] = React.useState(
    getInitialOrderInformation
  );

  const handleRecipientChange = React.useCallback(
    ({ recipientId, tractId, partialRecipient }: RecipientUpdate) => {
      setOrderInformation((prevOrder) => ({
        ...prevOrder,
        tracts: prevOrder.tracts.map((tract) => {
          if (tract.tractId === tractId) {
            tract.recipients = tract.recipients.map((recipient) => {
              if (recipient.id === recipientId) {
                return {
                  ...recipient,
                  ...partialRecipient,
                };
              } else {
                return { ...recipient };
              }
            });
          }
          return tract;
        }),
      }));
    },
    [setOrderInformation]
  );

  React.useEffect(() => {
    localStorage.setItem(
      STORAGE_KEY.ORDER_INFORMATION,
      JSON.stringify(orderInformation)
    );
  }, [orderInformation]);

  const [paymentIntent, setPaymentIntent] = React.useState(
    getInitialPaymentIntent
  );

  React.useEffect(() => {
    if (paymentIntent) {
      localStorage.setItem(
        STORAGE_KEY.PAYMENT_INTENT,
        JSON.stringify(paymentIntent)
      );
    } else if (localStorage.getItem(STORAGE_KEY.PAYMENT_INTENT)) {
      localStorage.removeItem(STORAGE_KEY.PAYMENT_INTENT);
    }
  }, [paymentIntent]);

  const userState = useProvideUserState();

  return (
    <div
      className="u-bg-transition"
      style={{
        background: backgroundColor,
      }}
    >
      <UserContext.Provider value={userState}>
        <Routes>
          {Object.entries(siteMap).map(
            ([path, { Component, hasDeepLinks }]) => (
              <Route
                key={path}
                path={hasDeepLinks ? path + "/*" : path}
                element={
                  <Component
                    setTheme={setTheme}
                    isDark={isDark}
                    orderInformation={orderInformation}
                    setOrderInformation={setOrderInformation}
                    onRecipientUpdate={handleRecipientChange}
                    paymentIntent={paymentIntent}
                    setPaymentIntent={setPaymentIntent}
                  />
                }
              />
            )
          )}
          <Route path="/" element={<Navigate to={PATH.HOME} replace />} />
          <Route path="*" element={<Navigate to={PATH.HOME} replace />} />
        </Routes>
        {userState.isPreviewAuthenticated && hasFooter && (
          <Footer isDark={isDark} backgroundColor={footerColor} />
        )}
      </UserContext.Provider>
    </div>
  );
}
