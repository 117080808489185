import React from "react";
import {
  TractImageGallery,
  ThumbnailProps,
} from "../../../../common/tract/image-gallery";
import { FILE_TYPE } from "../file-input";

function ThumbnailWrapper({ children }: { children: React.ReactNode }) {
  return <div className="u-flex u-flexAlignItemsCenter">{children}</div>;
}

interface ManageGalleryImagesProps {
  tractId: string;
  tractImages: string[];
  galleryImageFiles: File[];
  onGalleryImageFilesChange: React.Dispatch<React.SetStateAction<File[]>>;
  onTractImagesChange: React.Dispatch<React.SetStateAction<string[]>>;
}

export function ManageGalleryImages({
  tractId,
  tractImages,
  galleryImageFiles,
  onGalleryImageFilesChange,
  onTractImagesChange,
}: ManageGalleryImagesProps) {
  const handleFileSelect: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        if (e.target.files) {
          onGalleryImageFilesChange((p) =>
            p.concat([].slice.call(e.target.files))
          );
        }
      },
      [onGalleryImageFilesChange]
    );

  const Thumbnail: React.ComponentType<ThumbnailProps> = React.useCallback(
    ({ type, index }: ThumbnailProps) => {
      return (
        <button
          onClick={() => {
            if (type === "file") {
              onGalleryImageFilesChange((p) => [
                ...p.slice(0, index),
                ...p.slice(index + 1),
              ]);
            } else if (type === "image") {
              onTractImagesChange((p) => [
                ...p.slice(0, index),
                ...p.slice(index + 1),
              ]);
            }
          }}
          className="button button--close button--alert u-margin3gu "
        />
      );
    },
    [onGalleryImageFilesChange, onTractImagesChange]
  );
  return (
    <>
      <h3>Gallery Images</h3>
      <div></div>
      <TractImageGallery
        className="u-flexCol u-flexAlignItemsStart "
        images={tractImages}
        files={galleryImageFiles}
        Thumbnail={Thumbnail}
        ThumbnailWrapper={ThumbnailWrapper}
      />
      <input
        multiple
        type="file"
        className="button button--small button--secondary"
        style={{ display: "none" }}
        accept={[FILE_TYPE.JPG, FILE_TYPE.PNG].join()}
        name={`tract-${tractId}-gallery-images`}
        id={`tract-${tractId}-gallery-images`}
        onChange={handleFileSelect}
      />
      <label
        htmlFor={`tract-${tractId}-gallery-images`}
        className="button button--small button--secondary"
      >
        Add Images
      </label>
      <div className="rule u-sizeFull u-marginVert6gu" />
    </>
  );
}
