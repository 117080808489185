import React from "react";

export function useTextInputHook(
  initialValue = ""
): [string, React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>] {
  const [text, setText] = React.useState(initialValue);

  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = React.useCallback(
    (e) => {
      setText(e.target.value);
    },
    [setText]
  );

  return [text, handleChange];
}
