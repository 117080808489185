import { OrderInformation } from "../../../server/src/contracts/payment-intent";
import { createAddress } from "./address";

export function getEmptyOrderInformation(): OrderInformation {
  return {
    fullName: "",
    email: "",
    tracts: [],
    initialTractId: null,
    bulkTractOrders: [],
    donation: null,
    shippingAddress: createAddress(),
    billingAddress: createAddress(),
    tractPackShippingRate: null,
    customLiterature: null,
  } as OrderInformation;
}
