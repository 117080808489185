import { Navigate } from "react-router-dom";
import { PATH } from "../../path";
import { StepProps } from "./interfaces";
import { useGetStepsHook } from "./steps";

export function withRequiredOrderInformation(
  hasRequiredInfo: (orderInformation: StepProps["orderInformation"]) => boolean,
  StepComponent: React.ComponentType<StepProps>
): React.ComponentType<StepProps> {
  const WithOrderInformation: React.ComponentType<StepProps> = (props) => {
    const { prevStep } = useGetStepsHook();
    if (hasRequiredInfo(props.orderInformation)) {
      return <StepComponent {...props} />;
    } else {
      return <Navigate to={`${PATH.HOME}/${prevStep}`} replace />;
    }
  };

  WithOrderInformation.displayName = `withOrderInformation(${
    StepComponent.displayName || StepComponent.name
  })`;

  return WithOrderInformation;
}
