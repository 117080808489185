import React from "react";
import { Section } from "../home/light-theme";
import { Link } from "react-router-dom";
import { PATH } from "../../path";
import { MailTheGospelPrompt } from "./mail-the-gospel-prompt";

export function EmptyCartSection() {
  return (
    <Section isRounded>
      <div
        className="u-flex u-flexCol u-flexJustifyCenter u-flexAlignItemsCenter u-textCenter"
        style={{ minHeight: "calc(100vh - 348px)" }}
      >
        <h3 className="u-marginVert6gu">Looks like your cart is empty!</h3>
        <MailTheGospelPrompt />
        <p style={{ marginTop: 100 }}>
          Or you can order our tracts in bulk and hand them out yourself!
        </p>
        <Link to={PATH.PACK}>
          <button className="button button--secondary">Tract Packs</button>
        </Link>
      </div>
    </Section>
  );
}
