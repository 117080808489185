import React from "react";

import { SummaryTitle } from "../../common/summary-title";
import { TextInput } from "../../common/text-input";
import { PageProps } from "../../interfaces";
import { Box } from "./box";

export function CustomerInfoInput({
  orderInformation,
  setOrderInformation,
}: Pick<PageProps, "orderInformation" | "setOrderInformation">) {
  const handleFullNameChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        setOrderInformation((p) => ({
          ...p,
          fullName: e.target.value,
        }));
      },
      [setOrderInformation]
    );

  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        setOrderInformation((p) => ({
          ...p,
          email: e.target.value,
        }));
      },
      [setOrderInformation]
    );

  return (
    <Box>
      <SummaryTitle>Your Information</SummaryTitle>
      <TextInput
        id="full-name"
        value={orderInformation.fullName}
        onChange={handleFullNameChange}
        placeholder="Your full name"
        className="u-marginBottom3gu u-marginTop6gu"
      />
      <TextInput
        id="email"
        value={orderInformation.email}
        onChange={handleEmailChange}
        placeholder="Your email"
        className="u-marginTop6gu"
      />
    </Box>
  );
}
