import React from "react";
import { CSS_VARS } from "../../common/css-variables";
import { LightHeader } from "../../common/light-header";
import { NavBar } from "../../common/nav-bar";
import { SummaryTitle } from "../../common/summary-title";
import { PageProps } from "../../interfaces";

export function TermsAndConditions({ setTheme }: PageProps) {
  React.useEffect(() => {
    setTheme(CSS_VARS["--off-white"], false);
  }, [setTheme]);
  return (
    <div
      className="u-light"
      style={{ minHeight: "calc(100vh - 56px)", paddingBottom: 80 }}
    >
      <NavBar />
      <LightHeader title="Terms and Conditions" subTitle="" />
      <section
        className="rounded-section"
        style={{
          marginLeft: 30,
          marginRight: 30,
        }}
      >
        <SummaryTitle fontSize={18}>
          Terms of Use for Mail the Gospel
        </SummaryTitle>
        <p>
          Mail the Gospel created and maintains this website to provide
          information about Mail the Gospel (a ministry of Relearn.org). You may
          use this website, provided you comply with these terms and conditions.
        </p>
        <SummaryTitle fontSize={18}>
          Your Acceptance of These Terms and Conditions
        </SummaryTitle>
        <p>
          Please take a few minutes to carefully review these terms and
          conditions. By accessing and using this website you agree to follow
          and be bound by these terms and conditions. If you do not agree to
          follow and be bound by these terms and conditions, you may not access,
          use, or download materials from this website.
        </p>
        <SummaryTitle fontSize={18}>
          These Terms and Conditions May Change
        </SummaryTitle>
        <p>
          Mail the Gospel reserves the right to update or modify these terms and
          conditions at any time without prior notice. Your use of this website
          following any such change constitutes your agreement to follow and be
          bound by the terms and conditions as changed. For this reason, we
          encourage you to review these terms and conditions every time you use
          this website. These terms and conditions were last revised on May 15,
          2022.
        </p>
        <SummaryTitle fontSize={18}>Trademark Notice</SummaryTitle>
        <p>
          All of the trademarks, service marks, and logos displayed on this
          website (the “Trademark(s)”) are registered and unregistered
          trademarks of Relearn.org, one of its affiliates, or third parties who
          have licensed their Trademarks to Relearn.org or one of its
          affiliates. Except as expressly stated in these terms and conditions,
          you may not reproduce, display, or otherwise use any Trademark without
          first obtaining Relearn.org's written permission.
        </p>
        <SummaryTitle fontSize={18}>Unsolicited Ideas</SummaryTitle>
        <p>
          Relearn.org welcomes your comments and feedback regarding this
          website. All information and materials, including comments, ideas,
          questions, designs, and the like, submitted to Relearn.org through
          this website will be considered NON-CONFIDENTIAL and NON-PROPRIETARY.
          For this reason, we ask that you not send us any information or
          materials that you do not wish to assign to us, including any
          confidential information or any original creative materials such as
          product ideas, computer code, or original artwork.
        </p>
        <p>
          By submitting information or materials to Relearn.org through this
          website, you assign to Relearn.org, free of charge, all worldwide
          rights, title and interest in all copyrights, and other intellectual
          property rights in the information or materials you submit.
          Relearn.org will be entitled to use any information or materials you
          submit through this website for any purpose whatsoever, without
          restriction, and without compensating you in any way.
        </p>
        <SummaryTitle fontSize={18}>Links to Other Web Sites</SummaryTitle>
        <p>Links to Other Web Sites</p>
        <SummaryTitle fontSize={18}>LIMITATION OF LIABILITY</SummaryTitle>
        <p>
          YOUR USE OF THIS WEBSITE IS AT YOUR SOLE RISK. UNDER NO CIRCUMSTANCES,
          SHALL <a href="relearn.org">RELEARN.ORG</a>, ITS AFFILIATES, OR ANY OF
          THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, OR AGENTS, BE LIABLE
          FOR ANY DIRECT OR INDIRECT LOSSES OR DAMAGES ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE OF OR INABILITY TO USE THIS WEB SITE OR YOUR
          RELIANCE ON ANY INFORMATION PROVIDED ON THIS WEB SITE. THIS IS A
          COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL LOSSES AND
          DAMAGES OF ANY KIND WHATSOEVER, WHETHER DIRECT OR INDIRECT, GENERAL,
          SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR OTHERWISE, INCLUDING
          WITHOUT LIMITATION, LOSS OF DATA, REVENUE, OR PROFITS. THIS LIMITATION
          OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON
          CONTRACT, NEGLIGENCE, TORT, STRICT LIABILITY, OR ANY OTHER BASIS, AND
          EVEN IF AN AUTHORIZED REPRESENTATIVE OF{" "}
          <a href="relearn.org">RELEARN.ORG</a> OR ITS AFFILIATES HAS BEEN
          ADVISED OF OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          SOME STATES DO NOT ALLOW THE LIMITATION OF LIABILITY SET FORTH ABOVE,
          SO THIS LIMITATION OF LIABILITY MAY NOT APPLY TO YOU. IF ANY PART OF
          THIS LIMITATION OF LIABILITY IS FOUND TO BE INVALID OR UNENFORCEABLE
          FOR ANY REASON, THEN THE AGGREGATE LIABILITY OF{" "}
          <a href="relearn.org">RELEARN.ORG</a> AND/OR ITS AFFILIATES UNDER SUCH
          CIRCUMSTANCES FOR LIABILITIES THAT OTHERWISE WOULD HAVE BEEN LIMITED
          SHALL NOT EXCEED ONE HUNDRED ($100.00) DOLLARS.
        </p>
        <SummaryTitle fontSize={18}>
          Governing Law and Jurisdiction
        </SummaryTitle>
        <p>
          This website is controlled and operated by Relearn.org from its
          offices within the United States. Any claim relating to, and the use
          of, this website are governed by the laws of the State of Arizona,
          United States of America. By using this website, you consent to
          personal jurisdiction in the federal and state courts of Cottonwood,
          Arizona, for any action arising out of or relating to this website or
          your use of this website. The federal and state courts of Cottonwood,
          Arizona shall have exclusive jurisdiction over all such actions.
        </p>
        <SummaryTitle fontSize={18}>Entire Agreement</SummaryTitle>
        <p>
          This Agreement constitutes the entire agreement between you and
          Relearn.org (parent ministry of Mail the Gospel) with respect to your
          access to and/or use of this website.
        </p>
      </section>
    </div>
  );
}
