import React from "react";

export function Box({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <div
      className="rounded u-border-cream"
      style={{ padding: 30, marginBottom: 15 }}
    >
      {children}
    </div>
  );
}
