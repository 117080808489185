import React from "react";
import { Link } from "react-router-dom";
import { CSS_VARS } from "../../common/css-variables";
import { NavBar } from "../../common/nav-bar";
import { PageProps } from "../../interfaces";
import { pageLinks, PATH } from "../../path";
import { STEP } from "../home/interfaces";
import "./about.css";
import { Endorsements } from "./endorsements";
import { StatementCard } from "./statement-card";
import { statements } from "./statements";
import { TeamMembers } from "./team-members";

function useScrollToHash() {
  React.useEffect(() => {
    const hash = window.location.hash.replace(/^#/, "");
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);
}

const hasEndorsements = false;

export function About({ setTheme }: PageProps) {
  React.useEffect(() => {
    setTheme(CSS_VARS["--dark-green"], false, CSS_VARS["--off-white"]);
  }, [setTheme]);
  useScrollToHash();
  return (
    <div>
      <NavBar hasWhiteText />
      <div className="about-page u-flex u-flexCol">
        <div className="u-marginTop4gu" />
        <section className="about-page__section about-page__section--mission u-textCenter u-sm-textLeft u-flex u-flexCol u-flexAlignItemsCenter u-sm-flexAlignItemsStart">
          <h5 className="about-page__mission">Our Mission</h5>
          <h1 className="about-page__mission-message u-text-offWhite">
            Giving every Christian the Opportunity to Share the Gospel.
          </h1>
          <p className="about-page__serif-heading serif-heading u-text-white u-opacity8">
            For many Christians, sharing the Gospel can be intimidating—not
            because they doubt its message, but because they doubt their ability
            to deliver a theologically accurate and intelligent presentation.
            Mail the Gospel aims to allow any Christian to share a
            biblically-precise and circumstantially-relevant offering of Gospel
            with anyone, anywhere.
          </p>
          <img
            className="about-page__mission-graphic"
            src="/assets/about-page-illo.svg"
            alt="mailbox"
          />
        </section>
        <section className="about-page__section u-textCenter u-flex u-flexCol u-flexAlignItemsCenter u-md-hidden u-lg-hidden">
          <h1 className="u-text-offWhite">Our Profits</h1>
          <p className="about-page__serif-heading about-page__serif-heading--small serif-heading u-text-offWhite">
            We're a non-profit ministry. All proceeds further our mission.
          </p>
        </section>
        <section className="about-page__section u-textCenter u-flex u-flexJustifyCenter u-sm-hidden">
          <h2 className="about-page__profits u-text-offWhite">
            We're a non-profit ministry. All proceeds further our mission.
          </h2>
        </section>
        <div className="u-marginTop6gu" />
        <div className="u-marginTop6gu u-md-hidden" />
        <div className="u-flex u-lg-flexRow u-flexCol u-marginTop2gu">
          <section className="about-page__section about-page__section--lg-half u-lg-size1of2">
            <h2 className="u-text-offWhite">Our Tracts</h2>
            <p className="u-text-white about-page__reading">
              Most Gospel tracts are either too short and fail to present a
              thorough Gospel, or too long and require a substantial commitment
              from the reader. Our Gospel tracts can be read in 5-10 minutes
              which allows for an engaging and complete presentation of this
              vital biblical message. Each tract also includes an ESV Scripture
              Index listing every full verse referenced in the tract. In
              addition, our tracts are written and reviewed by theologically
              trained and experienced pastors and evangelists.
            </p>
          </section>
          <section className="about-page__section about-page__section--lg-half u-lg-size1of2">
            <h2 className="u-text-offWhite">One Message Many Ways</h2>
            <p className="u-text-white about-page__reading">
              We believe the Gospel is the same message for everyone. However,
              we also believe not everyone needs the same Gospel presentation.
              We see in Scripture that, based on who they were speaking to,
              Jesus and the Apostles would adjust some aspects of their
              presentation. For that reason, we have chosen to produce Gospel
              tracts that meet people in their unique circumstances. For
              example, we have chosen to produce select tracts for those who are
              sick or suffering and those who are depressed or anxious. We plan
              to continue writing tracts that meet large populations where they
              are with the truth of Jesus Christ.
            </p>
          </section>
        </div>
        <section className="about-page__section about-page__section--sof u-textCenter u-flex u-flexCol u-flexAlignItemsCenter">
          <h2 className="u-text-offWhite">Statement of Faith</h2>
          <p className="serif-heading u-text-white u-opacity8 about-page__sof-details">
            We believe the Bible is the final standard of faith and practice for
            the believer in Jesus Christ and for His Church.
          </p>
          <div className="u-flex u-flexCol u-lg-flexRow u-sizeFull u-flexAlignItemsStart about-page__sof-cards">
            {statements.map((statement) => (
              <StatementCard key={statement.title} statement={statement} />
            ))}
          </div>
        </section>
      </div>
      <div className="about-page about-page--light">
        {pageLinks[PATH.ABOUT].theologicalAdvisors.isVisible && (
          <section
            className="about-page__section u-flex u-flexCol u-flexAlignItemsCenter"
            id={pageLinks[PATH.ABOUT].theologicalAdvisors.id}
          >
            <h2>Theological Advisors</h2>
            <p className="serif-heading u-opacity8 about-page__sof-details u-textCenter">
              Our group of theologians, seminarians, and pastors offer doctrinal
              oversight for each of our Gospel tracts.
            </p>
            <TeamMembers />
          </section>
        )}
        {hasEndorsements && (
          <section className="about-page__section about-page__section--no-border u-flex u-flexCol u-flexAlignItemsCenter">
            <h2>Endorsements</h2>
            <p className="serif-heading u-opacity8 about-page__sof-details u-textCenter">
              Maecenas faucibus mollis interdum sociis natoque penatibus et
              magnis dis parturient montes, nascetur.
            </p>
            <Endorsements />
          </section>
        )}
        <section className="about-page__section about-page__section--no-border u-flex u-flexCol u-flexAlignItemsCenter">
          <h1
            className="u-textCenter about-page__ready"
            style={{ maxWidth: 536 }}
          >
            Are you ready to Mail The Gospel?
          </h1>
          <Link
            to={`${PATH.HOME}/${STEP.WHO_IS_MAILING}`}
            className="button button--primary button--large"
            style={{ textDecoration: "none", marginTop: 80, marginBottom: 80 }}
          >
            Get Started
          </Link>
          <div style={{ overflow: "hidden" }}>
            <img
              src="/assets/mail-box.svg"
              alt="mailbox"
              style={{ marginBottom: 80 }}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
