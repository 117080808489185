import React from "react";
import { CustomLiteraturePayload } from "../../../../../server/src/contracts/payment-intent";
import { RecipientPayload } from "../../../../../server/src/contracts/recipient";
import { ShippingRate } from "../../../../../server/src/contracts/shipping";
import { useGetProductsHook } from "../../../common/product-service";
import { createAddress } from "../../../utils/address";
import { STATUS } from "../../../utils/use-fetch-hook";
import { CalculateShipping } from "./calculate-shipping";
import { Cart } from "./cart";
import { ChooseTract } from "./choose-tract";
import { CollectCustomerInfo } from "./collect-customer-info";
import { CustomLiterature } from "./custom-literature";
import { ReadFile } from "./read-file";
import { CustomerInfo } from "./types";

enum STEP {
  UPLOAD_FILE = "UPLOAD_FILE",
  CUSTOM_LITERATURE = "CUSTOM_LITERATURE",
  ENTER_BILLING = "ENTER_BILLING",
  CHOOSE_TRACT = "CHOOSE_TRACT",
  CALCULATE_SHIPPING = "CALCULATE_SHIPPING",
  CART = "CART",
  CHECKOUT = "CHECKOUT",
}

export function BulkGospel() {
  const [step, setStep] = React.useState(STEP.UPLOAD_FILE);
  const [recipients, setRecipients] = React.useState([] as RecipientPayload[]);
  const [customLiterature, setCustomLiterature] =
    React.useState<CustomLiteraturePayload>(null);
  const [customerInfo, setCustomerInfo] = React.useState<CustomerInfo>({
    fullName: "",
    email: "",
    billingAddress: createAddress(),
  });
  const [tractId, setTractId] = React.useState<string | null>(null);
  const [shippingRate, setShippingRate] = React.useState<ShippingRate | null>(
    null,
  );

  const productsState = useGetProductsHook();

  const handleRecipientChange = React.useCallback(
    (newRecipients: RecipientPayload[]): void => {
      setRecipients(newRecipients);
      setStep(STEP.CUSTOM_LITERATURE);
    },
    [setRecipients, setStep],
  );

  const handleCustomLiteratureChange = React.useCallback(
    (newCustomLiterature: CustomLiteraturePayload) => {
      setCustomLiterature(newCustomLiterature);
      setStep(STEP.ENTER_BILLING);
    },
    [setCustomLiterature, setStep],
  );

  const handleCustomerInfoChange = React.useCallback(
    (newCustomerInfo: CustomerInfo) => {
      setCustomerInfo(newCustomerInfo);
      setStep(STEP.CHOOSE_TRACT);
    },
    [setCustomerInfo, setStep],
  );

  const handleTractChange = React.useCallback(
    (newTractId: string) => {
      setTractId(newTractId);
      setStep(STEP.CALCULATE_SHIPPING);
    },
    [setTractId, setStep],
  );

  const handleShippingRateChange = React.useCallback(
    (newShippingRate: ShippingRate) => {
      setShippingRate(newShippingRate);
      setStep(STEP.CART);
    },
    [setShippingRate, setStep],
  );

  React.useEffect(() => {
    if (productsState.status === STATUS.DONE && !tractId) {
      const firstTract = productsState.payload.tracts[0];
      if (firstTract) {
        setTractId(firstTract.id);
      }
    }
  }, [setTractId, productsState, tractId]);

  if (productsState.status !== STATUS.DONE) {
    return (
      <h4>
        {productsState.status === STATUS.ERROR
          ? "Unable to load products!"
          : "Loading..."}
      </h4>
    );
  }

  return (
    <div className="u-margin3gu u-flexExpandLeft u-flexExpandRight u-flex u-flexCol u-flexJustifyCenter u-flexAlignItemsCenter">
      {step === STEP.UPLOAD_FILE && <ReadFile onDone={handleRecipientChange} />}
      {step === STEP.CUSTOM_LITERATURE && (
        <CustomLiterature
          initialCustomLiterature={customLiterature}
          onDone={handleCustomLiteratureChange}
        />
      )}
      {step === STEP.ENTER_BILLING && (
        <CollectCustomerInfo
          initialCustomerInfo={customerInfo}
          onDone={handleCustomerInfoChange}
        />
      )}
      {step === STEP.CHOOSE_TRACT && (
        <ChooseTract
          tracts={productsState.payload.tracts}
          initialTractId={tractId}
          onDone={handleTractChange}
        />
      )}
      {step === STEP.CALCULATE_SHIPPING && (
        <CalculateShipping
          recipients={recipients}
          onDone={handleShippingRateChange}
        />
      )}
      {step === STEP.CART &&
        (tractId && shippingRate ? (
          <Cart
            products={productsState.payload}
            orderInformation={{
              ...customerInfo,
              donation: null,
              initialTractId: null,
              tractPackShippingRate: null,
              shippingAddress: createAddress(),
              bulkTractOrders: [],
              tracts: [
                {
                  tractId,
                  recipients: recipients.map((recipient) => ({
                    ...recipient,
                    displayShippingCost: shippingRate.displayShipmentCost,
                    shippingCost: shippingRate.shipmentCost,
                    shippingServiceType: shippingRate.serviceType,
                  })),
                },
              ],
              customLiterature,
            }}
          />
        ) : (
          <h3>
            Something went wrong. No tract selected and/or no shipping rate.
          </h3>
        ))}
    </div>
  );
}
