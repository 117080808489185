import React from "react";

export interface Statement {
  title: string;
  preview: string;
  full: React.ReactElement;
}

export const statements: Statement[] = [
  {
    title: "On Faith",
    preview:
      "We believe the Bible is the final standard of faith and practice for the believer in Jesus Christ and for His Church. While recognizing the historical, interpretive and guiding value of creeds and statements of faith made throughout the history of the Church, we affirm the Bible alone as the infallible and final authority in the life of a believer.",
    full: (
      <React.Fragment>
        <p>
          We believe in the Holy Scriptures as originally given by God, divinely
          inspired, infallible, entirely trustworthy; and the supreme authority
          in all matters of faith and conduct; One God, eternally existent in
          three persons, Father, Son, and Holy Spirit;
        </p>
        <p>
          We believe our Lord Jesus Christ, God manifest in the flesh, His
          virgin birth, His sinless human life, His divine miracles, His
          vicarious and atoning death, His bodily resurrection, His ascension,
          His mediatorial work, and His personal return in power and glory;
        </p>

        <p>
          We believe the Salvation of lost and sinful man through the shed blood
          of the Lord Jesus Christ by faith apart from works, and regeneration
          by the Holy Spirit;
        </p>

        <p>
          We believe the Holy Spirit, by Whose indwelling the believer is
          enabled to live a holy life, to witness and work for the Lord Jesus
          Christ;
        </p>

        <p>
          We believe the Unity of the Spirit of all true believers, the Church,
          the Body of Christ;
        </p>

        <p>
          We believe the Resurrection of both the saved and the lost; they that
          are saved unto the resurrection of life, they that are lost unto the
          resurrection of damnation.
        </p>
      </React.Fragment>
    ),
  },
  {
    title: "On Scripture",
    preview:
      "We believe that God has revealed Himself and His truth by both general and special revelation. General revelation displays his existence, power, providence, moral standard, patience, goodness, and glory; special revelation manifests His triune nature and His plan of redemption through Messiah for humanity. This special revelation has been given in various ways, preeminently in the incarnate Word of God, Jesus Christ, and in the inscripturated Word of God, the Bible. We affirm that the sixty-six books of the Bible are the written Word of God given by the Holy Spirit and are the complete and final canonical revelation of God for all time. (Rom. 1:18-2:4; 2:14-16; Ps. 19; Acts 14:15-17; 17:22-31; John 1:1- 18; 1 Thess. 2:13; Heb. 1:1-2; 4:12)",
    full: (
      <React.Fragment>
        <p>
          We believe these books were written by a process of dual authorship in
          which the Holy Spirit so moved the human authors that, through their
          individual personalities and styles, they composed and recorded God's
          Word which is inerrant. These books, constituting the written Word of
          God, convey objective truth and are the believer's only infallible
          rule of faith and practice. (2 Tim. 3:16-17; 2 Pet. 1:19-20; John
          10:35; 17:17; 1 Cor. 2:10-13)
        </p>
      </React.Fragment>
    ),
  },
  {
    title: "On The Church",
    preview:
      "We believe the Church is the people of God, initiated at Pentecost and completed at the return of Christ who is its head. The mission of the Church is to glorify God by worshiping corporately, building itself up as a loving, faithful community by instruction of the Word, observing baptism and communion, embracing the doctrinal mandates of the apostles, communicating the Gospel and making disciples of all peoples. (Matthew.  16:18; 28:16-20; Acts 1:4, 5; 11:15; 2:46, 47; 1 Cor. 12:13; Rom.  12:4-21; Eph. 1:22, 23; 2:19-22; 3:4-6; 5:25-27; Col. 1:18; Rev. 5:9)",
    full: (
      <React.Fragment>
        <p>
          We believe Christians should gather together in local assemblies. They
          are priests before God and to one another, responsible to serve God
          and minister to each other. The biblically designated officers serving
          under Christ and leading the assembly are elders and deacons. Although
          church and state are distinct institutions, believers are to submit to
          the government within the limits of God's Word. (Matt. 18:15-18;
          22:15-22; 28:19; Acts 2:41, 42; 6:1-6; 1 Cor. 14:40; Eph. 4:11, 12; 1
          Tim. 3:1-13; Tit. 1:5-9; Heb. 10:25; 1 Pet. 2:5-10, 13-17; 5:1-5)
        </p>
      </React.Fragment>
    ),
  },
];
