import React from "react";

export function useToggleHook(
  initialValue: boolean
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] {
  const [state, setState] = React.useState(initialValue);

  const toggleState = React.useCallback(() => {
    setState((prev) => !prev);
  }, [setState]);

  return [state, toggleState, setState];
}
