import { Tract } from "../../../server/src/contracts/tract";
import { apiHost } from "../api-host";
import { API } from "../path";

export function getTractCoverImage(tractId: Tract["id"]): string {
  return `${apiHost}${API.TRACT_COVER_IMAGE}/${tractId}.jpg`;
}

export function getTractBackground<
  TractLike extends Pick<Tract, "backgroundColorStart" | "backgroundColorEnd">
>({ backgroundColorStart, backgroundColorEnd }: TractLike): string {
  return `linear-gradient(${backgroundColorStart}, ${backgroundColorEnd})`;
}
