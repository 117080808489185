import React from "react";
import {
  DonationPageResult,
  DonationWithEvangelistDTO,
} from "../../../../../server/src/contracts/donate";
import { API } from "../../../path";
import { STATUS, useLazyFetchHook } from "../../../utils/use-fetch-hook";
import { EvangelistLink } from "../evangelist-link";
import { Table, TableHeader } from "../table";
import { ViewDate } from "../view-date";

const headers: TableHeader<DonationWithEvangelistDTO>[] = [
  {
    key: "date",
    displayName: "Date",
    Component: ({ data }) => <ViewDate date={data.date} />,
  },
  {
    key: "displayAmount",
    displayName: "Amount",
  },
  {
    key: "evangelistFullName",
    displayName: "Name",
    Component: ({ data }) => (
      <EvangelistLink
        id={data.evangelistId}
        displayFullName={data.evangelistFullName}
      />
    ),
  },
  {
    key: "evangelistEmail",
    displayName: "Email",
    Component: ({ data }) => (
      <EvangelistLink
        id={data.evangelistId}
        displayFullName={data.evangelistEmail}
      />
    ),
  },
];

export function Donations() {
  const [page, setPage] = React.useState(1);

  const [donationApi, getDonations] = useLazyFetchHook<
    { page: number },
    DonationPageResult
  >();

  React.useEffect(() => {
    getDonations({
      method: "get",
      url: API.DONATE,
      urlParams: { page },
    });
  }, [getDonations, page]);

  switch (donationApi.status) {
    case STATUS.IDLE:
    case STATUS.LOADING: {
      return <h4>Loading...</h4>;
    }
    case STATUS.ERROR: {
      return <h4>Error!</h4>;
    }
    case STATUS.DONE: {
      return (
        <div className="u-flex u-flexCol u-sizeFull u-flexGrow1 u-textLeft u-margin3gu">
          <h4 className="u-margin3gu">Donations</h4>
          <Table
            rows={donationApi.payload.results}
            idProp="id"
            headers={headers}
          />
          <div className="u-flex u-flexJustifyEnd u-marginRight2gu">
            <button
              className="button button--small button--primary u-marginRight1gu"
              disabled={donationApi.payload.pagination.previousPage === null}
              onClick={() => {
                const prevPage = donationApi.payload.pagination.previousPage;
                if (prevPage !== null) {
                  setPage(prevPage);
                }
              }}
            >
              Previous
            </button>
            <button
              className="button button--small button--primary"
              disabled={donationApi.payload.pagination.nextPage === null}
              onClick={() => {
                const nextPage = donationApi.payload.pagination.nextPage;
                if (nextPage !== null) {
                  setPage(nextPage);
                }
              }}
            >
              Next
            </button>
          </div>
        </div>
      );
    }
  }
}
