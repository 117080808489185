import React from "react";
import {
  EvangelistPageResult,
  EvangelistDTO,
  EvangelistSearchList,
} from "../../../../../server/src/contracts/evangelist";
import { API } from "../../../path";
import { STATUS, useLazyFetchHook } from "../../../utils/use-fetch-hook";
import { EvangelistLink } from "../evangelist-link";
import { Table, TableHeader } from "../table";
import { SearchBar } from "./search-bar";

const headers: TableHeader<EvangelistDTO>[] = [
  {
    key: "displayFullName",
    displayName: "Full Name",
    Component: function View({ data }) {
      return <EvangelistLink {...data} />;
    },
  },
  {
    key: "email",
    displayName: "Email",
  },
  {
    key: "phoneNumber",
    displayName: "Phone Number",
  },
];

export function EvangelistTable() {
  const [search, setSearch] = React.useState({
    page: 1,
    email: "",
  } as Omit<EvangelistSearchList, "view">);

  const [evangelistApi, getEvangelists] = useLazyFetchHook<
    EvangelistSearchList,
    EvangelistPageResult
  >();

  React.useEffect(() => {
    getEvangelists({
      method: "get",
      url: API.EVANGELIST,
      urlParams: {
        view: "list",
        page: search.page,
        email: search.email.trim(),
      },
    });
  }, [getEvangelists, search]);

  switch (evangelistApi.status) {
    case STATUS.IDLE:
    case STATUS.LOADING: {
      return <h4>Loading...</h4>;
    }
    case STATUS.ERROR: {
      return <h4>Error!</h4>;
    }
    case STATUS.DONE: {
      return (
        <div className="u-flex u-flexCol u-sizeFull u-flexGrow1">
          <h4 className="u-margin3gu">Evangelists</h4>
          <SearchBar
            initialEmailSearch={search.email}
            onSearchChange={(newEmail) => {
              setSearch({ page: 1, email: newEmail });
            }}
          />
          <div className="u-flex u-sizeFull u-flexGrow1">
            <div className="u-flex u-flexCol u-light u-sizeFull u-textLeft u-margin3gu">
              <Table
                rows={evangelistApi.payload.results}
                idProp="id"
                headers={headers}
              />
              <div className="u-flex u-flexJustifyEnd u-marginRight2gu">
                <button
                  className="button button--small button--primary u-marginRight1gu"
                  disabled={
                    evangelistApi.payload.pagination.previousPage === null
                  }
                  onClick={() => {
                    const prevPage =
                      evangelistApi.payload.pagination.previousPage;
                    if (prevPage !== null) {
                      setSearch((prevSearch) => ({
                        ...prevSearch,
                        page: prevPage,
                      }));
                    }
                  }}
                >
                  Previous
                </button>
                <button
                  className="button button--small button--primary"
                  disabled={evangelistApi.payload.pagination.nextPage === null}
                  onClick={() => {
                    const nextPage = evangelistApi.payload.pagination.nextPage;
                    if (nextPage !== null) {
                      setSearch((prevSearch) => ({
                        ...prevSearch,
                        page: nextPage,
                      }));
                    }
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
