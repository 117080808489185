import React from "react";
import { CustomLiteratureStats } from "../../../../../server/src/contracts/custom-literature";
import { CustomLiteraturePayload } from "../../../../../server/src/contracts/payment-intent";
import { RadioInput } from "../../../common/radio-input";
import { SelectThing } from "../../../common/select-thing";
import { TextInput } from "../../../common/text-input";
import { API } from "../../../path";
import { STATUS, useFetchHook } from "../../../utils/use-fetch-hook";

interface CustomLiteratureProps {
  initialCustomLiterature: CustomLiteraturePayload;
  onDone: (newLiteratureName: CustomLiteraturePayload) => void;
}

const emptyCustomLiterature: CustomLiteratureStats = {
  id: "",
  name: "-- Please Select an option --",
  number: 0,
  paidTransactionCount: 0,
  pendingTransactionCount: 0,
  totalTransactionCount: 0,
};

export function CustomLiterature({
  initialCustomLiterature,
  onDone,
}: CustomLiteratureProps) {
  const customLiteratureState = useFetchHook<null, CustomLiteratureStats[]>({
    method: "get",
    url: API.CUSTOM_LITERATURE,
  });
  const [selectedCustomLiterature, setSelectedCustomLiterature] =
    React.useState(emptyCustomLiterature);
  const [customLiterature, setCustomLiterature] = React.useState(
    initialCustomLiterature
  );

  const setNoCustomLiterature = React.useCallback(() => {
    setCustomLiterature(null);
  }, [setCustomLiterature]);

  const setExistingLiterature = React.useCallback(
    (newSelectedCustomLiterature: CustomLiteratureStats) => {
      setCustomLiterature({
        type: "existing",
        id: newSelectedCustomLiterature.id,
      });
      setSelectedCustomLiterature(newSelectedCustomLiterature);
    },
    [setCustomLiterature]
  );

  const setNewLiterature = React.useCallback(
    (name: string) => {
      setCustomLiterature({ type: "new", name });
    },
    [setCustomLiterature]
  );

  const hasSelectedExistingLiterature =
    customLiterature !== null && customLiterature.type === "existing";
  const hasSelectedNewLiterature =
    customLiterature !== null && customLiterature.type === "new";

  const isReady = Boolean(
    !customLiterature ||
      (customLiterature.type === "new" && customLiterature.name) ||
      (customLiterature.type === "existing" && customLiterature.id)
  );

  const handleDone = React.useCallback(() => {
    if (isReady) {
      onDone(customLiterature);
    }
  }, [isReady, onDone, customLiterature]);

  return (
    <div className="u-flex u-flexCol" style={{ width: 340 }}>
      <h3>Custom Literature</h3>
      <p>
        Enter a name here that will be meaningful to who ever will be fulfilling
        these orders. This will be given an id which will then be used in the
        picking-list short code sent to ShipStation.
      </p>
      <RadioInput
        id="no-custom-literature"
        className=""
        isChecked={customLiterature === null}
        label={<span className="u-text-black">No custom literature</span>}
        onChange={setNoCustomLiterature}
        name="no-custom-literature"
      />
      <RadioInput
        id="existing-custom-literature"
        name="existing-custom-literature"
        className=""
        isChecked={hasSelectedExistingLiterature}
        label={
          <span className="u-text-black">Use existing custom literature</span>
        }
        onChange={() => {
          setCustomLiterature({
            type: "existing",
            id: selectedCustomLiterature.id,
          });
        }}
      />
      {hasSelectedExistingLiterature && (
        <div className="u-flex">
          {customLiteratureState.status === STATUS.DONE ? (
            <SelectThing
              id="existing-literature-select"
              options={[emptyCustomLiterature].concat(
                customLiteratureState.payload
              )}
              onChange={setExistingLiterature}
              value={selectedCustomLiterature}
              formatOption={(literature) => literature.name}
            />
          ) : customLiteratureState.status === STATUS.ERROR ? (
            <h4>Unable to load existing custom literature!</h4>
          ) : (
            <h4>Loading...</h4>
          )}
        </div>
      )}
      <RadioInput
        id="new-custom-literature"
        name="new-custom-literature"
        className=""
        isChecked={hasSelectedNewLiterature}
        label={<span className="u-text-black">New custom literature</span>}
        onChange={() => {
          setNewLiterature("");
        }}
      />
      {hasSelectedNewLiterature && (
        <TextInput
          id="custom-literature-name"
          onChange={(e) => {
            setNewLiterature(e.target.value);
          }}
          placeholder="Custom Literature Name"
          className="u-marginTop5gu"
          value={customLiterature.name}
        />
      )}
      <button
        disabled={!isReady}
        onClick={handleDone}
        className="button button--primary button--large u-marginTop3gu u-flexExpandLeft"
      >
        Next
      </button>
    </div>
  );
}
