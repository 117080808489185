import { Address } from "../../../server/src/contracts/address";
import { COUNTRY } from "../pages/home/country-select";
import { countryOptions } from "../pages/home/state-select";

export function createAddress(): Address {
  return {
    country: COUNTRY.US,
    address1: "",
    address2: "",
    city: "",
    state: countryOptions[COUNTRY.US][0],
    zip: "",
  };
}

export function isEmptyAddress(address: Address): boolean {
  return (
    !address.address1 && !address.address2 && !address.city && !address.zip
  );
}

const canadianPostalRegex = new RegExp(
  /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ]( )?\d[ABCEGHJKLMNPRSTVWXYZ]\d$/i
);
function checkCanadianPostal(postal: string): boolean {
  return canadianPostalRegex.test(postal);
}

const usZipRegex = /^\d{5}(?:[-\s]\d{4})?$/;
function checkUsZip(zip: string): boolean {
  return usZipRegex.test(zip);
}

const postalZipValidator: Record<COUNTRY, (postalZip: string) => boolean> = {
  [COUNTRY.US]: checkUsZip,
  [COUNTRY.CA]: checkCanadianPostal,
};

export function isAddressValid<
  AddressLike extends Pick<
    Address,
    "country" | "address1" | "city" | "state" | "zip"
  >
>({ country, address1, city, zip, state }: AddressLike): boolean {
  const validator = postalZipValidator[country as COUNTRY];
  return Boolean(
    address1 && city && state && zip && validator && validator(zip)
  );
}
