import React from "react";
import { PATH } from "../../path";
import "./ribbon.css";

export interface RibbonProps {
  path: PATH.ORDER_REVIEW | PATH.CHECKOUT;
}

export function Ribbon({ path }: RibbonProps) {
  return (
    <>
      <div className="ribbon u-bg-black u-text-offWhite u-flex u-flexAlignItemsCenter u-sizeFull">
        <p
          className={`${
            path === PATH.ORDER_REVIEW ? "u-textBold " : ""
          }u-sm-marginLeft6gu u-md-marginLeft9gu u-lg-marginLeft12gu`}
        >
          Order Review
        </p>
        <div className="ribbon__chevron"></div>
        <p className={path === PATH.CHECKOUT ? "u-textBold" : undefined}>
          Checkout
        </p>
      </div>
    </>
  );
}
