import React from "react";
import Slider from "react-slick";
import { RecipientPayload } from "../../../../../server/src/contracts/recipient";
import { getAddOnImageSrc } from "../../../common/get-add-on-image-src";
import { RecipientUpdate } from "../../../interfaces";
import { AddOn } from "../interfaces";
import "./add-on-slider.css";

interface AddOnSliderProps {
  recipient: RecipientPayload;
  tractId: string;
  addOns: AddOn[];
  onChange: (recipientUpdate: RecipientUpdate) => void;
}

export function AddOnSlider({
  addOns,
  recipient,
  tractId,
  onChange,
}: AddOnSliderProps) {
  const recipientId = recipient.id;
  const [active, setActive] = React.useState(0);

  return (
    <div className="u-flex u-flexWrap u-flexAlignItemsCenter rounded add-on">
      <h3 className="u-sizeFull add-on__header">Add a Bible</h3>
      <div className="add-on__slider-container">
        <Slider
          adaptiveHeight
          dots
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          beforeChange={(_, newActive) => {
            setActive(newActive);
          }}
        >
          {addOns.map((addOn) => (
            <div
              key={addOn.id}
              className="u-flex u-sizeFull u-flexAlignItemsCenter"
            >
              <div className="u-size1of3">
                <img
                  style={{ width: "100%" }}
                  src={getAddOnImageSrc(addOn.id)}
                  alt={addOn.title}
                />
              </div>
              <div className="u-textLeft u-size2of3">
                <div className="add-on__title">{addOn.title}</div>
                <div className="add-on__description">{addOn.description}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="rule rule--extra-thick rule--cream u-opacity2 add-on__divider u-sizeFull" />
      <div className="u-flex u-sizeFill add-on__footer">
        <div className="add-on__price rounded u-price u-bg-creamMid u-border-cream">
          {addOns[active].displayPrice.replace("$", "$ ")}
          <span className="add-on__price-each">/ea</span>
        </div>

        {recipient.addOnId === addOns[active].id ? (
          <button
            onClick={() => {
              onChange({
                tractId,
                recipientId,
                partialRecipient: { addOnId: null },
              });
            }}
            className="u-sizeFill u-flexAlignSelfStretch button button--primary button--selected"
          >
            Added
          </button>
        ) : (
          <button
            onClick={() => {
              onChange({
                tractId,
                recipientId,
                partialRecipient: { addOnId: addOns[active].id },
              });
            }}
            className="u-sizeFill u-flexAlignSelfStretch button button--primary"
          >
            Add to order
          </button>
        )}
      </div>
    </div>
  );
}
