import React from "react";
import { STATUS } from "../../../utils/use-fetch-hook";
import { useGetProductsHook } from "../../../common/product-service";
import { TabContainer } from "./tabs";
import { ManageTracts } from "./manage-tracts";
import { ManageAddOns } from "./manage-add-ons";
import { TabProps } from "./interfaces";

function ManagePromotion() {
  return (
    <div>
      <h3>Promotions</h3>
      <div className="u-margin3gu">Not Implemented</div>
    </div>
  );
}

export function Manage() {
  const productsState = useGetProductsHook();

  switch (productsState.status) {
    case STATUS.IDLE:
    case STATUS.LOADING: {
      return <h4>Loading...</h4>;
    }
    case STATUS.ERROR: {
      return <h4>Error!</h4>;
    }
    case STATUS.DONE: {
      return (
        <div className="u-flex u-flexCol u-light u-sizeFull">
          <h4 className="u-margin3gu">Manage</h4>
          <TabContainer
            tabProps={
              {
                products: productsState.payload,
                refetch: productsState.refetch,
              } as TabProps
            }
            tabs={[
              { title: "Tracts", Component: ManageTracts },
              { title: "Add Ons", Component: ManageAddOns },
              { title: "Promotions", Component: ManagePromotion },
            ]}
          />
        </div>
      );
    }
  }
}
