import React from "react";
import { API } from "../../../path";
import {
  STATUS,
  useFetchHook,
  useLazyFetchHook,
} from "../../../utils/use-fetch-hook";
import { StampsStatus } from "../../../../../server/src/contracts/stamps-status";

export function Stamps() {
  const stampsStatusState = useFetchHook<null, StampsStatus>({
    method: "get",
    url: API.STAMPS_STATUS,
  });

  const [removeState, callRemove] = useLazyFetchHook<null, null>();

  const removeAuthorization = React.useCallback(() => {
    callRemove({
      method: "delete",
      url: API.STAMPS_STATUS,
    });
  }, [callRemove]);

  React.useEffect(() => {
    if (
      removeState.status === STATUS.DONE &&
      stampsStatusState.status === STATUS.DONE
    ) {
      stampsStatusState.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [removeState.status]);

  switch (stampsStatusState.status) {
    case STATUS.IDLE:
    case STATUS.LOADING: {
      return (
        <h4 className="u-flexExpand">
          Loading authorization status with <code>stamps.com</code>
        </h4>
      );
    }
    case STATUS.ERROR: {
      return (
        <h4 className="u-flexExpand">
          Unable to determine status of authorization!
        </h4>
      );
    }
    case STATUS.DONE: {
      return (
        <div className="u-flex u-flexCol u-light u-sizeFull u-flexJustifyCenter u-flexAlignItemsCenter">
          {stampsStatusState.payload.isAuthorized ? (
            <>
              <h4>
                <code>MailTheGospel</code> is authorized with{" "}
                <code>stamps.com</code>!
              </h4>

              <ul>
                {Object.entries(stampsStatusState.payload).map(
                  ([name, value]) => (
                    <li key={name}>
                      <strong>{String(name)}: </strong>
                      {String(value).substring(0, 20)}
                      {String(value).length > 20 ? "..." : ""}
                    </li>
                  )
                )}
              </ul>

              <h5>
                You can remove authorization - but BEWARE this will disable
                shipping information being shown to users!
              </h5>
              <button
                onClick={removeAuthorization}
                className="button button--small button--alert"
              >
                Remove
              </button>
            </>
          ) : (
            <>
              <h4>
                <code>MailTheGospel</code> is <b>NOT</b> authorized with{" "}
                <code>stamps.com</code>.
              </h4>
              <h5>Use the link below to authorize</h5>
              <a href={stampsStatusState.payload.authorizationUrl}>Authorize</a>
            </>
          )}
        </div>
      );
    }
  }
}
