import React from "react";
import { apiHost } from "../api-host";
import { API } from "../path";

interface FetchParamsBase {
  url: API;
  headers?: Record<string, string>;
}

type FetchParams<RequestPayload = never> =
  | (FetchParamsBase & {
      method: "get";
      urlParams?: Record<string, string | number>;
    })
  | (FetchParamsBase & {
      method: "post" | "put" | "delete";
      body?: RequestPayload;
      formData?: FormData;
    });

function getQueryString(urlParams: Record<string, string | number>) {
  const params = Object.entries(urlParams).reduce(
    (acc, [key, value]) => {
      acc[key] =
        typeof value === "string"
          ? value
          : value.toString
            ? value.toString()
            : String(value);
      return acc;
    },
    {} as Record<string, string>,
  );
  return "?" + new URLSearchParams(params).toString();
}

async function callFetch<RequestPayload, ResponseType>({
  url,
  headers,
  ...params
}: FetchParams<RequestPayload>): Promise<ResponseType> {
  const body =
    params.method === "get"
      ? undefined
      : params.formData
        ? params.formData
        : params.body
          ? JSON.stringify(params.body)
          : undefined;
  const urlParams =
    params.method === "get" && params.urlParams
      ? getQueryString(params.urlParams)
      : "";
  const fullUrl = apiHost + url + urlParams;
  const resp = await fetch(fullUrl, {
    method: params.method,
    body,
    headers: headers || {
      "content-type": "application/json",
    },
  });
  if (resp.status >= 400) {
    throw new Error();
  }
  const json = (await resp.json()) as ResponseType;
  return json;
}

export enum STATUS {
  IDLE = "IDLE",
  LOADING = "LOADING",
  ERROR = "ERROR",
  DONE = "DONE",
}

export type State<Params, Result> =
  | { status: STATUS.IDLE }
  | { status: STATUS.LOADING }
  | { status: STATUS.ERROR; error: Error }
  | {
      status: STATUS.DONE;
      payload: Result;
      refetch: (newParams?: FetchParams<Params>) => void;
    };

export function useFetchHook<RequestPayload, ResponseType>(
  initialParams: FetchParams<RequestPayload>,
): State<RequestPayload, ResponseType> {
  const [isLoading, setIsLoading] = React.useState(false);
  const [networkError, setNetworkError] = React.useState<Error>();
  const [payload, setPayload] = React.useState<ResponseType>();

  const [params] = React.useState(initialParams);

  const refetch = React.useCallback(
    (newParams = params) => {
      setIsLoading(true);
      callFetch<RequestPayload, ResponseType>(newParams)
        .then((res) => {
          setPayload(res);
        })
        .catch((err) => {
          setNetworkError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [params, setIsLoading],
  );

  React.useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return { status: STATUS.LOADING };
  }
  if (networkError) {
    return { status: STATUS.ERROR, error: networkError };
  }
  if (payload) {
    return { status: STATUS.DONE, payload, refetch };
  }
  return { status: STATUS.IDLE };
}

export function useLazyFetchHook<RequestPayload, ResponseType>(): [
  State<RequestPayload, ResponseType>,
  (fetchParams: FetchParams<RequestPayload>) => void,
] {
  const [isLoading, setIsLoading] = React.useState(false);
  const [networkError, setNetworkError] = React.useState<Error>();
  const [payload, setPayload] = React.useState<ResponseType>();
  const [lastParams, setLastParams] =
    React.useState<FetchParams<RequestPayload>>();

  const callApi = React.useCallback(
    (fetchParams: FetchParams<RequestPayload>) => {
      setLastParams(fetchParams);
      setIsLoading(true);
      setNetworkError(undefined);
      setPayload(undefined);
      callFetch<RequestPayload, ResponseType>(fetchParams)
        .then((res) => {
          setPayload(res);
        })
        .catch((err) => {
          setNetworkError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [setPayload, setNetworkError, setIsLoading],
  );

  const refetch = React.useCallback(() => {
    if (lastParams) {
      callApi(lastParams);
    }
  }, [lastParams, callApi]);

  if (isLoading) {
    return [{ status: STATUS.LOADING }, callApi];
  }
  if (networkError) {
    return [{ status: STATUS.ERROR, error: networkError }, callApi];
  }
  if (payload !== undefined) {
    return [{ status: STATUS.DONE, payload, refetch }, callApi];
  }
  return [{ status: STATUS.IDLE }, callApi];
}
