import React from "react";
import { Link } from "react-router-dom";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  CreatePaymentIntentResponse,
  UpdatePaymentIntentPayload,
} from "../../../../server/src/contracts/payment-intent";
import { API, PATH } from "../../path";
import { Products, StepProps } from "../home/interfaces";
import { SummaryTitle } from "../../common/summary-title";
import { BillingForm } from "../../common/billing-form";
import { TotalSummary } from "../home/total-summary";
import { Section, Wrapper } from "../home/light-theme";
import { Address } from "../../../../server/src/contracts/address";
import { isAddressValid, isEmptyAddress } from "../../utils/address";
import { STATUS, useLazyFetchHook } from "../../utils/use-fetch-hook";

interface CheckoutFormProps
  extends Pick<StepProps, "orderInformation" | "setOrderInformation"> {
  paymentIntentResponse: CreatePaymentIntentResponse;
  products: Products;
}

export function CheckoutForm({
  orderInformation,
  paymentIntentResponse: {
    taxRates,
    clientSecret,
    transactionId,
    paymentIntentId,
  },
  products,
  setOrderInformation,
}: CheckoutFormProps) {
  const [initialOrderInformation] = React.useState(orderInformation);

  /** default billing address to bulk tract order address if present */
  React.useEffect(() => {
    if (
      isEmptyAddress(initialOrderInformation.billingAddress) &&
      initialOrderInformation.shippingAddress
    ) {
      const defaultBillingAddress = {
        ...initialOrderInformation.shippingAddress,
      };
      setOrderInformation((o) => ({
        ...o,
        billingAddress: defaultBillingAddress,
      }));
    }
  }, [initialOrderInformation, setOrderInformation]);

  const [isAgreed, setIsAgreed] = React.useState(false);
  const [isPaymentValid, setIsPaymentValid] = React.useState(false);

  const toggleIsAgreed = React.useCallback(() => {
    setIsAgreed((p) => !p);
  }, [setIsAgreed]);

  const updateBilling = React.useCallback(
    (addressUpdate: Partial<Address>) => {
      setOrderInformation((prev) => ({
        ...prev,
        billingAddress: {
          ...prev.billingAddress,
          ...addressUpdate,
        },
      }));
    },
    [setOrderInformation]
  );

  const elements = useElements();
  const stripe = useStripe();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const isValidAddress = isAddressValid(orderInformation.billingAddress);

  const [paymentIntentUpdate, updatePaymentIntent] = useLazyFetchHook<
    UpdatePaymentIntentPayload,
    CreatePaymentIntentResponse
  >();

  /** updates the MTG database transaction */
  const startOrderProcessing = React.useCallback(() => {
    if (isAgreed && isValidAddress && isPaymentValid) {
      updatePaymentIntent({
        method: "put",
        url: API.PAYMENT_INTENT,
        body: {
          ...orderInformation,
          transactionId,
          clientSecret,
          paymentIntentId,
        },
      });
    }
  }, [
    isAgreed,
    isValidAddress,
    isPaymentValid,
    updatePaymentIntent,
    orderInformation,
    transactionId,
    clientSecret,
    paymentIntentId,
  ]);

  /** once the MTG database is updated, process payment */
  React.useEffect(() => {
    if (
      !stripe ||
      !elements ||
      !isAgreed ||
      !isPaymentValid ||
      paymentIntentUpdate.status !== STATUS.DONE
    ) {
      return;
    }

    async function confirmPayment() {
      if (!stripe || !elements) {
        return;
      }
      try {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.origin + PATH.ORDER_CONFIRMED,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setErrorMessage(error.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      } catch (e) {
        setErrorMessage("Unable to submit payment!");
      }
    }

    setIsLoading(true);
    try {
      confirmPayment();
    } catch (e) {
      setErrorMessage("Unexpected error in confirming payment");
    } finally {
      setIsLoading(false);
    }
  }, [elements, isAgreed, isPaymentValid, stripe, paymentIntentUpdate]);

  if (isLoading && !errorMessage) {
    return (
      <h3 className="u-text-offWhite step-header u-text-black">
        Processing Order...
      </h3>
    );
  }

  return (
    <Wrapper>
      <Section>
        <section className="rounded-section">
          <SummaryTitle fontSize={18}>Billing Address</SummaryTitle>
          <div className="rule rule--extra-thick rule--gray u-marginTop3gu u-marginBottom6gu" />
          <BillingForm
            billing={orderInformation.billingAddress}
            onChange={updateBilling}
          />
        </section>
        <section className="rounded-section">
          <SummaryTitle fontSize={18}>Payment Details</SummaryTitle>
          <div className="rule rule--extra-thick rule--gray u-marginVert3gu" />
          {errorMessage && <b className="u-text-error">{errorMessage}</b>}
          <PaymentElement
            onChange={(e) => {
              setIsPaymentValid(e.complete);
            }}
            options={{ wallets: { applePay: "never", googlePay: "never" } }}
          />
        </section>
      </Section>
      <Section>
        <div className="u-lg-hidden u-marginTop5gu" />
        <section className="rounded-section rounded-section--row">
          <SummaryTitle fontSize={18}>Need Help?</SummaryTitle>
          <Link className="u-text-black u-flexExpandLeft" to={PATH.SUPPORT}>
            Contact Support
          </Link>
        </section>
        <div className="u-lg-hidden u-marginVert2gu" />
        <section className="rounded-section rounded-section--sm-square">
          <TotalSummary
            hasTaxRate
            taxRate={taxRates[orderInformation.billingAddress.state]}
            orderInformation={orderInformation}
            products={products}
          />
          <label
            htmlFor="agree-terms-and-conditions"
            className="u-marginBottom5gu"
            style={{ cursor: "pointer" }}
          >
            <input
              type="checkbox"
              name="agree-terms-and-conditions"
              id="agree-terms-and-conditions"
              style={{ margin: "5px 10px 5px 0" }}
              checked={isAgreed}
              onChange={toggleIsAgreed}
            />
            By placing your order you agree to our{" "}
            <Link to={PATH.TERMS_AND_CONDITIONS}>Terms &amp; Conditions</Link>{" "}
            and that you have read our Privacy Policy, including our Cookie use.
          </label>
          <button
            disabled={
              !isAgreed ||
              !isValidAddress ||
              !isPaymentValid ||
              paymentIntentUpdate.status === STATUS.LOADING ||
              paymentIntentUpdate.status === STATUS.DONE
            }
            onClick={startOrderProcessing}
            className="button button--primary button--large"
          >
            Mail the Gospel
          </button>
        </section>
      </Section>
    </Wrapper>
  );
}
