import React from "react";
import { FILE_TYPE } from "../pages/admin-panel/manage/file-input";
import { STATUS } from "./use-fetch-hook";

export type FileReaderOutput =
  | { status: STATUS.IDLE }
  | { status: STATUS.LOADING }
  | { status: STATUS.DONE; result: string }
  | { status: STATUS.ERROR; error: DOMException | Error };

export function useFileReader(file: File | undefined): FileReaderOutput {
  const [fileReaderOutput, setFileReaderOutput] =
    React.useState<FileReaderOutput>({ status: STATUS.IDLE });

  React.useEffect(() => {
    if (!file) {
      setFileReaderOutput({ status: STATUS.IDLE });
      return;
    }
    const fileReader = new FileReader();
    fileReader.addEventListener("load", () => {
      if (fileReader.result) {
        setFileReaderOutput({
          status: STATUS.DONE,
          result: fileReader.result as string,
        });
      } else {
        setFileReaderOutput({
          status: STATUS.ERROR,
          error: new Error("Empty file"),
        });
      }
    });
    fileReader.addEventListener("error", () => {
      setFileReaderOutput({
        status: STATUS.ERROR,
        error:
          fileReader.error ||
          new Error("Unexpected error parsing uploaded image"),
      });
    });
    if (file.type === FILE_TYPE.CSV) {
      fileReader.readAsText(file);
    } else {
      fileReader.readAsDataURL(file);
    }
    setFileReaderOutput({ status: STATUS.LOADING });
  }, [file]);

  return fileReaderOutput;
}

export function useMultiFileReader(files: File[]): FileReaderOutput[] {
  const [fileContents, setFileContents] = React.useState<
    Array<string | undefined>
  >([]);
  const [fileParsingErrors, setFileParsingErrors] = React.useState<
    Array<DOMException | Error | undefined>
  >([]);
  const [isReading, setIsReading] = React.useState(() =>
    files.map(() => false)
  );

  React.useEffect(() => {
    files.forEach((file, index) => {
      setFileContents((p) => [
        ...p.slice(0, index),
        undefined,
        ...p.slice(index + 1),
      ]);
      setFileParsingErrors((p) => [
        ...p.slice(0, index),
        undefined,
        ...p.slice(index + 1),
      ]);
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        if (fileReader.result) {
          setFileContents((p) => [
            ...p.slice(0, index),
            fileReader.result as string,
            ...p.slice(index + 1),
          ]);
        }
        setIsReading((p) => [
          ...p.slice(0, index),
          false,
          ...p.slice(index + 1),
        ]);
      });
      fileReader.addEventListener("error", () => {
        setFileParsingErrors((p) => [
          ...p.slice(0, index),
          fileReader.error ||
            new Error("Unexpected error parsing uploaded image"),
          ...p.slice(index + 1),
        ]);
        setIsReading((p) => [
          ...p.slice(0, index),
          false,
          ...p.slice(index + 1),
        ]);
      });
      fileReader.readAsDataURL(file);
      setIsReading((p) => [...p.slice(0, index), true, ...p.slice(index + 1)]);
    });
  }, [files]);

  return files.map((_, index) => {
    const result = fileContents[index];
    const error = fileParsingErrors[index];
    const isLoading = isReading[index];
    if (result !== undefined) {
      return { status: STATUS.DONE, result };
    }
    if (error) {
      return { status: STATUS.ERROR, error };
    }
    if (isLoading) {
      return { status: STATUS.LOADING };
    }
    return { status: STATUS.IDLE };
  });
}
