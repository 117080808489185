import React from "react";
import { RadioInput } from "./radio-input";

interface RadioSelectProps {
  question: string;
  answers: string[];
  id: string;
  selected: string;
  onSelect: (newSelection: string) => void;
}

export function RadioSelect({
  question,
  answers,
  id,
  selected,
  onSelect,
}: RadioSelectProps) {
  return (
    <div style={{ fontSize: 20, marginBottom: 50 }}>
      {question}
      <div style={{ marginTop: 20 }}>
        {answers.map((answer, index) => (
          <RadioInput
            id={`${id}-${index}`}
            className="u-marginRight5gu"
            label={
              <span className="u-text-black" style={{ fontSize: 20 }}>
                {answer}
              </span>
            }
            isChecked={selected === answer}
            name={id}
            onChange={() => {
              onSelect(answer);
            }}
          />
        ))}
      </div>
    </div>
  );
}
