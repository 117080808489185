import React from "react";
import { Tract } from "../../../../server/src/contracts/tract";
import { getTractBackground, getTractCoverImage } from "../get-tract-image-url";

export function TractThumbnail({
  title,
  id,
  backgroundColorStart,
  backgroundColorEnd,
  children,
  className = "",
}: Tract & { children?: React.ReactNode; className?: string }) {
  return (
    <div
      className={`rounded u-flex u-flexAlignItemsCenter u-flexJustifyCenter u-marginVert3gu ${className}`}
      style={{
        backgroundImage: getTractBackground({
          backgroundColorStart,
          backgroundColorEnd,
        }),
        width: 66,
        height: 66,
        marginRight: 30,
      }}
    >
      <img
        src={getTractCoverImage(id)}
        alt={title}
        style={{ height: "77%", transform: "translateY(9px) scale(1.6)" }}
      />
      {children}
    </div>
  );
}
