import React from "react";
import { Link } from "react-router-dom";
import { PATH } from "../../path";

export function MailTheGospelPrompt() {
  return (
    <>
      <p>You can mail the gospel to someone you love!</p>
      <Link to={PATH.HOME}>
        <button className="button button--primary">Get Started</button>
      </Link>
    </>
  );
}
