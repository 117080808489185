import React from "react";
import { useNavigate } from "react-router-dom";
import { PrevStepNav } from "./prev-step-nav";
import { Ribbon, RibbonProps } from "./ribbon";

export function FullPage({
  children,
  offSet = 155,
}: {
  children: React.ReactNode | React.ReactNode[];
  offSet?: number;
}) {
  return (
    <div
      className="u-flex u-flexCol u-flexAlignItemsCenter u-textCenter"
      style={{
        margin: "auto",
        overflow: "hidden",
        minHeight: `calc(100vh - ${offSet}px)`,
      }}
    >
      {children}
    </div>
  );
}

export function withFullPageRibbon<Props>(
  Component: React.ComponentType<Props>,
  path: RibbonProps["path"]
): React.ComponentType<Props> {
  const ComponentWithFullPage: React.ComponentType<Props> = (props) => {
    const navigate = useNavigate();
    const goBack = React.useCallback(() => {
      navigate(-1);
    }, [navigate]);
    return (
      <FullPage offSet={56}>
        <PrevStepNav navClassName="u-sizeFull" isDark={false} onPrev={goBack} />
        <Ribbon path={path} />
        <Component {...props} />
      </FullPage>
    );
  };

  return ComponentWithFullPage;
}
