////////////////////////////////////////////////////////////////////////////////
///////// CAREFUL TO MAKE SURE BACK END MATCHES ////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
export const MAX_LENGTH = {
  GiftMessage: 1000,
  CustomField1: 100,
  CustomerName: 100,
  CustomerCode: 50,
  Email: 100,
  Address1: 200,
  Address2: 200,
  City: 100,
  State: 100,
  PostalCode: 50,
  ItemName: 200,
};
