import React from "react";

interface WindowSize {
  width: number;
  height: number;
}

function getWindowSize(): WindowSize {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function useWindowSize(): WindowSize {
  const [windowSize, setWindowSize] = React.useState(getWindowSize);

  React.useEffect(() => {
    function updateWindowSize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", updateWindowSize);

    return () => {
      window.removeEventListener("resize", updateWindowSize);
    };
  }, [setWindowSize]);

  return windowSize;
}

const SMALL_WIDTH = 800;
const MAX_MODAL_WIDTH = 550;
const MODAL_SPACE_AROUND = 45;

function getModalSize(windowSize: WindowSize, isSmall: boolean): number {
  const maxWidth = isSmall
    ? windowSize.width - MODAL_SPACE_AROUND
    : MAX_MODAL_WIDTH;

  const maxHeight = windowSize.height - MODAL_SPACE_AROUND;

  return Math.min(maxHeight, maxWidth);
}

export function useModalSizeHook(): { size: number; isSmall: boolean } {
  const windowSize = useWindowSize();
  const isSmall = windowSize.width < SMALL_WIDTH;

  return {
    size: getModalSize(windowSize, isSmall),
    isSmall,
  };
}
