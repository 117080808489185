import React from "react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  CreatePaymentIntentResponse,
  OrderInformation,
} from "../../../../../server/src/contracts/payment-intent";
import { ADMIN_PANEL_PATH, API, PATH } from "../../../path";
import { STATUS } from "../../../utils/use-fetch-hook";
import { useFormUploadHook } from "../manage/use-form-upload-hook";
import { stripePromise } from "../../../utils/stripe";
import { Products } from "../../home/interfaces";
import { TotalSummary } from "../../home/total-summary";

interface Props {
  orderInformation: OrderInformation;
  products: Products;
}

interface ConfirmPaymentProps extends Props {
  paymentIntent: CreatePaymentIntentResponse;
}

function ConfirmPayment({
  orderInformation,
  paymentIntent,
  products,
}: ConfirmPaymentProps) {
  const [isPaymentValid, setIsPaymentValid] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const elements = useElements();
  const stripe = useStripe();

  const handlePayment = React.useCallback(async () => {
    if (!stripe || !elements || !isPaymentValid) {
      return;
    }
    try {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}${PATH.ADMIN_PANEL_ROOT}/${ADMIN_PANEL_PATH.ORDERS}?transactionId=${paymentIntent.transactionId}`,
        },
      });

      if (error.type === "card_error" || error.type === "validation_error") {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("An unexpected error occurred.");
      }
    } catch (e) {
      setErrorMessage("Unable to submit payment!");
    }
  }, [elements, isPaymentValid, stripe, paymentIntent]);

  return (
    <>
      <TotalSummary
        hasTaxRate
        hasCondensedShipping
        taxRate={paymentIntent.taxRates[orderInformation.billingAddress.state]}
        orderInformation={orderInformation}
        products={products}
      />

      {errorMessage && <b className="u-text-error">{errorMessage}</b>}
      <PaymentElement
        onChange={(e) => {
          setIsPaymentValid(e.complete);
        }}
        options={{ wallets: { applePay: "never", googlePay: "never" } }}
      />
      <button
        disabled={!elements || !isPaymentValid || !stripe}
        className="button button--primary button--large u-marginTop3gu"
        onClick={handlePayment}
      >
        Mail the Gospel
      </button>
    </>
  );
}

export function Cart({ orderInformation, products }: Props) {
  const [initialOrderInformation] = React.useState(orderInformation);
  const [paymentIntentState, createOrder] = useFormUploadHook<
    { orderInformation: OrderInformation },
    CreatePaymentIntentResponse
  >(API.PAYMENT_INTENT_BULK);

  React.useEffect(() => {
    createOrder({ orderInformation: initialOrderInformation });
  }, [createOrder, initialOrderInformation]);

  return (
    <>
      <h3>Review Cart</h3>
      {paymentIntentState.status === STATUS.DONE ? (
        <Elements
          stripe={stripePromise}
          options={{ clientSecret: paymentIntentState.payload.clientSecret }}
        >
          <ConfirmPayment
            paymentIntent={paymentIntentState.payload}
            orderInformation={initialOrderInformation}
            products={products}
          />
        </Elements>
      ) : paymentIntentState.status === STATUS.ERROR ? (
        <h4>Error creating order</h4>
      ) : (
        <h4>Loading</h4>
      )}
    </>
  );
}
