import React from "react";
import { Address } from "../../../../server/src/contracts/address";
import {
  ShippingRequestPayload,
  ShippingRate,
} from "../../../../server/src/contracts/shipping";
import { API } from "../../path";
import { isAddressValid } from "../../utils/address";
import { State, useLazyFetchHook } from "../../utils/use-fetch-hook";

function useAddressForShippingRatesHook(originalAddress: Address): Address {
  const [address, setAddress] = React.useState({ ...originalAddress });

  React.useEffect(() => {
    // ignore changes to address1 and address2
    if (
      address.city !== originalAddress.city ||
      address.country !== originalAddress.country ||
      address.state !== originalAddress.state ||
      address.zip !== originalAddress.zip
    ) {
      setAddress({ ...originalAddress });
    }
  }, [address, originalAddress, setAddress]);

  return address;
}

export function useGetShippingRatesHook(
  originalAddress: Address,
  tractCount: number,
  extraOunces: number,
  onChange: () => void
): State<ShippingRequestPayload, ShippingRate[]> {
  const [shippingRatesState, getShippingRates] = useLazyFetchHook<
    ShippingRequestPayload,
    ShippingRate[]
  >();
  const address = useAddressForShippingRatesHook(originalAddress);
  const hasValidAddress = isAddressValid(originalAddress);
  React.useEffect(() => {
    onChange();
    if (hasValidAddress) {
      getShippingRates({
        method: "post",
        url: API.SHIPPING_RATES,
        body: {
          tractCount,
          address,
          extraOunces,
        },
      });
    }
  }, [
    tractCount,
    getShippingRates,
    address,
    hasValidAddress,
    extraOunces,
    onChange,
  ]);

  return shippingRatesState;
}
