import React from "react";
import "./file-input.css";

export enum FILE_TYPE {
  PNG = "image/png",
  JPG = "image/jpeg",
  CSV = "text/csv",
}

interface FileInputProps {
  id: string;
  label: string;
  className?: string;
  value: File | undefined;
  accept: FILE_TYPE | FILE_TYPE[];
  onChange: (newValue: File) => void;
}

export function FileInput({
  id,
  label,
  className,
  accept: fileTypes,
  onChange,
}: FileInputProps): React.ReactElement {
  const accept =
    typeof fileTypes === "string" ? fileTypes : fileTypes.join(",");
  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        const file = e.target.files ? e.target.files[0] : null;
        if (file) {
          onChange(file);
        }
      },
      [onChange]
    );

  return (
    <fieldset className={`file-input u-flex ${className}`}>
      <label htmlFor={id}>{label}</label>
      <input
        type="file"
        accept={accept}
        name={id}
        id={id}
        onChange={handleFileChange}
      />
    </fieldset>
  );
}
