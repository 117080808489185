import React from "react";
import { Link } from "react-router-dom";
import { pageLinks, PATH } from "../../path";
import { fullMessages } from "./full-messages";
import "./tract-details.css";
import { KNOWN_TITLES } from "./known-titles";
import { Tract } from "../../../../server/src/contracts/tract";

const showTractRef: React.LegacyRef<HTMLDivElement> | undefined = (
  node: HTMLDivElement | null
): void => {
  if (node) {
    node.scrollIntoView({ behavior: "smooth" });
  }
};

interface TractDetailsProps {
  tract: Tract;
  showTract: boolean;
  toggleShowTract: () => void;
  selectButton?: React.ReactNode;
  continueButton?: React.ReactNode;
  headerControls?: React.ReactNode;
}

export function TractDetails({
  tract,
  showTract,
  toggleShowTract,
  selectButton,
  continueButton,
  headerControls,
}: TractDetailsProps) {
  const fullMessage = fullMessages[tract.subTitle as KNOWN_TITLES];

  return (
    <>
      {headerControls ? (
        headerControls
      ) : (
        <div className="u-flex u-sm-flexCol u-flexAlignItemsCenter">
          <span className="u-flex u-flexAlignItemsCenter">
            <p className="tract-details__price">{tract.displayPrice}</p>
            <p className="tract-details__shipping">(Plus Shipping)</p>
          </span>
          {selectButton}
        </div>
      )}
      <div className="u-flex u-flexWrap u-flexJustifyBetween u-textLeft tract-info">
        <div className="u-flex u-flexCol tract-info__section tract-info__section--left">
          <div className="tract-info__section-title">WHO IS THIS FOR?</div>
          {pageLinks[PATH.ABOUT].theologicalAdvisors.isVisible && (
            <div className="tract-info__section-details tract-info__section-details--about">
              {tract.whoIsThisFor} Each of our tracts has been fully reviewed by
              our{" "}
              <Link to={pageLinks[PATH.ABOUT].theologicalAdvisors.url}>
                Theological Advisory Board
              </Link>
              .
            </div>
          )}
          <div className="tract-info__section-title u-marginTop6gu">
            TRACT QUALITY
          </div>
          <div className="tract-info__section-details tract-info__section-details--about">
            Our tracts are small, perfect bound, micro-books printed on choice
            paper and a sturdy cotton-woven cover. Recipients will appreciate
            the premium feel and pleasant reading experience.
          </div>
          {Boolean(fullMessage) && (
            <button
              onClick={toggleShowTract}
              className="button button--primary button--small tract-info__read-tract u-flexAlignSelfStart"
            >
              Read the Tract
            </button>
          )}
        </div>
        <div className="u-flex u-flexCol tract-info__section tract-info__section--right">
          <div className="tract-info__section-title">PRODUCT DETAILS</div>
          <div className="tract-info__section-details tract-info__section-details--product-details u-flex u-flex u-flexWrap">
            <span className="u-size1of2">Target Audience</span>
            <span className="u-size1of2">{tract.targetAudience}</span>
            <span className="u-size1of2">Read Time: </span>
            <span className="u-size1of2">{tract.estimatedReading}</span>
            <span className="u-size1of2">Product Dimensions: </span>
            <span className="u-size1of2">{tract.productDimensions}</span>
            <span className="u-size1of2">Binding Type: </span>
            <span className="u-size1of2">{tract.bindingType}</span>
            <span className="u-size1of2">Pages: </span>
            <span className="u-size1of2">{tract.pages}</span>
            <span className="u-size1of2">Written By: </span>
            <span className="u-size1of2">{tract.author}</span>
          </div>
        </div>
      </div>
      {showTract && Boolean(fullMessage) && (
        <>
          <div
            ref={showTractRef}
            className="full-message__title u-text-offWhite u-textLeft u-flex u-sizeFull u-flexJustifyBetween u-flexAlignItemsCenter"
          >
            <div>
              {tract.title}: {tract.subTitle}
            </div>
            <div className="u-flex u-flexExpandLeft u-paddingLeft2gu">
              {continueButton}
              <button
                onClick={toggleShowTract}
                className="button--close button--close-large"
              />
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="full-message u-text-white u-textLeft">
              {fullMessage}
            </div>
          </div>
        </>
      )}
    </>
  );
}
