import React from "react";
import { CSS_VARS } from "../common/css-variables";
import { LightHeader } from "../common/light-header";
import { NavBar } from "../common/nav-bar";
import { PageProps } from "../interfaces";
import { socialMediaLinks } from "../utils/social-media";

const churchFinders = [
  {
    name: "The Masters Seminary Finder",
    url: "https://tms.edu/find-a-church/",
    imageSrc: "/assets/masters-seminary-finder.svg",
    imageStyle: { height: 100 },
  },
  {
    name: "9Marks.org Finder",
    url: "https://www.9marks.org/church-search/",
    imageSrc: "/assets/nine-marks-finder.jpg",
    imageStyle: { width: 100, height: 100 },
  },
  {
    name: "Founders.org Finder",
    url: "https://church.founders.org/",
    imageSrc: "/assets/founders-finder.svg",
    imageStyle: { height: 100 },
  },
];

const faq: Array<{ question: string; answer: string | JSX.Element }> = [
  {
    question: "Q: How can I know that I'm truly saved?",
    answer: (
      <React.Fragment>
        <p>
          This question revolves around what the Bible calls
          &quot;assurance.&quot; How can a person have assurance of their
          salvation? The book of 1 John confronts this issue directly. At the
          end of the book (1 John 5:13), the Apostle tells us the purpose of his
          epistle, &quot;I write these things to you who believe in the name of
          the Son of God so that you may know that you have eternal life.&quot;
          Practically speaking, here are 10 evidences of people who are saved.
          <ol>
            <li>You are aware of your great sins against a holy God.</li>
            <li>You have repented of your sin.</li>
            <li>
              You do not trust in your own works, obedience, or righteousness to
              justify you in front of God; you trust (or have faith) only in the
              work, obedience, and righteousness of Christ to justify you in
              front of God.
            </li>
            <li>
              You have submitted to the Lordship of Jesus Christ. He is your
              King, Ruler, and Master.
            </li>
            <li>
              Your life is marked by the fruits of the Spirit (Gal. 5:22-23).
            </li>
            <li> You have a growing hatred for sin.</li>
            <li> You have a love for the local church.</li>
            <li> You're passionate about telling others about Jesus.</li>
            <li>You have a growing opposition to the way of the world.</li>
            <li>You see your prayers being answered.</li>
          </ol>
        </p>
      </React.Fragment>
    ),
  },
  {
    question: "Q: When and why should I get baptized?",
    answer:
      "In Scripture, baptism is a sacred act by which a Christian publicly identifies themselves with Christ in His burial, death, and resurrection. They do this symbolically by immersion in water. Not only are Christians called to baptism by Jesus in the command of the Great Commission (Matt. 28:18-20), but they are also called to follow the example of Christ's baptism in Matthew 3:13-17. That said, Christians are not saved by baptism; saved Christians simply pursue baptism. As for timing, the Bible presents baptism as occurring immediately after someone believes (Acts 2:38, 41; 8:12, 38). Namely, do not wait or postpone baptism. Speak to your local pastor to arrange a time for him to baptize you.",
  },
  {
    question: "Q: Do I really need to go to church every Sunday?",
    answer:
      "When a person is born-again, they are born into God's family. The church is God's family. The local church is your nearest expression of this family, and you should long to be with those who also love Christ. In addition, a local biblical church is the central place for you to mature in your understanding of the Scriptures. For that reason, we suggest using our local church locator above to find a faithful and theologically-sound community of Christians.",
  },
  {
    question: "Q: Where should I start reading in the Bible?",
    answer:
      "The Bible is not just a book; it's a collection of 66 books that radiate God's ultimate plan of redemption from Genesis to Revelation. The Old Testament prepares us and points us to Christ, the Gospels record the ministry of Christ, the epistles explain Christ, and Revelation predicts the future return of Christ. That said, the five most effective books for new believers are John, Ephesians, Romans, Genesis, and Proverbs. However, you should also make an effort to read the entire Bible within a year.",
  },
  {
    question: "Q: As a Christian, what will happen after I die?",
    answer:
      "In 2 Corinthians 5:8, Paul tells us that to be away from the body is to be present with the Lord. Namely, physical death is the separation of the body and the spirit. The body returns to the ground, and the spirit goes to the Lord in Heaven (Ecc. 12:70). However, Scripture promises that our spirits will be reunited with our new, resurrected bodies at the return of Christ. At that time, we will dwell on the new, restored earth forever with Christ and His people. (1 Cor. 15:50-54; 1 Thess. 4:13-17; Rev. 21-22).",
  },
  {
    question:
      "Q: What podcasts, books, or pastors can help me grow in my faith?",
    answer: (
      <p>
        There are several trusted pastors and theologians whose teachings are
        worth consuming. Suggestions include Charles Spurgeon, Dr. Martyn Llyod
        Jones, R.C. Sproul, John MacArthur, Paul Washer, Voddie Baucham, and
        Doug Wilson. In addition, our Founder, Dale Partridge, has a large
        listenership on his podcast “Real Christianity” and offers several
        resources (books, videos, and PDFs) on our parent ministry at{" "}
        <a href="http://relearn.org">Relearn.org</a>
      </p>
    ),
  },
];

const sectionWidth = 600;

export function Faq({ setTheme }: PageProps) {
  React.useEffect(() => {
    setTheme(CSS_VARS["--off-white"], false, "", false);
  }, [setTheme]);

  return (
    <div className="u-light">
      <NavBar />
      <div
        style={{
          paddingBottom: 80,
          maxWidth: sectionWidth,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <LightHeader
          title="Thank you :)"
          subTitle='If you selected "yes" to receive a Bible or to have a pastor contact you, someone will be in touch soon.'
        />
        <section className="rounded-section u-textCenter u-marginHoriz0">
          <h4 style={{ fontSize: 30, margin: 0 }}>Find a Local Church</h4>
          <p style={{ fontSize: 20 }}>
            Below are three trusted church locator services
          </p>
          <div className="u-flex">
            {churchFinders.map(({ url, imageSrc, imageStyle, name }) => (
              <a
                key={url}
                className="u-flex u-flexCol u-flexAlignItemsCenter u-flexJustifyBetween u-size1of3"
                href={url}
                style={{ textDecoration: "none", padding: 25 }}
                target="_blank"
                rel="noreferrer"
              >
                <img src={imageSrc} style={{ ...imageStyle }} alt={name} />
                <span className="u-marginTop3gu">{name}</span>
              </a>
            ))}
          </div>
        </section>
        <section className="rounded-section u-marginTop6gu u-marginHoriz0">
          <h4 style={{ fontSize: 30, margin: 0 }}>Common Questions from</h4>
          <h4 style={{ fontSize: 30, margin: 0 }}>New Christians</h4>
          <div className="u-flex u-flexCol">
            {faq.map(({ question, answer }) => (
              <div
                key={question}
                style={{ borderRadius: 15, padding: "0 30px 30px" }}
                className="u-border-cream u-marginTop5gu"
              >
                <h4>{question}</h4>
                {typeof answer === "string" ? <p>{answer}</p> : answer}
              </div>
            ))}
            <p>
              Suggested books: The Holiness of God by R.C. Sproul, Chosen by God
              by R.C. Sproul, What is the Gospel? by Greg Gilbert, The Cross of
              Christ by John Stott, Essential Christian Doctrine by John
              MacArthur.
            </p>
            <p>
              Suggested documentaries: American Gospel: In Christ Alone,
              American Gospel: Christ Crucified, Spirit and Truth, Calvinist,
              Eve in Exile (for women), The God Who Speaks, and Luther by
              Ligonier.
            </p>
          </div>
        </section>
        <div className="u-textCenter u-flex u-flexCol u-flexAlignItemsCenter u-padding4gu">
          <h4 style={{ fontSize: 30, margin: "60px 0 0 0" }}>
            Follow us on Social Media
          </h4>
          <p>
            Mail The Gospel is a Ministry of{" "}
            <a href="https://relearn.org">Relearn.org</a>
          </p>
          <div className="u-flex" style={{ maxWidth: 500 }}>
            {socialMediaLinks.map(({ src, href, alt }) => (
              <a
                key={src}
                href={href}
                className={`u-size1of${socialMediaLinks.length} u-margin3gu`}
                target="_blank"
                rel="noreferrer"
              >
                <img src={src} alt={alt} className="u-sizeFull" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
