export enum PATH {
  HOME = "/home",
  PACK = "/pack",
  ORDER_REVIEW = "/order-review",
  CHECKOUT = "/checkout",
  ORDER_CONFIRMED = "/order-confirmed",
  DONATION_CONFIRMED = "/donation-confirmed",
  ADMIN_PANEL_ROOT = "/admin-panel",
  ABOUT = "/about",
  GIVE = "/give",
  SUPPORT = "/support",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  WELCOME = "/welcome",
  FAQ = "/faq",
}

export const pageLinks = {
  [PATH.ABOUT]: {
    theologicalAdvisors: {
      isVisible: false,
      id: "theological-advisors",
      get url() {
        return `${PATH.ABOUT}#${this.id}`;
      },
    },
  },
};

export enum ADMIN_PANEL_PATH {
  ORDERS = "orders",
  BULK_GOSPEL = "bulk-gospel",
  MANAGE = "manage",
  STAMPS = "stamps",
  EVANGELISTS = "evangelists",
  DONATIONS = "donations",
  CUSTOM_LITERATURE = "custom-literature",
}

export enum API {
  SIGN_IN = "/api/auth/login",
  SIGN_OUT = "/api/auth/logout",
  PRODUCTS = "/api/products",
  TRACT = "/api/tract",
  TRACT_COVER_IMAGE = "/api/tract/cover",
  ADD_ON = "/api/add-on",
  TRANSACTION = "/api/transaction",
  TRANSACTION_MARK_PAID = "/api/transaction/mark-paid",
  MODIFY_ORDER = "/api/transaction/order/modify",
  PAYMENT_INTENT = "/api/payment/payment-intent",
  PAYMENT_INTENT_BULK = "/api/payment/payment-intent/bulk",
  DONATE = "/api/donate",
  DONATION_MARK_PAID = "/api/donate/mark-paid",
  STAMPS_STATUS = "/api/stamps/status",
  SHIPPING_RATES = "/api/shipping-rates",
  SHIPPING_RATES_BULK = "/api/shipping-rates/bulk",
  WELCOME_FORM = "/api/welcome-form",
  SUPPORT_FORM = "/api/support-form",
  CUSTOM_LITERATURE = "/api/custom-literature",
  EVANGELIST = "/api/evangelist",
}
