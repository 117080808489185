import { Link } from "react-router-dom";
import { EvangelistDTO } from "../../../../server/src/contracts/evangelist";
import { ADMIN_PANEL_PATH, PATH } from "../../path";

export function EvangelistLink<
  EvangelistLike extends { id: string } & Partial<EvangelistDTO>,
>(props: EvangelistLike) {
  return (
    <Link
      to={`${PATH.ADMIN_PANEL_ROOT}/${ADMIN_PANEL_PATH.EVANGELISTS}/${props.id}`}
    >
      {props.displayFullName || props.id}
    </Link>
  );
}
