const computedStyle = window.getComputedStyle(document.documentElement);

export const CSS_VARS = {
  "--black": computedStyle.getPropertyValue("--black"),
  "--cream": computedStyle.getPropertyValue("--cream"),
  "--cream-mid": computedStyle.getPropertyValue("--cream-mid"),
  "--dark-green": computedStyle.getPropertyValue("--dark-green"),
  "--green": computedStyle.getPropertyValue("--green"),
  "--light-green": computedStyle.getPropertyValue("--light-green"),
  "--light-gray": computedStyle.getPropertyValue("--light-gray"),
  "--off-white": computedStyle.getPropertyValue("--off-white"),
  "--white": computedStyle.getPropertyValue("--white"),
  "--unit": computedStyle.getPropertyValue("--unit"),
  "--sm-padding-horiz": computedStyle.getPropertyValue("--sm-padding-horiz"),
};
