import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "../../modal";
import { STEP } from "../../pages/home/interfaces";
import { PATH } from "../../path";
import "./nav-bar.css";

function scrollToTop() {
  window.scrollTo(0, 0);
}

const topLevelPages = [
  { path: `${PATH.HOME}/${STEP.WHO_IS_MAILING}`, label: "Get Started" },
  { path: PATH.PACK, label: "Tract Packs" },
  { path: PATH.ABOUT, label: "About" },
  { path: PATH.GIVE, label: "Give" },
  { path: PATH.SUPPORT, label: "Support" },
];

interface NavBarProps {
  hasWhiteText?: boolean;
  customLinks?: React.ReactNode;
}

export function NavBar({
  hasWhiteText = false,
  customLinks,
}: NavBarProps): React.ReactElement {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const toggleMenuOpen = React.useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, [setIsMenuOpen]);

  React.useEffect(() => {
    if (isMenuOpen) {
      scrollToTop();
      window.addEventListener("scroll", scrollToTop);
      return () => {
        window.removeEventListener("scroll", scrollToTop);
      };
    }
  }, [isMenuOpen]);

  return (
    <>
      <nav
        className={`u-flex u-flexAlignItemsCenter mtg-header${
          isMenuOpen ? " mtg-header--open" : ""
        }${hasWhiteText ? " mtg-header--light" : ""}`}
      >
        <Link to={PATH.HOME} className="u-flexExpandRight">
          <img src="/assets/envelope-heart.webp" alt="Mailbox" height="30" />
        </Link>
        {topLevelPages.map((page) => (
          <Link
            key={page.path}
            className={`u-sm-hidden ${
              hasWhiteText ? " u-text-offWhite" : "u-text-black"
            }`}
            to={page.path}
          >
            {page.label}
          </Link>
        ))}
        <button
          onClick={toggleMenuOpen}
          className="hamburger u-md-hidden u-lg-hidden"
        />
        {customLinks}
      </nav>
      {isMenuOpen && (
        <Modal>
          <div className="modal-menu u-flex u-flexCol u-flexAlignItemsStretch u-textCenter">
            {topLevelPages.map((page, index, total) => (
              <React.Fragment key={page.path}>
                <Link key={page.path} to={page.path}>
                  {page.label}
                </Link>
                {index !== total.length - 1 && <div className="line" />}
              </React.Fragment>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
}
