import React from "react";

export interface TableHeader<T> {
  key: keyof T;
  displayName: string;
  Component?: React.ComponentType<{ data: T; dataKey: keyof T }>;
  HeaderComponent?: React.ComponentType<Record<string, never>>;
}

interface TableProps<RowType> {
  rows: RowType[];
  headers: TableHeader<RowType>[];
  /** used to set unique `key` when mapping through @param rows */
  idProp: keyof RowType;
}

export function Table<RowType>({
  rows,
  headers,
  idProp,
}: TableProps<RowType>): React.ReactElement {
  return (
    <table>
      <thead>
        <tr>
          {headers.map(({ key, displayName, HeaderComponent }) => (
            <th key={String(key)}>
              {HeaderComponent ? <HeaderComponent /> : displayName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={String(row[idProp])}>
            {headers.map(({ key, Component }) => (
              <td key={String(key)}>
                {Component ? <Component data={row} dataKey={key} /> : row[key]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
