import React from "react";
import { RecipientPayload } from "../../../../../server/src/contracts/recipient";
import {
  BulkShippingRequestPayload,
  ShippingRate,
} from "../../../../../server/src/contracts/shipping";
import { API } from "../../../path";
import { STATUS, useFetchHook } from "../../../utils/use-fetch-hook";

interface CalculateShippingProps {
  recipients: RecipientPayload[];
  onDone: (newShippingRate: ShippingRate) => void;
}

/**
 * gets a recipient for each unique zip code
 */
function getSampleRecipients(
  recipients: RecipientPayload[]
): RecipientPayload[] {
  return Object.values(
    recipients.reduce((zips, recipient) => {
      if (!zips[recipient.zip]) {
        zips[recipient.zip] = recipient;
      }
      return zips;
    }, {} as Record<string, RecipientPayload>)
  );
}

export function CalculateShipping({
  recipients,
  onDone,
}: CalculateShippingProps) {
  const [sampleRecipients] = React.useState(() =>
    getSampleRecipients(recipients)
  );

  const shippingRatesState = useFetchHook<
    BulkShippingRequestPayload,
    ShippingRate
  >({
    method: "post",
    url: API.SHIPPING_RATES_BULK,
    body: {
      addresses: sampleRecipients,
      extraOunces: 0,
    },
  });

  const handleNext = React.useCallback(() => {
    if (shippingRatesState.status === STATUS.DONE) {
      onDone(shippingRatesState.payload);
    }
  }, [onDone, shippingRatesState]);

  return (
    <>
      <h4>Calculate Shipping</h4>
      <p>Calculating shipping rates for each unique zip code in list.</p>
      <ul>
        {sampleRecipients.map((recipient) => (
          <li key={recipient.id}>{recipient.zip}</li>
        ))}
      </ul>
      {shippingRatesState.status === STATUS.DONE ? (
        <div className="u-flex u-flexCol u-flexAlignItemsCenter">
          <p>For each tract the shipping will cost:</p>
          <h3 className="u-price">
            {shippingRatesState.payload.displayShipmentCost}
          </h3>
        </div>
      ) : shippingRatesState.status === STATUS.ERROR ? (
        <p>Unable to load shipping rate!</p>
      ) : (
        <p>Loading...</p>
      )}
      <button
        disabled={shippingRatesState.status !== STATUS.DONE}
        className="button button--primary button--large u-marginTop3gu u-flexAlignSelfEnd"
        onClick={handleNext}
      >
        Next
      </button>
    </>
  );
}
