import {
  CreatePaymentIntentResponse,
  OrderInformation,
} from "../../server/src/contracts/payment-intent";
import { RecipientPayload } from "../../server/src/contracts/recipient";

export enum STORAGE_KEY {
  ORDER_INFORMATION = "ORDER_INFORMATION",
  PAYMENT_INTENT = "PAYMENT_INTENT",
  DONATION_INTENT = "DONATION_INTENT",
}

export interface RecipientUpdate {
  recipientId: string;
  tractId: string;
  partialRecipient: Partial<RecipientPayload>;
}

export interface PageProps {
  setTheme: (
    newColor: string,
    isDark: boolean,
    footerColor?: string,
    hasFooter?: boolean
  ) => void;
  isDark: boolean;
  orderInformation: OrderInformation;
  setOrderInformation: React.Dispatch<React.SetStateAction<OrderInformation>>;
  onRecipientUpdate: (recipientUpdate: RecipientUpdate) => void;
  paymentIntent: CreatePaymentIntentResponse | null;
  setPaymentIntent: React.Dispatch<
    React.SetStateAction<CreatePaymentIntentResponse | null>
  >;
}
