import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ADMIN_PANEL_PATH, API, PATH } from "../../path";
import { useUserState } from "../../user.context";
import { useLazyFetchHook } from "../../utils/use-fetch-hook";
import "./admin-nav.css";

function AdminLink({
  to,
  children,
}: {
  to: ADMIN_PANEL_PATH;
  children: string;
}) {
  const isActive = useLocation().pathname.startsWith(
    `${PATH.ADMIN_PANEL_ROOT}/${to}`,
  );
  return (
    <Link className={isActive ? "active" : "inactive"} to={to}>
      {children}
    </Link>
  );
}

export function AdminNav({ hasNewFeatures }: { hasNewFeatures: boolean }) {
  const userState = useUserState();
  const [, signOut] = useLazyFetchHook<null, null>();

  return (
    <nav className="admin-nav">
      <h4>Admin Panel</h4>
      <AdminLink to={ADMIN_PANEL_PATH.ORDERS}>Orders</AdminLink>
      {hasNewFeatures && (
        <>
          <AdminLink to={ADMIN_PANEL_PATH.EVANGELISTS}>Evangelists</AdminLink>
          <AdminLink to={ADMIN_PANEL_PATH.DONATIONS}>Donations</AdminLink>
        </>
      )}
      <AdminLink to={ADMIN_PANEL_PATH.BULK_GOSPEL}>Bulk Gospel</AdminLink>
      <AdminLink to={ADMIN_PANEL_PATH.CUSTOM_LITERATURE}>
        Custom Literature
      </AdminLink>
      <AdminLink to={ADMIN_PANEL_PATH.MANAGE}>Manage</AdminLink>
      <AdminLink to={ADMIN_PANEL_PATH.STAMPS}>Stamps</AdminLink>
      <button
        className="button button--primary"
        onClick={() => {
          signOut({
            method: "post",
            url: API.SIGN_OUT,
          });
          userState.update(null);
        }}
      >
        Logout
      </button>
    </nav>
  );
}
