import React from "react";

export function SummaryTitle({
  children,
  fontSize = 22,
}: {
  children: string;
  fontSize?: number;
}) {
  return <h4 style={{ fontSize, margin: 0 }}>{children}</h4>;
}
