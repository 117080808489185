import React from "react";
import { getAddOnImageSrc } from "../../common/get-add-on-image-src";
import { RecipientUpdate } from "../../interfaces";
import { AddOn } from "../home/interfaces";

interface AddOnPreviewProps {
  recipientId: string;
  addOn: AddOn;
  tractId: string;
  onRecipientUpdate: (recipientUpdate: RecipientUpdate) => void;
}

export function AddOnPreview({
  addOn,
  recipientId,
  tractId,
  onRecipientUpdate,
}: AddOnPreviewProps) {
  return (
    <div className="u-flex u-flexAlignItemsCenter u-marginVert3gu">
      <div
        className="rounded u-bg-creamMid u-border-cream u-flex u-flexAlignItemsCenter u-flexJustifyContent removable"
        style={{ width: 66, height: 66, marginRight: 30 }}
      >
        <img
          style={{ marginLeft: -9, height: "90%" }}
          src={getAddOnImageSrc(addOn.id)}
          alt={addOn.title}
        />
        <button
          onClick={() => {
            onRecipientUpdate({
              recipientId,
              tractId,
              partialRecipient: { addOnId: null },
            });
          }}
          className="removable__button"
        />
      </div>
      <p>{addOn.title}</p>
      <p className="u-price u-flexExpandLeft">{addOn.displayPrice}</p>
    </div>
  );
}
