import { OrderInformation } from "../../../server/src/contracts/payment-intent";
import { RecipientPayload } from "../../../server/src/contracts/recipient";
import { isAddressValid } from "../utils/address";
import { isStringAnEmail } from "../utils/email";

export function hasValidCustomerInfo({
  fullName,
  email,
}: OrderInformation): boolean {
  return Boolean(fullName && email && isStringAnEmail(email));
}

export function hasSelectedTract(orderInformation: OrderInformation): boolean {
  return (
    orderInformation.initialTractId !== null &&
    orderInformation.tracts.length > 0
  );
}

function isRecipientValid({
  lastName,
  firstName,
  ...address
}: RecipientPayload): boolean {
  return Boolean(lastName && firstName && isAddressValid(address));
}

export function hasAllRecipientsFilledOut(
  orderInformation: OrderInformation
): boolean {
  return orderInformation.tracts.every((tractOrder) =>
    tractOrder.recipients.every(isRecipientValid)
  );
}

export function isReadyForCheckout(
  orderInformation: OrderInformation
): boolean {
  const hasTractOrders = orderInformation.tracts.length !== 0;
  const hasBulkTractOrders = orderInformation.bulkTractOrders.length !== 0;
  const hasAnyOrders = hasTractOrders || hasBulkTractOrders;

  return (
    hasValidCustomerInfo(orderInformation) &&
    hasAllRecipientsFilledOut(orderInformation) &&
    hasAnyOrders &&
    (orderInformation.bulkTractOrders.length === 0 ||
      (isAddressValid(orderInformation.shippingAddress) &&
        Boolean(orderInformation.tractPackShippingRate)))
  );
}
