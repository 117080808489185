import React from "react";
import "./radio-input.css";

interface RadioInputProps {
  id: string;
  className: string;
  name: string;
  label: string | React.ReactElement;
  isChecked: boolean;
  onChange: (newChecked: string) => void;
}

export function RadioInput({
  id,
  className,
  name,
  label,
  isChecked,
  onChange,
}: RadioInputProps): React.ReactElement {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        if (e.target.checked) {
          onChange(e.target.id);
        }
      },
      [onChange]
    );

  return (
    <label htmlFor={id} className={`radio ${className}`}>
      <input
        type="radio"
        name={name}
        id={id}
        checked={isChecked}
        onChange={handleChange}
      />
      {typeof label === "string" ? <span>{label}</span> : label}
      <span className="checkmark"></span>
    </label>
  );
}
