import React from "react";
import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { DonationAmount } from "../../common/donation-amount";
import { SummaryTitle } from "../../common/summary-title";
// import { BillingForm } from "../../common/billing-form";
// import { createAddress, isAddressValid } from "../../utils/address";
import { Donation } from "../../../../server/src/contracts/payment-intent";
// import { Address } from "../../../../server/src/contracts/address";
import { API, PATH } from "../../path";
import { TextInput } from "../../common/text-input";
import { useTextInputHook } from "../../common/use-text-input-hook";
import { isStringAnEmail } from "../../utils/email";
import { STATUS, useLazyFetchHook } from "../../utils/use-fetch-hook";
import {
  UpdateDonationIntentRequest,
  UpdateDonationIntentResponse,
} from "../../../../server/src/contracts/donate";
import { STORAGE_KEY } from "../../interfaces";

const DONATION_IMPACT = 5;
function getDonationImpact(donation: Donation | null): string {
  if (!donation || !donation.amount) {
    return "0";
  }
  return String(Math.floor(donation.amount / DONATION_IMPACT));
}

interface DonateFormProps {
  donationAmounts: number[];
  paymentIntentId: string;
}

export function DonateForm({
  donationAmounts,
  paymentIntentId,
}: DonateFormProps) {
  const [donation, setDonation] = React.useState<Donation | null>(null);
  const [isPaymentValid, setIsPaymentValid] = React.useState(false);
  // const [billing, setBilling] = React.useState(createAddress);
  const [fullName, handleFullNameChange] = useTextInputHook();
  const [phoneNumber, handlePhoneNumberChange] = useTextInputHook();
  const [email, handleEmailChange] = useTextInputHook();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>();

  const isValidEmail = Boolean(email && isStringAnEmail(email));

  const elements = useElements();
  const stripe = useStripe();
  const [paymentIntentUpdate, updatePaymentIntent] = useLazyFetchHook<
    UpdateDonationIntentRequest,
    UpdateDonationIntentResponse
  >();

  const saveDonation = React.useCallback(() => {
    if (fullName && isValidEmail && isPaymentValid && donation) {
      updatePaymentIntent({
        method: "put",
        url: API.DONATE,
        body: {
          donationAmount: donation.amount,
          email,
          fullName,
          paymentIntentId,
          phoneNumber,
        },
      });
    }
  }, [
    donation,
    email,
    fullName,
    phoneNumber,
    isPaymentValid,
    isValidEmail,
    paymentIntentId,
    updatePaymentIntent,
  ]);

  // const handleBillingChange = React.useCallback(
  //   (addressPartial: Partial<Address>): void => {
  //     setBilling((prev) => ({
  //       ...prev,
  //       ...addressPartial,
  //     }));
  //   },
  //   [setBilling]
  // );

  // const isValidAddress = isAddressValid(billing);

  React.useEffect(() => {
    if (
      !stripe ||
      !elements ||
      // !isValidAddress ||
      !isPaymentValid ||
      paymentIntentUpdate.status !== STATUS.DONE
    ) {
      return;
    }

    localStorage.setItem(
      STORAGE_KEY.DONATION_INTENT,
      paymentIntentUpdate.payload.donationId
    );

    async function confirmPayment() {
      if (!stripe || !elements) {
        return;
      }
      try {
        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: window.location.origin + PATH.DONATION_CONFIRMED,
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
          setErrorMessage(error.message);
        } else {
          setErrorMessage("An unexpected error occurred.");
        }
      } catch (e) {
        setErrorMessage("Unable to submit payment!");
      }
    }

    setIsLoading(true);
    try {
      confirmPayment();
    } catch (e) {
      setErrorMessage("Unexpected error in confirming payment");
    } finally {
      setIsLoading(false);
    }
  }, [
    elements,
    isPaymentValid,
    // isValidAddress,
    stripe,
    paymentIntentUpdate,
  ]);

  if (isLoading && !errorMessage) {
    return (
      <h3 className="u-text-offWhite step-header u-text-black">
        Processing Order...
      </h3>
    );
  }

  return (
    <div className="u-flex u-flexCol u-size1of2 u-sm-sizeFull u-bg-gray give__column-two">
      <div className="give__amount">
        <DonationAmount
          hasDarkBg
          amounts={donationAmounts}
          donation={donation}
          onChange={setDonation}
        />
        <div className="u-flex u-flexAlignItemsCenter u-flexAlignSelfCenter">
          <h5>Your impact</h5>
          <h1
            style={{
              margin: "0 10px 0 25px",
              color: "var(--white)",
              fontFamily: "DM Mono",
            }}
          >
            {getDonationImpact(donation)}
          </h1>
          <div>
            People get
            <br />
            the Gospel
          </div>
        </div>
      </div>
      <section className="rounded-section">
        <SummaryTitle fontSize={18}>Payment Details</SummaryTitle>
        <div className="rule rule--extra-thick rule--gray u-marginVert3gu" />
        {errorMessage && <b className="u-text-error">{errorMessage}</b>}
        <PaymentElement
          onChange={(e) => {
            setIsPaymentValid(e.complete);
          }}
          options={{ wallets: { applePay: "never", googlePay: "never" } }}
        />
      </section>
      <section className="rounded-section">
        <SummaryTitle fontSize={18}>Who is Donating?</SummaryTitle>
        <div className="rule rule--extra-thick rule--gray u-marginTop3gu u-marginBottom6gu" />
        <TextInput
          id="full-name"
          placeholder="Full Name"
          className="u-sizeFull u-marginBottom6gu"
          value={fullName}
          onChange={handleFullNameChange}
        />
        <TextInput
          id="email"
          type="email"
          placeholder="Email"
          className="u-sizeFull u-marginBottom6gu"
          value={email}
          onChange={handleEmailChange}
        />
        <TextInput
          id="telephone"
          type="tel"
          placeholder="Phone Number"
          className="u-sizeFull u-marginBottom6gu"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
        />
      </section>
      {/* <section className="rounded-section">
        <SummaryTitle fontSize={18}>Billing Address</SummaryTitle>
        <div className="rule rule--extra-thick rule--gray u-marginTop3gu u-marginBottom6gu" />
        <BillingForm billing={billing} onChange={handleBillingChange} />
      </section> */}
      <button
        disabled={
          !stripe ||
          !elements ||
          !fullName ||
          !isValidEmail ||
          // !isValidAddress ||
          !isPaymentValid ||
          !donation
        }
        onClick={saveDonation}
        className="button button--primary"
        style={{ margin: "5px 20px 20px 20px" }}
      >
        Submit Donation
      </button>
    </div>
  );
}
