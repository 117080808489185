import React from "react";
import { useUserState } from "../user.context";
import { useTextInputHook } from "./use-text-input-hook";

const PREVIEW_AUTH_PASSWORD_KEY = "PREVIEW_AUTH_PASSWORD";

export function hasCorrectPassword(): boolean {
  if (!process.env.REACT_APP_PREVIEW_PASSWORD) {
    return true;
  }
  const storedPassword = localStorage.getItem(PREVIEW_AUTH_PASSWORD_KEY);
  return Boolean(
    storedPassword && storedPassword === process.env.REACT_APP_PREVIEW_PASSWORD
  );
}

function PreviewPassword() {
  const { setIsPreviewAuthenticated } = useUserState();
  const [previewPassword, setPreviewPassword] = useTextInputHook(
    sessionStorage.getItem(PREVIEW_AUTH_PASSWORD_KEY) || ""
  );

  React.useEffect(() => {
    if (previewPassword) {
      localStorage.setItem(PREVIEW_AUTH_PASSWORD_KEY, previewPassword);
    } else {
      localStorage.removeItem(PREVIEW_AUTH_PASSWORD_KEY);
    }
    setIsPreviewAuthenticated(hasCorrectPassword());
  }, [previewPassword, setIsPreviewAuthenticated]);

  return (
    <div
      className="u-flex u-flexCol u-flexJustifyCenter u-flexAlignItemsCenter u-textCenter"
      style={{
        minHeight: "100vh",
        backgroundColor: "black",
      }}
    >
      <h4 className="u-text-offWhite">Password</h4>
      <div>
        <input
          autoFocus
          type="password"
          id="preview-password"
          name="preview-password"
          onChange={setPreviewPassword}
          value={previewPassword}
        />
      </div>
    </div>
  );
}

export function withPreviewAuth<Props>(
  Component: React.ComponentType<Props>
): React.ComponentType<Props> {
  const Wrapped: React.ComponentType<Props> = (props) => {
    const userState = useUserState();
    if (userState.isPreviewAuthenticated) {
      return <Component {...props} />;
    } else {
      return <PreviewPassword />;
    }
  };

  Wrapped.displayName = `withPreviewAuth(${
    Component.displayName || Component.name
  })`;

  return Wrapped;
}
