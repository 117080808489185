import React from "react";
import { Link } from "react-router-dom";
import { PATH } from "../path";

export function PrevStepNav({
  label = "Previous Step",
  buttonClassName,
  navClassName = "",
  onPrev,
  isDark,
}: {
  label?: string;
  buttonClassName?: string;
  navClassName?: string;
  isDark: boolean;
  onPrev: () => void;
}) {
  const buttonClassList = [
    buttonClassName || "button button--tertiary button--previous",
  ];
  if (!isDark) {
    buttonClassList.push("u-text-black");
  }
  const buttonClass = buttonClassList.join(" ");
  return (
    <nav
      className={`u-flex u-flexAlignItemsCenter prev-step-nav ${navClassName}`}
    >
      <Link to={PATH.HOME} className="u-flexExpandRight">
        <img
          src="/assets/envelope-heart.webp"
          style={isDark ? { filter: "invert(100%)" } : {}}
          alt="Mailbox"
          height="30"
        />
      </Link>
      <button className={buttonClass} onClick={onPrev}>
        {label}
      </button>
    </nav>
  );
}
