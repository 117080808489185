import React from "react";
import Modal from "react-modal";
import {
  ModifyOrderRequest,
  ModifyOrderResponse,
  Order,
} from "../../../../../server/src/contracts/orders";
import { API } from "../../../path";
import { useLazyFetchHook } from "../../../utils/use-fetch-hook";
import { Table } from "../table";

interface EditModalProps {
  isOpen: boolean;
  toggleIsOpen: () => void;
  selectedOrders: Record<string, Order>;
}

export function EditModal({
  isOpen,
  toggleIsOpen,
  selectedOrders,
}: EditModalProps) {
  const [, modifyOrder] = useLazyFetchHook<
    ModifyOrderRequest,
    ModifyOrderResponse
  >();

  const canTakeAction = Object.keys(selectedOrders).length === 1;
  const orderId = canTakeAction ? Object.keys(selectedOrders)[0] : null;

  const updateOrderTimeStamp = React.useCallback(() => {
    if (orderId) {
      modifyOrder({
        method: "post",
        url: API.MODIFY_ORDER,
        body: { id: orderId, action: "update-timestamp" },
      });
    }
  }, [orderId, modifyOrder]);

  const markOrderPaid = React.useCallback(() => {
    if (orderId) {
      modifyOrder({
        method: "post",
        url: API.MODIFY_ORDER,
        body: { id: orderId, action: "mark-paid" },
      });
    }
  }, [orderId, modifyOrder]);

  const deleteOrders = React.useCallback(() => {
    if (orderId) {
      modifyOrder({
        method: "post",
        url: API.MODIFY_ORDER,
        body: { id: orderId, action: "delete" },
      });
    }
  }, [orderId, modifyOrder]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggleIsOpen}
      style={{
        content: {
          display: "flex",
          flexDirection: "column",
          // padding: 0,
          border: 0,
          // width,
          // height,
          margin: "auto",
        },
        overlay: {
          background: "rgba(82, 82, 82, 0.75)",
        },
      }}
      contentLabel="Mail the Gospel Introduction"
    >
      <h1 style={{ color: "red" }}>Warning! Tread Carefully!</h1>
      <div className="u-margin3gu">
        <Table
          idProp="number"
          rows={Object.values(selectedOrders)}
          headers={[
            { displayName: "Order #", key: "number" },
            { displayName: "Status", key: "displayStatus" },
            { key: "displayTotal", displayName: "Total" },
          ]}
        />
      </div>
      <div className="u-flex">
        <button onClick={updateOrderTimeStamp} disabled={!canTakeAction}>
          Update Time Stamp
        </button>
        <button onClick={markOrderPaid} disabled={!canTakeAction}>
          Mark Paid
        </button>
        <button onClick={deleteOrders} disabled={!canTakeAction}>
          Delete
        </button>
      </div>
    </Modal>
  );
}
