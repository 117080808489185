import React from "react";
import { Link } from "react-router-dom";
import {
  CreatePaymentIntentResponse,
  OrderInformation,
} from "../../../../server/src/contracts/payment-intent";
import {
  MarkPaidPayload,
  MarkPaidResponse,
} from "../../../../server/src/contracts/orders";
import { LightHeader } from "../../common/light-header";
import { PageProps, STORAGE_KEY } from "../../interfaces";
import { API, PATH } from "../../path";
import { getEmptyOrderInformation } from "../../utils/get-empty-order-information";
import { STATUS, useLazyFetchHook } from "../../utils/use-fetch-hook";

function printNames(orderInformation: OrderInformation): string {
  return orderInformation.tracts.reduce(
    (names, tractOrder, tractOrderIndex) => {
      const isLastTract =
        tractOrderIndex === orderInformation.tracts.length - 1;
      return (
        names +
        tractOrder.recipients.reduce(
          (tractNames, recipient, recipientIndex) => {
            const isLast =
              isLastTract &&
              recipientIndex === tractOrder.recipients.length - 1;
            const isFirst = tractOrderIndex === 0 && recipientIndex === 0;
            if (!isFirst) {
              if (isLast) {
                tractNames += " and ";
              } else {
                tractNames += ", ";
              }
            }
            return tractNames + recipient.firstName;
          },
          ""
        )
      );
    },
    ""
  );
}

function getMessage(orderInformation: OrderInformation): string {
  const hasGospelOrders = orderInformation.tracts.length !== 0;
  const hasTractPacks = orderInformation.bulkTractOrders.length !== 0;

  const names = printNames(orderInformation);

  if (hasGospelOrders && !hasTractPacks) {
    return `The Gospel is on its way to ${names}. Thank you for doing your part!`;
  } else if (!hasGospelOrders && hasTractPacks) {
    return "Your tract packs are on the way!";
  } else if (hasGospelOrders && hasTractPacks) {
    return `Your tract packs are on the way! Also, The Gospel is on the way to ${names}.`;
  } else {
    return "";
  }
}

export function OrderConfirmed({
  setTheme,
  orderInformation,
  setOrderInformation,
  setPaymentIntent,
}: PageProps) {
  const [updateStatus, markTransactionPaid] = useLazyFetchHook<
    MarkPaidPayload,
    MarkPaidResponse
  >();
  const [message] = React.useState(() => getMessage(orderInformation));

  React.useEffect(() => {
    setTheme(
      "url(/assets/gradient-bg.png) no-repeat center/cover",
      false,
      "var(--off-white)"
    );
  }, [setTheme]);

  React.useEffect(() => {
    setPaymentIntent(null);
  }, [setPaymentIntent]);

  React.useEffect(() => {
    setOrderInformation(({ fullName, email }) => ({
      ...getEmptyOrderInformation(),
      fullName,
      email,
    }));
    const paymentIntentSerialized = localStorage.getItem(
      STORAGE_KEY.PAYMENT_INTENT
    );
    if (paymentIntentSerialized) {
      const paymentIntent = JSON.parse(
        paymentIntentSerialized
      ) as CreatePaymentIntentResponse;
      markTransactionPaid({
        method: "post",
        url: API.TRANSACTION_MARK_PAID,
        body: {
          transactionId: paymentIntent.transactionId,
          paymentIntentId: paymentIntent.paymentIntentId,
        },
      });
    }
  }, [setOrderInformation, markTransactionPaid]);

  React.useEffect(() => {
    if (updateStatus.status === STATUS.DONE) {
      localStorage.removeItem(STORAGE_KEY.PAYMENT_INTENT);
    }
  }, [updateStatus]);

  return (
    <div
      className="u-flex u-flexCol u-flexAlignItemsCenter u-textCenter"
      style={{
        margin: "auto",
        overflow: "hidden",
        minHeight: "calc(100vh - 56px)",
      }}
    >
      {updateStatus.status === STATUS.DONE ? (
        <>
          <Link to={PATH.HOME}>
            <img
              style={{ marginTop: 68 }}
              src="/assets/envelope-heart.webp"
              alt="Mailbox"
              height="47"
            />
          </Link>
          <LightHeader isCTA title="Order Confirmed" subTitle={message} />
          <Link className="button button--primary button--large" to={PATH.HOME}>
            Return to Home
          </Link>
          <div className="home__mailbox">
            <img src="/assets/order-confirmed.svg" alt="mailbox" />
          </div>
        </>
      ) : updateStatus.status === STATUS.LOADING ? (
        <h3>Processing...</h3>
      ) : (
        <div className="u-flex u-flexCol u-flexAlignItemsCenter u-flexExpand">
          <h3 className="u-marginVert6gu">
            Something went wrong processing your payment!
          </h3>
          <Link className="button button--primary button--large" to={PATH.HOME}>
            Return to Home
          </Link>
        </div>
      )}
    </div>
  );
}
