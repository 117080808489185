import React from "react";
import { API } from "../../../../path";
import { useLazyFetchHook } from "../../../../utils/use-fetch-hook";
import { Tract } from "../../../../../../server/src/contracts/tract";
import { TractEditor } from "./tract-editor";
import { TractTable } from "./tract-table";
import { TabProps } from "../interfaces";

interface TractId {
  tractId: Tract["id"];
}

export function ManageTracts({ products, refetch }: TabProps) {
  const [isAddingTract, setIsAddingTract] = React.useState(false);
  const [editingTract, setIsEditingTract] = React.useState<Tract>();
  const [, deleteTract] = useLazyFetchHook<TractId, never>();

  const handleDelete = React.useCallback(
    (tractId: string) => {
      deleteTract({
        method: "delete",
        url: API.TRACT,
        body: { tractId },
      });
      refetch();
    },
    [deleteTract, refetch],
  );

  const handleEdit = React.useCallback(
    (tractId: string) => {
      const tract = products.tracts.find((t) => t.id === tractId);
      if (tract) {
        setIsEditingTract(tract);
      }
    },
    [setIsEditingTract, products],
  );

  if (isAddingTract) {
    return (
      <TractEditor
        onCancel={() => {
          setIsAddingTract(false);
        }}
        onDone={() => {
          setIsAddingTract(false);
          refetch();
        }}
      />
    );
  }
  if (editingTract) {
    return (
      <TractEditor
        tract={editingTract}
        onCancel={() => {
          setIsEditingTract(undefined);
        }}
        onDone={() => {
          setIsEditingTract(undefined);
          refetch();
        }}
      />
    );
  }
  return (
    <>
      <div className="u-flex u-flexAlignItemsCenter u-sizeFull">
        <h3>Tracts</h3>
        <button
          className="button button--primary button--small"
          onClick={() => {
            setIsAddingTract(true);
          }}
        >
          Add Tract
        </button>
      </div>
      <div>
        {products.tracts.length > 0 ? (
          <TractTable
            tracts={products.tracts}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        ) : (
          <h4>No tracts!</h4>
        )}
      </div>
    </>
  );
}
