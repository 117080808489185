import React from "react";
import { Tract } from "../../../../../server/src/contracts/tract";
import {
  getTractBackground,
  getTractCoverImage,
} from "../../../common/get-tract-image-url";

interface MoreTractsProps {
  tracts: Tract[];
  onSelect: (tractId: string) => void;
}

export function MoreTracts({ tracts, onSelect }: MoreTractsProps) {
  return (
    <div className="u-flex u-flexWrap u-flexAlignItemsCenter rounded u-textLeft add-on">
      <h3 className="u-sizeFull u-textCenter add-on__header">
        Send an additional tract to a different person
      </h3>
      {tracts.map((tract, index) => (
        <React.Fragment key={tract.id}>
          {index !== 0 && (
            <div className="u-sizeFull rule rule--black u-marginBottom5gu" />
          )}
          <div className="u-flex u-flexCol u-sizeFull u-flexAlignItemsCenter u-marginBottom3gu add-on__tract-details">
            <div
              className="rounded u-flex u-flexAlignItemsCenter u-flexJustifyContent"
              style={{
                backgroundImage: getTractBackground(tract),
                height: 195,
              }}
            >
              <img
                src={getTractCoverImage(tract.id)}
                alt={tract.title}
                style={{
                  height: "77%",
                  margin: "0 22px",
                  transform: "translateY(20px) scale(1.5)",
                }}
              />
            </div>
            <div className="u-flex u-flexCol">
              <div className="u-textBold">
                For someone who needs{" "}
                {tract.subTitle.split(" ").slice(-1)[0].toLowerCase()}
              </div>
              <div>
                {tract.title}: {tract.subTitle}
              </div>
              <button
                onClick={() => {
                  onSelect(tract.id);
                }}
                className="button button--primary u-flexAlignSelfCenter u-marginVert2gu"
              >
                Select
              </button>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
