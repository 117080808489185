import React from "react";
import { Tract } from "../../../../../../server/src/contracts/tract";
import { TractThumbnail } from "../../../../common/tract/thumbnail";
import { Table, TableHeader } from "../../table";

interface TractTableProps {
  tracts: Tract[];
  onEdit: (tractId: string) => void;
  onDelete: (tractId: string) => void;
}

export function TractTable({
  tracts,
  onDelete,
  onEdit,
}: TractTableProps): React.ReactElement {
  const Action = React.useCallback(
    ({ data: tract }: { data: Tract }) => {
      return (
        <>
          <button
            className="button button--small button--alert"
            onClick={() => {
              onDelete(tract.id);
            }}
          >
            Delete
          </button>
          <button
            className="button button--small button--primary"
            onClick={() => {
              onEdit(tract.id);
            }}
          >
            Edit
          </button>
        </>
      );
    },
    [onDelete, onEdit],
  );
  const headers: Array<TableHeader<Tract>> = [
    {
      key: "id",
      displayName: "",
      Component: ({ data }) => <TractThumbnail {...data} />,
    },
    { key: "title", displayName: "Title" },
    { key: "subTitle", displayName: "Sub Title" },
    { key: "abbreviation", displayName: "Abbreviation" },
    { key: "urlPart", displayName: "URL Part" },
    { key: "displayPrice", displayName: "Price" },
    { key: "id", displayName: "Actions", Component: Action },
  ];
  return <Table rows={tracts} headers={headers} idProp="id" />;
}
