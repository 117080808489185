import React from "react";
import { PageProps } from "../../interfaces";
import "./light-theme.css";

export function useLightTheme(setTheme: PageProps["setTheme"]) {
  React.useEffect(() => {
    setTheme(
      "linear-gradient(180deg, rgba(245, 241, 237, 0.931) 86.38%, rgba(245, 241, 237, 0.98) 100%)",
      false
    );
  }, [setTheme]);
}

export function Wrapper({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) {
  return (
    <div className="u-flex u-lg-flexRow u-flexCol u-sizeFull u-textLeft u-light">
      {children}
    </div>
  );
}

export function Section({
  children,
  isRounded = false,
  isSmallSquare = false,
}: {
  children: React.ReactElement | React.ReactElement[];
  isRounded?: boolean;
  isSmallSquare?: boolean;
}) {
  return (
    <section
      className={`u-flex u-flexCol u-lg-size1of2 u-marginTop4gu${
        isRounded ? " rounded-section" : ""
      }${isRounded && isSmallSquare ? " rounded-section--sm-square" : ""}`}
    >
      {children}
    </section>
  );
}
