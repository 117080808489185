import React from "react";
import { Link } from "react-router-dom";
import { PATH } from "./path";
import "./footer.css";
import { socialMediaLinks } from "./utils/social-media";

const thisYear = new Date().getFullYear();

interface FooterProps {
  isDark: boolean;
  backgroundColor: string;
}

const lightTheme = {
  color: "#7a7876",
  borderTop: `1px solid rgba(211, 211, 211, 0.5)`,
};

const darkTheme = {
  color: "var(--off-white)",
  borderTop: `1px solid rgba(211, 211, 211, 0.3)`,
};

export function Footer({
  isDark,
  backgroundColor,
}: FooterProps): React.ReactElement {
  const theme = isDark ? darkTheme : lightTheme;
  return (
    <nav
      className={`mtg-footer ${isDark ? "" : " mtg-footer--light"}`}
      style={{ ...theme, background: backgroundColor }}
    >
      <div>Copyright © {thisYear}. All Rights Reserved. &nbsp;</div>
      <div className="mtg-footer__legal">
        <Link style={{ color: theme.color }} to={PATH.PRIVACY_POLICY}>
          Privacy Policy
        </Link>
        &nbsp; | &nbsp;
        <Link
          className="u-flexExpandRight"
          style={{ color: theme.color }}
          to={PATH.TERMS_AND_CONDITIONS}
        >
          Terms &amp; Conditions
        </Link>
      </div>
      <div className="mtg-footer__social-links">
        {socialMediaLinks.map(({ alt, href, src }, index) => (
          <a
            key={index}
            className="social-link"
            href={href}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={src}
              alt={alt}
              height={18}
              width={18}
              style={{ filter: isDark ? "invert()" : "inherit" }}
            />
          </a>
        ))}
      </div>
    </nav>
  );
}
