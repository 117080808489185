import React from "react";
import { COUNTRY } from "./country-select";

const usStates = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

const caProvinces = [
  // provinces
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Nova Scotia",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  // territories
  "Northwest Territories",
  "Nunavut",
  "Yukon",
];

export const countryOptions: Record<COUNTRY, string[]> = {
  [COUNTRY.US]: usStates,
  [COUNTRY.CA]: caProvinces,
};

interface StateSelectProps {
  id: string;
  state: string;
  country: COUNTRY;
  className: string;
  isDisabled?: boolean;
  onStateChange: (newState: string) => void;
}

export function StateSelect({
  id,
  country,
  state,
  onStateChange,
  className,
  isDisabled = false,
}: StateSelectProps) {
  const unique = `state-select-input-${id}`;

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> =
    React.useCallback(
      (e) => {
        onStateChange(e.target.value);
      },
      [onStateChange]
    );

  const states = countryOptions[country] || [];

  return (
    <div
      className={`select-wrapper${
        isDisabled ? " select-wrapper--disabled" : ""
      }${className ? " " + className : ""}`}
    >
      <select
        name={unique}
        id={unique}
        value={state}
        disabled={isDisabled}
        onChange={handleSelectChange}
        className="u-sizeFull"
      >
        {states.map((state) => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
}
