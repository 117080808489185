import React from "react";
import { Link, useLocation } from "react-router-dom";
import { LightHeader } from "../common/light-header";
import { PageProps, STORAGE_KEY } from "../interfaces";
import { API, PATH } from "../path";
import { useLazyFetchHook } from "../utils/use-fetch-hook";
import {
  MarkDonationPaidRequest,
  MarkDonationPaidResponse,
} from "../../../server/src/contracts/donate";

export function DonateConfirmed({ setTheme }: PageProps) {
  const [, markDonationPaid] = useLazyFetchHook<
    MarkDonationPaidRequest,
    MarkDonationPaidResponse
  >();

  React.useEffect(() => {
    setTheme(
      "url(/assets/gradient-bg.png) no-repeat center/cover",
      false,
      "var(--off-white)"
    );
  }, [setTheme]);

  const location = useLocation();
  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const paymentIntent = urlParams.get("payment_intent");
    if (paymentIntent) {
      const donationId = localStorage.getItem(STORAGE_KEY.DONATION_INTENT);
      if (donationId) {
        markDonationPaid({
          method: "post",
          url: API.DONATION_MARK_PAID,
          body: { donationId },
        });
        localStorage.removeItem(STORAGE_KEY.DONATION_INTENT);
      }
    }
  }, [location, markDonationPaid]);

  return (
    <div
      className="u-flex u-flexCol u-flexAlignItemsCenter u-textCenter"
      style={{
        margin: "auto",
        overflow: "hidden",
        minHeight: "calc(100vh - 56px)",
      }}
    >
      <Link to={PATH.HOME}>
        <img
          style={{ marginTop: 68 }}
          src="/assets/envelope-heart.webp"
          alt="Mailbox"
          height="47"
        />
      </Link>
      <LightHeader
        isCTA
        title="Donation Confirmed"
        subTitle={`Your donation will help spread the gospel. Thank you for doing your part!`}
      />
      <Link className="button button--primary button--large" to={PATH.HOME}>
        Return to Home
      </Link>
      <div className="home__mailbox">
        <img src="/assets/order-confirmed.svg" alt="mailbox" />
      </div>
    </div>
  );
}
