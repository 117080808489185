import React from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { PageProps } from "../../interfaces";
import { NavBar } from "../../common/nav-bar";
import { STEP } from "./interfaces";
import { PrevStepNav } from "../../common/prev-step-nav";
import { useGetProductsHook } from "../../common/product-service";
import { STATUS } from "../../utils/use-fetch-hook";
import { RecipientPayload } from "../../../../server/src/contracts/recipient";
import { steps, useGetStepsHook } from "./steps";
import { FullPage } from "../../common/full-page";

function scrollToTop() {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });
}

export function Home({
  setTheme,
  isDark,
  orderInformation,
  setOrderInformation,
  onRecipientUpdate,
  paymentIntent,
  setPaymentIntent,
}: PageProps) {
  const navigate = useNavigate();

  const productsState = useGetProductsHook();

  const products =
    productsState.status === STATUS.DONE ? productsState.payload : null;
  // dev mode to cut to steps
  React.useEffect(() => {
    if (
      process.env.NODE_ENV === "development" &&
      process.env.REACT_APP_FAKE_ORDER === "true" &&
      products &&
      products.tracts &&
      products.tracts.length &&
      ""
    ) {
      setOrderInformation({
        fullName: "John Doe",
        email: "john.doe@mail.com",
        bulkTractOrders: [
          {
            tractId: products.tracts[0].id,
            quantity: 25,
            price: 20,
            displayPrice: "$20.00",
          },
        ],
        donation: { amount: 10, isCustom: false },
        initialTractId: products.tracts[0].id,
        tracts: [
          {
            tractId: products.tracts[0].id,
            recipients: [
              {
                country: "US",
                address1: "123 Main St.",
                address2: "Apt #B315",
                addOnId: products.addOns[0]?.id || null,
                city: "Brooklyn",
                firstName: "Sam",
                id: "810-0",
                lastName: "Armstrong",
                hasPersonalizedMessage: true,
                personalizedMessage: `Sam,

It was a pleasure meeting you at last week’s conference. I enjoyed our conversation about faith and religion and I wanted to send you a copy of the Gospel. It will only take 10-15 minutes to read and represents what I believe.

If you have any questions or would like to talk about this more, feel free to reach out to me.

Blessings,
Chris Smith`,
                state: "AK",
                zip: "02011",
                shippingCost: 5.0,
                displayShippingCost: "$5.00",
                shippingServiceType:
                  "usps_media_mail" as RecipientPayload["shippingServiceType"],
              },
            ],
          },
          {
            tractId: products.tracts[1].id,
            recipients: [
              {
                country: "US",
                address1: "189 Lake St.",
                address2: "",
                addOnId: products.addOns[1]?.id || null,
                city: "St. Paul",
                firstName: "Jimmy",
                id: "811-1",
                lastName: "Johns",
                hasPersonalizedMessage: false,
                personalizedMessage: "",
                state: "MN",
                zip: "55420",
                shippingCost: 9.53,
                displayShippingCost: "$9.53",
                shippingServiceType:
                  "usps_first_class_mail" as RecipientPayload["shippingServiceType"],
              },
            ],
          },
        ],
        shippingAddress: {
          country: "US",
          address1: "330 Madison St.",
          address2: "",
          city: "Elk River",
          state: "MN",
          zip: "55330",
        },
        billingAddress: {
          country: "US",
          address1: "781 Lake St",
          address2: "",
          city: "Minneapolis",
          state: "MN",
          zip: "55410",
        },
        tractPackShippingRate: null,
        customLiterature: null,
      });
    }
  }, [products, setOrderInformation]);

  const { prevStep, currentStep, nextStep } = useGetStepsHook();

  const goToNextStep = React.useCallback(() => {
    navigate(nextStep);
    scrollToTop();
  }, [nextStep, navigate]);

  const goToPreviousStep = React.useCallback(() => {
    navigate(prevStep);
    scrollToTop();
  }, [prevStep, navigate]);

  const handleRestart = React.useCallback(() => {
    navigate(STEP.GET_STARTED);
  }, [navigate]);

  return (
    <>
      {currentStep === STEP.GET_STARTED ? (
        <NavBar />
      ) : (
        <PrevStepNav
          onPrev={goToPreviousStep}
          isDark={isDark}
          {...(currentStep === STEP.WHO_IS_MAILING
            ? {
                label: "Cancel",
                buttonClassName: "button button--tertiary",
              }
            : null)}
        />
      )}
      <FullPage>
        <Routes>
          {steps.map(({ path, Component }) => (
            <Route
              key={path}
              {...{ [path ? "path" : "index"]: path || true }}
              element={
                <Component
                  productsState={productsState}
                  onNext={goToNextStep}
                  onPrev={goToPreviousStep}
                  onRestart={handleRestart}
                  setTheme={setTheme}
                  orderInformation={orderInformation}
                  setOrderInformation={setOrderInformation}
                  onRecipientUpdate={onRecipientUpdate}
                  paymentIntent={paymentIntent}
                  setPaymentIntent={setPaymentIntent}
                />
              }
            />
          ))}
          <Route
            path="*"
            element={<Navigate to={STEP.GET_STARTED} replace />}
          />
        </Routes>
      </FullPage>
    </>
  );
}
