import React from "react";
import Slider from "react-slick";
import { StepProps } from "../interfaces";
import "./choose-your-tract.css";
import { TractTitle } from "../../../common/tract/title";
import { TractPreview } from "../../../common/tract/preview";
import { FooterNav } from "../../../common/footer-nav";
import { STATUS } from "../../../utils/use-fetch-hook";
import { getTractBackground } from "../../../common/get-tract-image-url";
import { TractDetails } from "../../../common/tract/tract-details";
import { Tract } from "../../../../../server/src/contracts/tract";
import { hasSelectedTract } from "../../../common/order-validation";

export function ChooseYourTract({
  setTheme,
  onNext,
  productsState,
  orderInformation,
  setOrderInformation,
}: StepProps) {
  const products =
    productsState.status === STATUS.DONE ? productsState.payload : undefined;
  const { initialTractId } = orderInformation;

  const [tractIndex, setTractIndex] = React.useState(() => {
    if (products) {
      const index = products.tracts.findIndex(
        (tract) => tract.id === initialTractId
      );
      if (index !== -1) {
        return index;
      }
    }
    return 0;
  });

  function getInitialSlide() {
    if (products) {
      const index = products.tracts.findIndex(
        (tract) => tract.id === initialTractId
      );
      if (index !== -1) {
        return index;
      }
    }
    return tractIndex;
  }

  React.useEffect(() => {
    if (products) {
      const index = products.tracts.findIndex(
        (tract) => tract.id === initialTractId
      );
      if (index !== -1) {
        setTractIndex(index);
      }
    }
  }, [setTractIndex, products, initialTractId]);

  const [showTract, setShowTract] = React.useState(false);

  const toggleShowTract = React.useCallback(() => {
    setShowTract((prev) => !prev);
  }, [setShowTract]);

  const unSelectTract = React.useCallback(
    (tractId: Tract["id"]) => {
      setOrderInformation((prev) => {
        const nextTracts = prev.tracts.filter(
          (tract) => tract.tractId !== tractId
        );
        return {
          ...prev,
          initialTractId: nextTracts.length > 0 ? nextTracts[0].tractId : null,
          tracts: nextTracts,
        };
      });
    },
    [setOrderInformation]
  );

  const selectTract = React.useCallback(
    (tractId: Tract["id"]) => {
      setOrderInformation((prev) => ({
        ...prev,
        initialTractId: tractId,
        tracts: [{ tractId, recipients: [] }],
      }));
    },
    [setOrderInformation]
  );

  const activeTract: Tract | undefined = products?.tracts[tractIndex];

  React.useEffect(() => {
    if (activeTract) {
      setTheme(
        getTractBackground(activeTract),
        true,
        activeTract.backgroundColorEnd
      );
    }
    setShowTract(false);
  }, [setTheme, activeTract, setShowTract]);

  if (productsState.status === STATUS.LOADING) {
    return <h3 className="u-text-offWhite step-header">Loading tracts...</h3>;
  }

  if (productsState.status === STATUS.ERROR || !activeTract || !products) {
    return (
      <h3 className="u-text-offWhite step-header">
        Uh oh! Something went wrong!
      </h3>
    );
  }

  const isSelected = initialTractId === activeTract.id;

  return (
    <>
      <h3 className="u-text-offWhite step-header">Choose your tract</h3>
      <TractTitle {...activeTract} />
      <div className="cyt__slider-container">
        <Slider
          adaptiveHeight
          dots
          infinite
          centerMode
          centerPadding="50px"
          className="center"
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          initialSlide={getInitialSlide()}
          beforeChange={(_, newActive) => {
            setTractIndex(newActive);
          }}
        >
          {products.tracts.map((tract) => (
            <TractPreview key={tract.id} {...tract} />
          ))}
        </Slider>
      </div>
      <TractDetails
        tract={activeTract}
        showTract={showTract}
        toggleShowTract={toggleShowTract}
        selectButton={
          isSelected ? (
            <button
              onClick={() => {
                unSelectTract(activeTract.id);
              }}
              className="button button--primary button--selected select-tract"
            >
              Selected
            </button>
          ) : (
            <button
              onClick={() => {
                selectTract(activeTract.id);
              }}
              className="button button--primary select-tract"
            >
              &nbsp;Select&nbsp;
            </button>
          )
        }
      />
      <FooterNav
        onNext={onNext}
        prevButton={
          showTract ? (
            <button
              onClick={toggleShowTract}
              className="button--close button--close-large"
            />
          ) : undefined
        }
        isReadyToGoNext={hasSelectedTract(orderInformation)}
      />
    </>
  );
}
