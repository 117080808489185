import React from "react";
import { Address } from "../../../../server/src/contracts/address";
import { RecipientPayload } from "../../../../server/src/contracts/recipient";
import { RecipientUpdate } from "../../interfaces";
import { isAddressValid } from "../../utils/address";
import { displayCountry } from "../home/country-select";
import {
  MESSAGE_LIMIT,
  useEditRecipientHook,
} from "../home/use-edit-recipient-hook";
import { AddressForm } from "./address-form";
import { PreviewRule, PreviewTitle } from "./preview";

interface RecipientReviewProps {
  recipient: RecipientPayload;
  tractId: string;
  isEditing: boolean;
  toggleEditing: () => void;
  onRemove: (tractId: string, recipientId: string) => void;
  onChange: (update: RecipientUpdate) => void;
}

export function RecipientReview({
  tractId,
  recipient,
  isEditing,
  toggleEditing,
  onChange,
  onRemove,
}: RecipientReviewProps) {
  const recipientId = recipient.id;

  // TODO: this should only update the orderInformation state, via onChange, when done editing
  // to prevent invalid state from being persisted.
  const {
    handleFirstNameChange,
    handleLastNameChange,
    handlePersonalizedMessage,
  } = useEditRecipientHook({ tractId, recipientId, onChange });

  const handleAddressChange = React.useCallback(
    (newAddress: Address) => {
      onChange({
        partialRecipient: newAddress,
        recipientId,
        tractId,
      });
    },
    [onChange, recipientId, tractId]
  );

  const isDisabled = isEditing && !isAddressValid(recipient);

  return (
    <>
      <div className="u-flex u-flexAlignItemsCenter u-marginBottom4gu">
        {!isEditing && (
          <PreviewTitle
            message={`Recipient: ${recipient.firstName} ${recipient.lastName}`}
          />
        )}
        <button
          disabled={isDisabled}
          style={{ textDecoration: "underline" }}
          className={`button button--tertiary u-flexExpandLeft${
            isDisabled ? "" : " u-text-black"
          }`}
          onClick={toggleEditing}
        >
          {isEditing ? "Done" : "Edit"}
        </button>
        <button
          style={{ filter: "invert(100%)" }}
          onClick={() => {
            onRemove(tractId, recipientId);
          }}
          className="button button--close"
        />
      </div>
      {isEditing && (
        <div className="u-flex u-flexCol review-name">
          <input
            autoFocus
            type="text"
            name="first-name"
            id={`${recipientId}-first-name`}
            placeholder="First Name"
            value={recipient.firstName}
            onChange={handleFirstNameChange}
          />
          <input
            type="text"
            name="last-name"
            id={`${recipientId}-last-name`}
            placeholder="Last Name"
            value={recipient.lastName}
            onChange={handleLastNameChange}
          />
        </div>
      )}
      <PreviewRule />
      <div className="u-flex u-marginTop1gu">
        <PreviewTitle
          className={isEditing ? "u-sm-hidden" : undefined}
          message="Address"
        />
        {isEditing ? (
          <AddressForm
            initialAddress={recipient}
            onAddressChange={handleAddressChange}
          />
        ) : (
          <div className="u-flexAlignSelfEnd">
            {recipient.address1}{" "}
            {recipient.address2 && (
              <>
                <br />
                {recipient.address2}
              </>
            )}
            <br />
            {recipient.city} {recipient.state} {recipient.zip}
            <br />
            {displayCountry(recipient.country)}
          </div>
        )}
      </div>
      {recipient.hasPersonalizedMessage &&
        (isEditing ? (
          <>
            <textarea
              spellCheck="true"
              className="u-sizeFull"
              name="personalized-message"
              rows={4}
              id={`${recipientId}-personalized-message`}
              placeholder="Personalized Message"
              maxLength={MESSAGE_LIMIT}
              value={recipient.personalizedMessage}
              onChange={handlePersonalizedMessage}
            />
            <div className="u-sizeFull u-textRight u-text-black u-opacity5">
              {recipient.personalizedMessage.length}/{MESSAGE_LIMIT}
            </div>
            <div className="u-sizeFull rule rule--offWhite rule--thick" />
          </>
        ) : (
          <div className="u-flex u-marginVert4gu">
            <PreviewTitle message="Message" />
            <div className="u-flexAlignSelfEnd">
              {recipient.personalizedMessage}
            </div>
          </div>
        ))}
    </>
  );
}
