import React from "react";
import { Table, TableHeader } from "./table";
import { Order as OrderDTO } from "../../../../server/src/contracts/orders";
import { useToggleHook } from "../../utils/use-toggle-hook";
import { PersonalizedMessageModal } from "../../personalized-message-modal";
import { displayCountry } from "../home/country-select";
import { EvangelistLink } from "./evangelist-link";

const PersonalizedMessage: React.ComponentType<{ data: OrderDTO }> = ({
  data,
}: {
  data: OrderDTO;
}) => {
  const [isOpen, toggleOpen] = useToggleHook(false);
  if (!data.personalizedMessage) {
    return null;
  }
  return (
    <React.Fragment>
      {isOpen && (
        <PersonalizedMessageModal
          message={data.personalizedMessage}
          orderNumber={data.number}
          onRequestClose={toggleOpen}
        />
      )}
      <button
        className="button button--small button--secondary"
        onClick={toggleOpen}
      >
        View
      </button>
    </React.Fragment>
  );
};

const OrderEvangelistLink: TableHeader<OrderDTO>["Component"] = ({
  data,
  dataKey,
}) => (
  <EvangelistLink
    id={data.evangelistId}
    displayFullName={String(data[dataKey])}
  />
);

const initialHeaders: Array<TableHeader<OrderDTO>> = [
  { key: "number", displayName: "Order #" },
  { key: "pickingListCode", displayName: "Picking List Code" },
  { key: "displayDate", displayName: "Date" },
  { key: "displayTime", displayName: "Time" },
  { key: "displayStatus", displayName: "Status" },
  { key: "displayTotal", displayName: "Total" },
  { key: "displayShipping", displayName: "Shipping" },
  {
    key: "evangelistEmail",
    displayName: "Email",
    Component: OrderEvangelistLink,
  },
  {
    key: "evangelistFullName",
    displayName: "Evangelist",
    Component: OrderEvangelistLink,
  },
  {
    key: "countryCode",
    displayName: "Country",
    Component: (props) => <>{displayCountry(props.data.countryCode)}</>,
  },
  {
    key: "personalizedMessage",
    displayName: "Personalized Message",
    Component: PersonalizedMessage,
  },
];

export function OrderTable({
  headers,
  orders,
  omitEvangelist,
}: {
  orders: OrderDTO[];
  headers?: TableHeader<OrderDTO>[];
  omitEvangelist?: boolean;
}) {
  const allHeaders = React.useMemo(
    () =>
      (headers ? headers.concat(initialHeaders) : initialHeaders).filter(
        (header) => !omitEvangelist || !header.key.startsWith("evangelist"),
      ),
    [headers, omitEvangelist],
  );
  return <Table rows={orders} headers={allHeaders} idProp="number" />;
}
