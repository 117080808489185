import React from "react";
import { BulkPrice } from "../../../../../../server/src/contracts/bulk-price";
import { currencyFormatter } from "../../../../common/currency-formatter";
import { TextInput } from "../../../../common/text-input";

function createNewBulkPrice(): BulkPrice {
  return {
    quantity: Number.NaN,
    displayQuantity: "",
    price: Number.NaN,
    displayPrice: "",
    shipping: Number.NaN,
    displayShipping: "",
  };
}

interface ManageBulkTractsProps {
  bulkPrices: BulkPrice[];
  onChange: React.Dispatch<React.SetStateAction<BulkPrice[]>>;
}

export function ManageBulkPrices({
  bulkPrices,
  onChange,
}: ManageBulkTractsProps) {
  // have one bulk price by default if none present
  const [initialBulkPrices] = React.useState(bulkPrices);
  React.useEffect(() => {
    if (initialBulkPrices.length === 0) {
      onChange([createNewBulkPrice()]);
    }
  }, [initialBulkPrices, onChange]);

  const addBulkPrice = React.useCallback(() => {
    onChange((prev) => prev.concat([createNewBulkPrice()]));
  }, [onChange]);

  function createHandlePriceChange(
    index: number
  ): React.ChangeEventHandler<HTMLInputElement> {
    return ({ target: { value } }) => {
      onChange((p) => [
        ...p.slice(0, index),
        {
          ...p[index],
          price: Number.parseFloat(value.replace(/^\$/, "")),
          displayPrice: value,
        },
        ...p.slice(index + 1),
      ]);
    };
  }

  function createHandleShippingPriceChange(
    index: number
  ): React.ChangeEventHandler<HTMLInputElement> {
    return ({ target: { value } }) => {
      onChange((p) => [
        ...p.slice(0, index),
        {
          ...p[index],
          shipping: Number.parseFloat(value.replace(/^\$/, "")),
          displayShipping: value,
        },
        ...p.slice(index + 1),
      ]);
    };
  }

  function createHandleQuantityChange(
    index: number
  ): React.ChangeEventHandler<HTMLInputElement> {
    return ({ target: { value } }) => {
      onChange((p) => [
        ...p.slice(0, index),
        {
          ...p[index],
          quantity: Number.parseFloat(value),
          displayQuantity: value,
        },
        ...p.slice(index + 1),
      ]);
    };
  }

  function createHandleRemove(
    index: number
  ): React.MouseEventHandler<HTMLButtonElement> {
    return () => {
      onChange((p) => [...p.slice(0, index), ...p.slice(index + 1)]);
    };
  }

  return (
    <>
      <h3>Bulk Tract Pricing</h3>
      <div>
        {bulkPrices.map((bulkPrice, index) => (
          <div
            key={index}
            style={{ borderRadius: 5 }}
            className="u-flex u-flexCol u-border-cream u-marginVert4gu u-padding6gu"
          >
            <TextInput
              id={`quantity-${index}`}
              onChange={createHandleQuantityChange(index)}
              placeholder="Quantity"
              className="u-marginBottom6gu"
              value={bulkPrice.displayQuantity}
            />
            <TextInput
              id={`price-${index}`}
              onChange={createHandlePriceChange(index)}
              placeholder="Price"
              className="u-marginBottom6gu"
              value={bulkPrice.displayPrice}
            />
            <TextInput
              id={`shipping-price-${index}`}
              onChange={createHandleShippingPriceChange(index)}
              placeholder="Shipping Price"
              className="u-marginBottom6gu"
              value={bulkPrice.displayShipping}
            />
            <div className="u-flex">
              <div className="u-price">
                {currencyFormatter.format(bulkPrice.price / bulkPrice.quantity)}
                /each
              </div>
              <button
                className="u-flexExpandLeft button button--small button--primary"
                onClick={createHandleRemove(index)}
              >
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>
      <button
        className="button button--small button--secondary"
        onClick={addBulkPrice}
      >
        Add Bulk Price
      </button>
      <div className="rule u-sizeFull u-marginVert6gu" />
    </>
  );
}
