import React from "react";
import { Tract } from "../../../../../server/src/contracts/tract";
import { RadioInput } from "../../../common/radio-input";
import { TractThumbnail } from "../../../common/tract/thumbnail";
import "./choose-tract.css";

interface ChooseTractProps {
  tracts: Tract[];
  initialTractId: string | null;
  onDone: (tractId: string) => void;
}

export function ChooseTract({
  tracts,
  initialTractId,
  onDone,
}: ChooseTractProps) {
  const [tractId, setTractId] = React.useState(initialTractId);
  return (
    <>
      <h4>Choose Tract</h4>
      {tracts.map((tract) => (
        <RadioInput
          key={tract.id}
          id={tract.id}
          className={`bulk-gospel-tract ${
            tract.id === tractId ? "bulk-gospel-tract--selected" : ""
          }`}
          isChecked={tract.id === tractId}
          label={
            <div className="u-flex u-flexAlignItemsCenter">
              <TractThumbnail {...tract} />
              <p>
                {tract.title}: {tract.subTitle}
              </p>
            </div>
          }
          onChange={setTractId}
          name={tract.subTitle}
        />
      ))}
      <button
        disabled={!tractId}
        className="button button--primary button--large u-marginTop3gu u-flexAlignSelfEnd"
        onClick={() => {
          if (tractId) {
            onDone(tractId);
          }
        }}
      >
        Next
      </button>
    </>
  );
}
