import React from "react";
import { MAX_LENGTH } from "../../input-limits";
import { CSS_VARS } from "../../common/css-variables";
import { hasValidCustomerInfo } from "../../common/order-validation";
import { StepProps } from "./interfaces";
import "./who-is-mailing.css";

export function WhoIsMailing({
  setTheme,
  onNext,
  orderInformation,
  setOrderInformation,
}: StepProps) {
  React.useEffect(() => {
    setTheme(
      `url(/assets/who-is-mailing-bg.png) no-repeat center/cover ${CSS_VARS["--dark-green"]}`,
      true,
      CSS_VARS["--dark-green"]
    );
  }, [setTheme]);

  const updateFullName = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOrderInformation((prev) => ({
        ...prev,
        fullName: e.target.value,
      }));
    },
    [setOrderInformation]
  );

  const updateEmail = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOrderInformation((prev) => ({
        ...prev,
        email: e.target.value,
      }));
    },
    [setOrderInformation]
  );

  return (
    <>
      <h2 className="u-text-white step-header">Who's mailing the Gospel?</h2>
      <div className="who-is-mailing u-flex u-sm-flexCol u-flexAlignItemsCenter">
        <label htmlFor="name">My name is</label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Type your full name"
          width={275}
          maxLength={MAX_LENGTH.CustomerName}
          value={orderInformation.fullName}
          onChange={updateFullName}
        />
        <label htmlFor="email">and my email is</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Type your email address"
          width={275}
          maxLength={MAX_LENGTH.Email}
          value={orderInformation.email}
          onChange={updateEmail}
        />
        <span className="u-sm-hidden">.</span>
      </div>
      <div className="u-flex u-flexExpandTop u-sizeFull">
        <button
          className="button button--primary button--large who-is-mailing__button"
          onClick={onNext}
          disabled={!hasValidCustomerInfo(orderInformation)}
        >
          Continue
        </button>
      </div>
    </>
  );
}
