import React from "react";
import {
  BulkTractOrder,
  OrderInformation,
} from "../../../../server/src/contracts/payment-intent";
import { ShippingRate } from "../../../../server/src/contracts/shipping";
import { Tract } from "../../../../server/src/contracts/tract";
import { currencyFormatter } from "../../common/currency-formatter";
import { ShippingSelector } from "./shipping-selector";
import { TractThumbnail } from "../../common/tract/thumbnail";
import { PreviewContainer, PreviewRule, PreviewTitle } from "./preview";
import "./preview-bulk-tract-order.css";
import { STATUS } from "../../utils/use-fetch-hook";
import { isAddressValid } from "../../utils/address";
import { useGetShippingRatesHook } from "./use-get-shipping-rates-hook";

interface PreviewBulkTractOrdersProps {
  tracts: Tract[];
  orderInformation: OrderInformation;
  onShippingRateChange: (newShippingRate: ShippingRate | null) => void;
  onRemoveTractPack: (tractId: string) => void;
}

function getTractCount(bulkTractOrders: BulkTractOrder[]): number {
  return bulkTractOrders.reduce(
    (total, bulkTractOrder) => total + bulkTractOrder.quantity,
    0
  );
}

export function PreviewBulkTractOrders({
  tracts,
  orderInformation: { bulkTractOrders, tractPackShippingRate, shippingAddress },
  onShippingRateChange,
  onRemoveTractPack,
}: PreviewBulkTractOrdersProps) {
  function findTract(id: string): Tract {
    return tracts.find((tract) => tract.id === id) || tracts[0];
  }

  const hasValidAddress = isAddressValid(shippingAddress);

  const clearShippingRate = React.useCallback(() => {
    onShippingRateChange(null);
  }, [onShippingRateChange]);

  const tractCount = getTractCount(bulkTractOrders);
  const shippingRatesState = useGetShippingRatesHook(
    shippingAddress,
    tractCount,
    0,
    clearShippingRate
  );

  return (
    <PreviewContainer>
      <PreviewTitle message="Tract Pack" />
      <PreviewRule />
      {bulkTractOrders.map((order) => {
        const tract = findTract(order.tractId);
        return (
          <div key={order.tractId} className="u-flex u-flexAlignItemsCenter">
            <TractThumbnail
              key={order.tractId}
              className="removable"
              {...tract}
            >
              <button
                onClick={() => {
                  onRemoveTractPack(order.tractId);
                }}
                className="removable__button"
              />
            </TractThumbnail>
            <div className="preview-bulk-tract-order">
              <p>
                {tract.title}: {tract.subTitle}
              </p>
              <p className="u-price preview-bulk-tract-order__price">
                {order.quantity} x{" "}
                {currencyFormatter.format(order.price / order.quantity)} ={" "}
                {order.displayPrice}
              </p>
            </div>
          </div>
        );
      })}
      {!hasValidAddress ? (
        <h4 className="u-text-darkGreen">
          Shipping will calculate here after you enter a valid address
        </h4>
      ) : (
        shippingRatesState.status !== STATUS.IDLE && (
          <ShippingSelector
            idPrefix="tract-pack"
            onShippingChange={onShippingRateChange}
            shippingRatesState={shippingRatesState}
            shippingCost={tractPackShippingRate?.shipmentCost}
            shippingServiceType={tractPackShippingRate?.serviceType}
          />
        )
      )}
    </PreviewContainer>
  );
}
