import React from "react";
import Modal from "react-modal";
import YouTube from "react-youtube";
import { LightHeader } from "../../common/light-header";
import { StepProps } from "./interfaces";
import "./get-started.css";
import { STATUS } from "../../utils/use-fetch-hook";

export function GetStarted({ onNext, setTheme, productsState }: StepProps) {
  const [showNotification, setShowNotification] = React.useState(false);
  const [isViewingVideo, setIsViewingVideo] = React.useState(false);
  const stopViewingVideo = React.useCallback(() => {
    setIsViewingVideo(false);
  }, [setIsViewingVideo]);
  const startViewingVideo = React.useCallback(() => {
    setIsViewingVideo(true);
  }, [setIsViewingVideo]);

  React.useEffect(() => {
    setTheme(
      "url(/assets/gradient-bg.png) no-repeat center/cover",
      false,
      "var(--off-white)"
    );
  }, [setTheme]);

  const promotional =
    productsState.status === STATUS.DONE
      ? productsState.payload.promotional
      : null;

  React.useEffect(() => {
    if (promotional) {
      setShowNotification(true);
    }
  }, [promotional, setShowNotification]);

  const { innerWidth } = window;
  const isSmall = innerWidth < 768;
  const width = isSmall ? innerWidth : innerWidth * 0.67;
  const height = width / 1.7771;

  return (
    <>
      {showNotification && promotional !== null && (
        <div className="u-flex u-flexAlignItemsCenter notification">
          {promotional}&nbsp;
          <span onClick={onNext} style={{ textDecoration: "underline" }}>
            {" "}
            Get started
          </span>
          <button
            onClick={() => {
              setShowNotification(false);
            }}
            className="button--close u-flexExpandLeft"
          />
        </div>
      )}
      <LightHeader
        isCTA
        title={
          <h2>
            Mail the Gospel
            <div className="u-sm-hidden u-md-hidden" /> to Someone You Love
          </h2>
        }
        subTitle="Send it anonymously, with a personalized message, or with a Bible."
      />
      <div className="start-buttons">
        <button
          className="button button--large start-buttons__watch-the-film"
          onClick={startViewingVideo}
        >
          Watch Video
          <img
            className="u-paddingLeft2gu"
            src="/assets/play-icon.svg"
            alt="play button"
          />
        </button>
        <button
          className="button button--primary button--large"
          onClick={onNext}
        >
          Get Started
        </button>
      </div>
      <div className="home__mailbox">
        <img src="/assets/mail-box.svg" alt="mailbox" />
      </div>
      <Modal
        isOpen={isViewingVideo}
        onRequestClose={stopViewingVideo}
        style={{
          content: {
            display: "flex",
            flexDirection: "column",
            padding: 0,
            border: 0,
            width,
            height,
            margin: "auto",
            ...(isSmall ? { inset: 0 } : null),
          },
          overlay: {
            background: "rgba(82, 82, 82, 0.75)",
          },
        }}
        contentLabel="Mail the Gospel Introduction"
      >
        <YouTube
          videoId="XPRUfpXmmJQ"
          style={{ height: "100%" }}
          opts={{
            height: "100%",
            width: "100%",
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 1,
            },
          }}
        />
      </Modal>
    </>
  );
}
