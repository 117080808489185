import React from "react";
import { RecipientPayload } from "../../../../../server/src/contracts/recipient";
import { useBooleanHook } from "../../../utils/use-boolean-hook";
import { STATUS } from "../../../utils/use-fetch-hook";
import { useFileReader } from "../../../utils/use-file-reader";
import { FileInput, FILE_TYPE } from "../manage/file-input";
import { Table, TableHeader } from "../table";
import { useParseMailingList } from "./use-parse-mailing-list";

const recipientHeaders: TableHeader<RecipientPayload>[] = [
  {
    displayName: "First Name",
    key: "firstName",
  },
  {
    displayName: "Last Name",
    key: "lastName",
  },
  {
    displayName: "Address",
    key: "address1",
  },
  {
    displayName: "Address 2",
    key: "address2",
  },
  {
    displayName: "City",
    key: "city",
  },
  {
    displayName: "State",
    key: "state",
  },
  {
    displayName: "Zip",
    key: "zip",
  },
  {
    displayName: "Country",
    key: "country",
  },
];

interface ReadFileProps {
  onDone: (recipients: RecipientPayload[]) => void;
}
export function ReadFile({ onDone }: ReadFileProps) {
  const [csvFile, setCsvFile] = React.useState<File>();
  const fileContents = useFileReader(csvFile);
  const recipients = useParseMailingList(fileContents);
  const [showPreview, toggleShowPreview] = useBooleanHook(false);

  const handleNext = React.useCallback(() => {
    if (recipients.status === STATUS.DONE) {
      onDone(recipients.parsedRecipients.valid);
    }
  }, [recipients, onDone]);

  return (
    <>
      {fileContents.status === STATUS.ERROR && (
        <h3>Unable to read the selected file!</h3>
      )}
      {recipients.status === STATUS.ERROR && (
        <h3>Unable to parse addresses from the file!</h3>
      )}
      {recipients.status === STATUS.DONE ? (
        <>
          <h3 className="u-flex u-flexAlignItemsCenter">
            Found {recipients.parsedRecipients.valid.length} names!{" "}
            <button
              className="button button--secondary u-marginLeft2gu"
              onClick={toggleShowPreview}
            >
              {showPreview ? "Hide Preview" : "Show Preview"}
            </button>
          </h3>
          {showPreview && (
            <Table
              headers={recipientHeaders}
              idProp="id"
              rows={recipients.parsedRecipients.valid}
            />
          )}
          {recipients.parsedRecipients.skipped.length !== 0 && (
            <>
              <h4>Skipping the following:</h4>
              <Table
                headers={recipientHeaders}
                idProp="id"
                rows={recipients.parsedRecipients.skipped}
              />
            </>
          )}
        </>
      ) : (
        <FileInput
          accept={FILE_TYPE.CSV}
          id="csv-file"
          label="Upload CSV File"
          onChange={(newFile) => {
            setCsvFile(newFile);
          }}
          value={csvFile}
        />
      )}
      <button
        disabled={recipients.status !== STATUS.DONE}
        className="button button--primary button--large u-marginTop3gu u-flexAlignSelfEnd"
        onClick={handleNext}
      >
        Next
      </button>
    </>
  );
}
