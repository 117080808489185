import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { CSS_VARS } from "../../common/css-variables";
import { NavBar } from "../../common/nav-bar";
import { PageProps } from "../../interfaces";
import "./give.css";
import { stripePromise } from "../../utils/stripe";
import { LightHeader } from "../../common/light-header";
import { DonateForm } from "./donate-form";
import { STATUS, useFetchHook } from "../../utils/use-fetch-hook";
import {
  CreateDonationIntentRequest,
  CreateDonationIntentResponse,
} from "../../../../server/src/contracts/donate";
import { API } from "../../path";

const DONATION_AMOUNTS = [25, 50, 100];

const createDonationIntentRequest: CreateDonationIntentRequest = {
  donationAmount: DONATION_AMOUNTS[0],
};

export function Give({ setTheme }: PageProps) {
  React.useEffect(() => {
    setTheme(CSS_VARS["--off-white"], false);
  }, [setTheme]);
  const donationIntentState = useFetchHook<
    CreateDonationIntentRequest,
    CreateDonationIntentResponse
  >({
    method: "post",
    url: API.DONATE,
    body: createDonationIntentRequest,
  });

  return (
    <>
      <NavBar />
      <div className="give u-light">
        <LightHeader
          title="Help us mail the Gospel to millions"
          subTitle="Sponsor tracts, cover shipping, or pay for Bibles"
        />
        <div className="u-flex u-sm-flexCol u-sm-flexAlignItemsCenter u-sm-marginHoriz3gu">
          <div className="u-flex u-flexCol u-size1of2 u-sm-sizeFull">
            <div className="give__list">
              <div className="give__list-item">
                <div className="give__list-number">1</div>
                <div>
                  Help us cover the cost of tracts by committing to cover the
                  printing cost for a particular quantity. Each tract costs
                  roughly <b>$2.00</b> each.
                </div>
              </div>
              <div className="give__list-item">
                <div className="give__list-number">2</div>
                <div>
                  Help us cover the cost of shipping. Each Gospel sent costs
                  roughly <b>$5</b>
                </div>
              </div>
              <div className="give__list-item">
                <div className="give__list-number">3</div>
                <div>
                  Help us cover the costs of Bibles. Each Bible costs roughly{" "}
                  <b>$10</b>.
                </div>
              </div>
            </div>
            <div className="give__ways">
              <h3>Ways to Give</h3>
              <p>
                <strong>
                  Mail The Gospel is a companion ministry of{" "}
                  <a href="http://relearn.org" className="u-text-black">
                    Relearn.org
                  </a>
                  .
                </strong>
              </p>

              <p>
                Due to the nature of this donation, we cannot offer refunds, but
                you may cancel your recurring donation at any time.
              </p>
              <p>
                <strong>Donations by check</strong> can be made out to
                “Relearn.org” and mailed to: 2675 West SR 89A #180 Sedona,
                Arizona 86336. Relearn.org is a 501(c)3 Non-Profit EIN#
                82-3784161
              </p>
            </div>
          </div>
          {donationIntentState.status === STATUS.DONE && (
            <Elements
              stripe={stripePromise}
              options={{
                clientSecret: donationIntentState.payload.clientSecret,
              }}
            >
              <DonateForm
                donationAmounts={DONATION_AMOUNTS}
                paymentIntentId={donationIntentState.payload.paymentIntentId}
              />
            </Elements>
          )}
          {donationIntentState.status === STATUS.ERROR && (
            <h3>Donating is currently unavailable.</h3>
          )}
        </div>
      </div>
    </>
  );
}
