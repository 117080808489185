import React from "react";
import { Statement } from "./statements";

interface StatementCardProps {
  statement: Statement;
}

const previewLength = 244;

export function StatementCard({
  statement: { title, preview, full },
}: StatementCardProps): React.ReactElement {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = React.useCallback(() => {
    setIsOpen((o) => !o);
  }, [setIsOpen]);

  return (
    <div className="about-page__sof-card">
      <h4>{title}</h4>
      <p>
        {isOpen ? preview : preview.substring(0, previewLength)}
        {!isOpen && preview.length > 244 && "..."}
      </p>
      {isOpen === true && full}
      <button
        style={isOpen ? undefined : { marginTop: 50 }}
        onClick={toggleOpen}
      >
        Read {isOpen ? "Less" : "More"}
      </button>
    </div>
  );
}
