import React from "react";
import "./team-member.css";

interface TeamMember {
  name: string;
  role: string;
  about: string;
  imageUrl: string;
}

const teamMembers: TeamMember[] = [
  {
    name: "Dale Partridge",
    role: "Founder",
    about:
      "Dale Partridge is the President of Relearn.org and Reformation Seminary. Dale conducted his graduate studies at The Master's Seminary and Grace Bible Theological Seminary. He is the author of several Christian books, the host of the Real Christianity podcast, and the teaching pastor at Reformation Fellowship in Sedona, Arizona.",
    imageUrl: "assets/team-members/dale-partridge.jpg",
  },
  {
    name: "Dr. Jason Barker",
    role: "Theological Advisor",
    about:
      "Jason is the Academic Dean at Reformation Seminary and received his D.Min from New Orleans Baptist Theological Seminary. Jason is an experienced pastor and theological educator who serves as an adjunct professor at several respected Christian institutions. ",
    imageUrl: "assets/team-members/dr-jason-barker.png",
  },
  {
    name: "Dr. Dustin Benge",
    role: "Theological Advisor",
    about:
      "Dustin earned His Ph.D. from The Southern Baptist Theological Seminary. He is now the Provost and professor of church history and preaching at Union School of Theology in Wales and Oxford.",
    imageUrl: "assets/team-members/dr-dustin-benge.png",
  },
  {
    name: "Dr. Anthony Wood",
    role: "Theological Advisor",
    about:
      "Anthony has pastored at Mission Bible Church since 2010. He completed his doctorate at The Master's Seminary. He is married to his wife, Bre, and they have three children.",
    imageUrl: "assets/team-members/dr-anthony-wood.png",
  },
  {
    name: "Dr. Owen Strachan",
    role: "Theological Advisor",
    about:
      "Owen is Provost and Research Professor of Theology at Grace Bible Theological Seminary. He earned his Ph.D from Trinity Evangelical Divinity School and has authored numerous Christian books. ",
    imageUrl: "assets/team-members/dr-owen-strachan.jpg",
  },
  {
    name: "Albert Kilgore",
    role: "Theological Advisor",
    about:
      "Albert is the Lead Pastor at Mission Bible Church in Phoenix. He is completing his M.Div. from Midwestern Baptist Theological Seminary. ",
    imageUrl: "assets/team-members/albert-kilgore.png",
  },
];

export function TeamMembers() {
  return (
    <div className="team-member">
      {teamMembers.map(({ name, role, about, imageUrl }) => (
        <div key={imageUrl} className="team-member__container">
          <div className="team-member__image-container">
            <img
              src={imageUrl}
              alt={`${name}'s face`}
              style={{ borderRadius: 10 }}
              className="team-member__image"
            />
          </div>
          <h4 className="team-member__name">{name}</h4>
          <p className="team-member__role">{role}</p>
          <p className="team-member__about">{about}</p>
        </div>
      ))}
    </div>
  );
}
