import React from "react";
import { CustomLiteratureStats } from "../../../../server/src/contracts/custom-literature";
import { API } from "../../path";
import { STATUS, useFetchHook } from "../../utils/use-fetch-hook";
import { Table } from "./table";

export function CustomLiteratureTable() {
  const customLiteratureState = useFetchHook<null, CustomLiteratureStats[]>({
    method: "get",
    url: API.CUSTOM_LITERATURE,
  });

  switch (customLiteratureState.status) {
    case STATUS.IDLE:
    case STATUS.LOADING: {
      return <h3>Loading</h3>;
    }
    case STATUS.ERROR: {
      return (
        <>
          <h3>Error!</h3>
          {customLiteratureState.error.toString
            ? customLiteratureState.error.toString()
            : "Unable to load custom literature"}
        </>
      );
    }
    case STATUS.DONE: {
      return (
        <div className="u-flex u-flexCol u-sizeFull u-flexGrow1 u-textLeft u-margin3gu">
          <h4 className="u-margin3gu">Custom Literature</h4>
          <Table
            idProp="number"
            rows={customLiteratureState.payload}
            headers={[
              { displayName: "Number", key: "number" },
              { displayName: "Name", key: "name" },
              {
                displayName: "Total Transactions",
                key: "totalTransactionCount",
              },
              { displayName: "Paid Transactions", key: "paidTransactionCount" },
              {
                displayName: "Pending Transactions",
                key: "pendingTransactionCount",
              },
            ]}
          />
        </div>
      );
    }
  }
}
