import React from "react";
import { AddOn } from "../../../home/interfaces";
import { Table, TableHeader } from "../../table";

interface AddOnTableProps {
  addOns: AddOn[];
  onEdit: (addOnId: string) => void;
  onDelete: (addOnId: string) => void;
}

export function AddOnTable({
  addOns,
  onDelete,
  onEdit,
}: AddOnTableProps): React.ReactElement {
  const Action = React.useCallback(
    ({ data: addOn }: { data: AddOn }) => {
      return (
        <>
          <button
            className="button button--small button--alert"
            onClick={() => {
              onDelete(addOn.id);
            }}
          >
            Delete
          </button>
          <button
            className="button button--small button--primary"
            onClick={() => {
              onEdit(addOn.id);
            }}
          >
            Edit
          </button>
        </>
      );
    },
    [onDelete, onEdit],
  );
  const headers: Array<TableHeader<AddOn>> = [
    { key: "title", displayName: "Title" },
    { key: "description", displayName: "Description" },
    { key: "abbreviation", displayName: "Abbreviation" },
    { key: "displayPrice", displayName: "Price" },
    { key: "id", displayName: "Actions", Component: Action },
  ];
  return <Table rows={addOns} headers={headers} idProp="id" />;
}
