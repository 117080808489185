import jsPdf from "jspdf";
import { Order } from "../../../../../server/src/contracts/orders";
import "./GreycliffCF-Bold-bold";

const WIDTH = 4;
const HEIGHT = 6;

const CENTER_X = WIDTH / 2;

const IMAGE_WIDTH = 0.5;
const IMAGE_HEIGHT = IMAGE_WIDTH * (2 / 3);

const MARGIN = 0.5;
const TEXT_START_Y = 1.35;

const LINE_Y = HEIGHT - 1.2;
const PREVIEW_START_Y = HEIGHT - HEIGHT / 3;
const FOOTER_HEIGHT = HEIGHT - 0.8;

const MAX_LINE_LENGTH = 45;

function formatMessage(message: string): string {
  return message
    .split("\n")
    .reduce((lines, line) => {
      if (line.length <= MAX_LINE_LENGTH) {
        lines.push(line);
      } else {
        const newLines = line.split(" ").reduce((newLines, word) => {
          const prevIndex = newLines.length - 1;
          if (
            prevIndex === -1 ||
            newLines[prevIndex].length + word.length + 1 >= MAX_LINE_LENGTH
          ) {
            newLines.push(word);
          } else {
            newLines[prevIndex] += ` ${word}`;
          }
          return newLines;
        }, [] as string[]);
        lines.push(...newLines);
      }
      return lines;
    }, [] as string[])
    .join("\n");
}

function drawMessage(doc: jsPdf, message: string, orderNumber?: string) {
  const logo = new Image();
  logo.src = "/assets/mtg-logo.png";
  doc.addImage(
    logo,
    "PNG",
    CENTER_X - IMAGE_WIDTH / 2,
    MARGIN,
    IMAGE_WIDTH,
    IMAGE_HEIGHT,
    undefined,
    "NONE"
  );

  doc.setFont("Georgia");
  doc.setFontSize(11);

  doc.text(formatMessage(message), MARGIN, TEXT_START_Y, { maxWidth: 44 });

  doc.text("_".repeat(38), MARGIN, LINE_Y);

  doc.setFontSize(13);
  doc.setFont("GreycliffCF-Bold", "bold");
  doc.text("MailTheGospel.org", 1.3, FOOTER_HEIGHT);

  if (orderNumber) {
    doc.setFont("Georgia", "regular");
    doc.setFontSize(10);
    doc.text(`Order #: ${orderNumber}`, 1.5, HEIGHT - MARGIN);
  } else {
    doc.setFont("Times New Roman");
    doc.setFontSize(45);
    doc.setTextColor("gray");
    doc.text("PREVIEW", MARGIN, PREVIEW_START_Y);
    doc.setFontSize(9);
    doc.text(
      "We will print this and include it with the tract!",
      0.7,
      PREVIEW_START_Y + 0.2
    );
  }
}

function createDoc(): jsPdf {
  return new jsPdf({
    orientation: "portrait",
    unit: "in",
    format: [WIDTH, HEIGHT],
  });
}

export function createBulkMessage(orders: Order[]): URL {
  const doc = createDoc();

  orders
    .filter((order) => Boolean(order.personalizedMessage))
    .forEach((order, index, total) => {
      drawMessage(doc, order.personalizedMessage || "", order.number);
      if (index !== total.length - 1) {
        doc.addPage();
      }
    });

  return doc.output("bloburl");
}

export function createPersonalizedMessage(
  message: string,
  orderNumber?: string
): URL {
  const doc = createDoc();

  drawMessage(doc, message, orderNumber);

  return doc.output("bloburl");
}
