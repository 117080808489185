import React from "react";
import { RecipientPayload } from "../../../../server/src/contracts/recipient";
import { RecipientUpdate } from "../../interfaces";
import { COUNTRY } from "./country-select";
import { countryOptions } from "./state-select";

function useTextInputHook({
  recipientKey,
  onChange,
  recipientId,
  tractId,
}: {
  recipientKey: keyof RecipientPayload;
  onChange: (recipientUpdate: RecipientUpdate) => void;
  recipientId: string;
  tractId: string;
}): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = React.useCallback(
    (e) => {
      onChange({
        recipientId,
        tractId,
        partialRecipient: {
          [recipientKey]: e.target.value,
        },
      });
    },
    [onChange, recipientKey, recipientId, tractId]
  );

  return handleChange;
}

export const MESSAGE_LIMIT = 380;

export function useEditRecipientHook({
  tractId,
  recipientId,
  onChange,
}: {
  tractId: string;
  recipientId: string;
  onChange: (recipientUpdate: RecipientUpdate) => void;
}) {
  const handleStateChange = React.useCallback(
    (newState: string) => {
      onChange({ recipientId, tractId, partialRecipient: { state: newState } });
    },
    [onChange, tractId, recipientId]
  );

  const handleCountryChange = React.useCallback(
    (newCountry: string) => {
      onChange({
        recipientId,
        tractId,
        partialRecipient: {
          country: newCountry,
          // reset state when country changes
          state: countryOptions[newCountry as COUNTRY][0],
        },
      });
    },
    [onChange, tractId, recipientId]
  );

  const handleFirstNameChange = useTextInputHook({
    recipientKey: "firstName",
    onChange,
    recipientId,
    tractId,
  });
  const handleLastNameChange = useTextInputHook({
    recipientKey: "lastName",
    onChange,
    recipientId,
    tractId,
  });
  const handleAddress1Change = useTextInputHook({
    recipientKey: "address1",
    onChange,
    recipientId,
    tractId,
  });
  const handleAddress2Change = useTextInputHook({
    recipientKey: "address2",
    onChange,
    recipientId,
    tractId,
  });
  const handleCityChange = useTextInputHook({
    recipientKey: "city",
    onChange,
    recipientId,
    tractId,
  });
  const handleZipChange = useTextInputHook({
    recipientKey: "zip",
    onChange,
    recipientId,
    tractId,
  });
  const handlePersonalizedMessage = useTextInputHook({
    recipientKey: "personalizedMessage",
    onChange,
    recipientId,
    tractId,
  });
  const setHowToSend = React.useCallback(
    (newHowToSend: boolean) => {
      onChange({
        recipientId,
        tractId,
        partialRecipient: { hasPersonalizedMessage: newHowToSend },
      });
    },
    [onChange, recipientId, tractId]
  );

  const setPersonalized = React.useCallback(() => {
    setHowToSend(true);
  }, [setHowToSend]);

  const setAnonymous = React.useCallback(() => {
    setHowToSend(false);
  }, [setHowToSend]);

  return {
    handleAddress1Change,
    handleAddress2Change,
    handleCityChange,
    handleFirstNameChange,
    handleLastNameChange,
    handlePersonalizedMessage,
    handleStateChange,
    handleCountryChange,
    handleZipChange,
    setPersonalized,
    setAnonymous,
  };
}
