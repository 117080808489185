import React from "react";
import { DonationDTO } from "../../../../../server/src/contracts/donate";
import { Table, TableHeader } from "../table";
import { ViewDate } from "../view-date";

const headers: TableHeader<DonationDTO>[] = [
  {
    displayName: "Date",
    key: "date",
    Component: function DonationDate({ data }) {
      return <ViewDate date={data.date} />;
    },
  },
  { displayName: "Amount", key: "displayAmount" },
];

export function DonationTable({ donations }: { donations: DonationDTO[] }) {
  return <Table headers={headers} idProp="id" rows={donations} />;
}
