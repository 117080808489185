import React from "react";
import {
  CreatePaymentIntentResponse,
  OrderInformation,
} from "../../../../server/src/contracts/payment-intent";
import { Tract } from "../../../../server/src/contracts/tract";
import { PageProps } from "../../interfaces";
import { State } from "../../utils/use-fetch-hook";

export enum STEP {
  GET_STARTED = "",
  WHO_IS_MAILING = "who-is-mailing",
  CHOOSE_YOUR_TRACT = "choose-your-tract",
  WHO_IS_RECEIVING = "who-is-receiving",
}

export interface AddOn {
  id: string;
  title: string;
  description: string;
  price: number;
  displayPrice: string;
  abbreviation: string;
  ounces: number;
}

export interface Products {
  tracts: Tract[];
  addOns: AddOn[];
  promotional: string | null;
}

export interface StepProps
  extends Pick<PageProps, "setTheme" | "onRecipientUpdate"> {
  onNext: () => void;
  onPrev: () => void;
  onRestart: () => void;
  productsState: State<null, Products>;
  orderInformation: OrderInformation;
  setOrderInformation: React.Dispatch<React.SetStateAction<OrderInformation>>;
  paymentIntent: CreatePaymentIntentResponse | null;
  setPaymentIntent: React.Dispatch<
    React.SetStateAction<CreatePaymentIntentResponse | null>
  >;
}
