import React from "react";
import { Link } from "react-router-dom";
import { OrderInformation } from "../../../../server/src/contracts/payment-intent";
import { Tract } from "../../../../server/src/contracts/tract";
import { CSS_VARS } from "../../common/css-variables";
import { FooterNav } from "../../common/footer-nav";
import { NavBar } from "../../common/nav-bar";
import { PageProps } from "../../interfaces";
import { API, PATH } from "../../path";
import { STATUS, useFetchHook } from "../../utils/use-fetch-hook";
import { TractSelector } from "./tract-selector";

function ShoppingCartLink({
  orderInformation,
}: {
  orderInformation: OrderInformation;
}) {
  const gospelCount = orderInformation.tracts.length;
  const bulkTractOrderCount = orderInformation.bulkTractOrders.length;

  const cartCount = gospelCount + bulkTractOrderCount;

  return (
    <Link to={PATH.ORDER_REVIEW}>
      <div style={{ position: "relative" }}>
        <img
          src="/assets/shopping-cart.png"
          alt="Checkout"
          style={{ maxHeight: 30 }}
        />
        {cartCount !== 0 && (
          <div
            style={{
              position: "absolute",
              top: -5,
              right: -10,
              backgroundColor: "white",
              padding: 5,
              borderRadius: "100%",
              height: 20,
              width: 20,
              display: "flex",
              justifyContent: "center",
              fontSize: 10,
              alignItems: "center",
            }}
          >
            {cartCount}
          </div>
        )}
      </div>
    </Link>
  );
}

export function Pack({
  setTheme,
  orderInformation,
  setOrderInformation,
}: PageProps) {
  const tractState = useFetchHook<null, Tract[]>({
    method: "get",
    url: API.TRACT,
  });
  React.useEffect(() => {
    setTheme(CSS_VARS["--dark-green"], true, "", true);
  }, [setTheme]);

  return (
    <>
      <NavBar
        hasWhiteText
        customLinks={<ShoppingCartLink orderInformation={orderInformation} />}
      />
      <div
        className="u-flex u-flexCol u-flexAlignItemsCenter u-textCenter"
        style={{
          margin: "auto",
          overflow: "hidden",
          minHeight: "calc(100vh - 155px)",
        }}
      >
        {tractState.status === STATUS.DONE ? (
          <TractSelector
            setTheme={setTheme}
            tracts={tractState.payload}
            orderInformation={orderInformation}
            setOrderInformation={setOrderInformation}
          />
        ) : tractState.status === STATUS.ERROR ? (
          <h3 className="u-text-white">Unable to load tracts!</h3>
        ) : (
          <h3 className="u-text-white">Loading tracts...</h3>
        )}
      </div>
      <FooterNav
        isReadyToGoNext
        onNext={() => {}}
        continueButton={
          <Link to={PATH.ORDER_REVIEW}>
            <button className="button button--primary button--large u-sm-sizeFull">
              Continue
            </button>
          </Link>
        }
      />
    </>
  );
}
