import React from "react";
import ReactDOM from "react-dom";

import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { AnalyticsProvider } from "use-analytics";
import Modal from "react-modal";
import "suitcss-utils-flex";
import "suitcss-utils-size";
import "suitcss-utils-text";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./theme.css";
import App from "./app";
import { BrowserRouter } from "react-router-dom";

const containerId = "root";

Modal.setAppElement(`#${containerId}`);

const plugins: Record<string, unknown>[] = [];
if (process.env.REACT_APP_UNIVERSAL_ANALYTICS) {
  plugins.push(
    googleAnalytics({
      trackingId: process.env.REACT_APP_UNIVERSAL_ANALYTICS,
    })
  );
} else {
  plugins.push({
    name: "my-custom-plugin",
    page: ({ payload }: { payload: unknown }) => {
      console.log("page view fired", payload);
    },
    track: ({ payload }: { payload: unknown }) => {
      console.log("track event payload", payload);
    },
  });
}

const analytics = Analytics({ app: "Mail the Gospel", plugins });

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById(containerId)
);
