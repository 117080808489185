import React from "react";
import { Wrapper, Section, useLightTheme } from "../home/light-theme";
import { SummaryTitle } from "../../common/summary-title";
import { TotalSummary } from "../home/total-summary";
import { DonationAmount } from "../../common/donation-amount";
import { STATUS } from "../../utils/use-fetch-hook";
import {
  OrderInformation,
  TractOrder,
} from "../../../../server/src/contracts/payment-intent";
import { RecipientPreview } from "./recipient-preview";
import { PreviewBulkTractOrders } from "./preview-bulk-tract-orders";
import { AddressForm } from "./address-form";
import { PageProps } from "../../interfaces";
import { useGetProductsHook } from "../../common/product-service";
import { Link } from "react-router-dom";
import { PATH } from "../../path";
import { withFullPageRibbon } from "../../common/full-page";
import { isReadyForCheckout } from "../../common/order-validation";
import { CustomerInfoInput } from "./customer-info-input";
import { EmptyCartSection } from "./empty-cart-section";
import { MailTheGospelPrompt } from "./mail-the-gospel-prompt";
import { TractPackPrompt } from "./tract-pack-prompt";
import { Box } from "./box";
import { ShippingRate } from "../../../../server/src/contracts/shipping";

const DONATION_AMOUNTS = [5, 10, 20];

function OrderReviewBase({
  setTheme,
  onRecipientUpdate,
  orderInformation,
  setOrderInformation,
}: PageProps) {
  useLightTheme(setTheme);
  const productsState = useGetProductsHook();

  const handleRemoveRecipient = React.useCallback(
    (tractId: string, recipientId: string) => {
      setOrderInformation((prev) => {
        const tractOrders = prev.tracts.reduce((acc, tractOrder) => {
          if (tractOrder.tractId === tractId) {
            tractOrder.recipients = tractOrder.recipients.filter(
              (recipient) => recipientId !== recipient.id
            );

            if (tractOrder.recipients.length > 0) {
              acc.push(tractOrder);
            }
          } else {
            acc.push(tractOrder);
          }
          return acc;
        }, [] as TractOrder[]);

        const initialTractId = tractOrders.some(
          (tractOrder) => tractOrder.tractId === prev.initialTractId
        )
          ? prev.initialTractId
          : tractOrders.length !== 0
          ? tractOrders[0].tractId
          : null;

        return {
          ...prev,
          tracts: tractOrders,
          initialTractId,
        };
      });
    },
    [setOrderInformation]
  );

  const handleDonationChange = React.useCallback(
    (donation: OrderInformation["donation"]) => {
      setOrderInformation((prev) => ({ ...prev, donation }));
    },
    [setOrderInformation]
  );

  const handleShippingRateChange = React.useCallback(
    (shippingRate: ShippingRate | null) => {
      setOrderInformation((o) => ({
        ...o,
        tractPackShippingRate: shippingRate,
      }));
    },
    [setOrderInformation]
  );

  const handleTractPackRemove = React.useCallback(
    (tractId: string) => {
      setOrderInformation((o) => {
        const bulkTractOrders = o.bulkTractOrders.filter(
          (tract) => tract.tractId !== tractId
        );
        const tractPackShippingRate =
          bulkTractOrders.length > 0 ? o.tractPackShippingRate : null;

        return {
          ...o,
          bulkTractOrders,
          tractPackShippingRate,
        };
      });
    },
    [setOrderInformation]
  );

  const handleAddressChange = React.useCallback(
    (newShippingAddress) => {
      setOrderInformation((o) => ({
        ...o,
        shippingAddress: {
          ...o.shippingAddress,
          ...newShippingAddress,
        },
      }));
    },
    [setOrderInformation]
  );

  const products =
    productsState.status === STATUS.DONE ? productsState.payload : undefined;

  if (productsState.status === STATUS.LOADING) {
    return <h3 className="u-text-offWhite step-header">Loading tracts...</h3>;
  }

  if (productsState.status === STATUS.ERROR || !products) {
    return (
      <h3 className="u-text-offWhite step-header">
        Uh oh! Something went wrong!
      </h3>
    );
  }

  const hasTractOrders = orderInformation.tracts.length !== 0;
  const hasBulkTractOrders = orderInformation.bulkTractOrders.length !== 0;
  const isEmptyCart = !hasTractOrders && !hasBulkTractOrders;

  if (isEmptyCart) {
    return <EmptyCartSection />;
  }

  return (
    <Wrapper>
      <Section isRounded>
        <h3 className="u-marginBottom6gu">Your Cart</h3>
        <>
          {hasBulkTractOrders && (
            <PreviewBulkTractOrders
              tracts={products?.tracts || []}
              orderInformation={orderInformation}
              onShippingRateChange={handleShippingRateChange}
              onRemoveTractPack={handleTractPackRemove}
            />
          )}
          {!hasTractOrders ? (
            <div className="u-flex u-flexCol u-flexJustifyCenter u-flexAlignItemsCenter u-textCenter u-marginTop6gu">
              <MailTheGospelPrompt />
            </div>
          ) : (
            orderInformation.tracts.map((tractOrder): React.ReactElement => {
              const tract = products.tracts.find(
                ({ id }) => tractOrder.tractId === id
              );
              if (!tract) {
                return <></>;
              }

              return (
                <React.Fragment key={tractOrder.tractId}>
                  {tractOrder.recipients.map((recipient) => (
                    <RecipientPreview
                      key={recipient.id}
                      onRecipientUpdate={onRecipientUpdate}
                      onRemoveRecipient={handleRemoveRecipient}
                      products={products}
                      recipient={recipient}
                      tract={tract}
                      tractOrder={tractOrder}
                    />
                  ))}
                </React.Fragment>
              );
            })
          )}
        </>
      </Section>
      <Section isRounded isSmallSquare>
        <h3 className="u-sm-hidden u-marginBottom6gu">Summary</h3>
        {hasBulkTractOrders ? (
          <>
            <CustomerInfoInput
              orderInformation={orderInformation}
              setOrderInformation={setOrderInformation}
            />
            <Box>
              <SummaryTitle>Your Mailing Address</SummaryTitle>
              <AddressForm
                initialAddress={orderInformation.shippingAddress}
                onAddressChange={handleAddressChange}
              />
            </Box>
          </>
        ) : (
          <Box>
            <TractPackPrompt bulkPrices={products.tracts[0].bulkPrices} />
          </Box>
        )}
        <Box>
          <SummaryTitle>Support Mail the Gospel</SummaryTitle>
          <p>
            Make a donation that will help cover the cost of tracts, Bibles, and
            shipping.
          </p>
          <DonationAmount
            amounts={DONATION_AMOUNTS}
            donation={orderInformation.donation}
            onChange={handleDonationChange}
          />
        </Box>
        <div className="rule rule--extra-thick rule--gray u-marginVert3gu" />
        <TotalSummary orderInformation={orderInformation} products={products} />

        <Link to={PATH.CHECKOUT}>
          <button
            disabled={!isReadyForCheckout(orderInformation)}
            className="button button--primary button--large u-sizeFull u-marginTop3gu"
          >
            Checkout
          </button>
        </Link>
      </Section>
    </Wrapper>
  );
}

export const OrderReview = withFullPageRibbon(
  OrderReviewBase,
  PATH.ORDER_REVIEW
);
