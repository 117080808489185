import React from "react";
import "./tabs.css";

interface TabInfo<Props> {
  title: string;
  Component: React.ComponentType<Props>;
}

interface TabContainerProps<Props> {
  tabs: TabInfo<Props>[];
  tabProps: Props;
}

export function TabContainer<Props>({
  tabs,
  tabProps,
}: TabContainerProps<Props>): React.ReactElement {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const ActiveTab = tabs[activeIndex].Component;

  return (
    <div className="tabs">
      <div className="u-flex">
        {tabs.map(({ title }, index) => (
          <button
            key={title}
            className={`tabs__tab${
              activeIndex === index ? " tabs__tab--active" : ""
            }`}
            onClick={() => {
              if (activeIndex !== index) {
                setActiveIndex(index);
              }
            }}
          >
            {title}
          </button>
        ))}
      </div>
      <ActiveTab {...tabProps} />
    </div>
  );
}
