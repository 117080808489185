import React from "react";
import { RecipientPayload } from "../../../../../server/src/contracts/recipient";
import { isAddressValid } from "../../../utils/address";
import { STATUS } from "../../../utils/use-fetch-hook";
import { FileReaderOutput } from "../../../utils/use-file-reader";
import { ParsedRecipients } from "./types";

type MailingListOutput =
  | { status: STATUS.IDLE }
  | { status: STATUS.LOADING }
  | { status: STATUS.DONE; parsedRecipients: ParsedRecipients }
  | { status: STATUS.ERROR; error: Error };

function parseHeaders(
  text: string
): Record<
  keyof Pick<
    RecipientPayload,
    "firstName" | "lastName" | "state" | "zip" | "city" | "address1"
  >,
  number
> {
  const headers = text.split(",");
  const indices = headers.reduce(
    (acc, header, index) => {
      const headerName = header.toLowerCase();
      if (/first/.test(headerName)) {
        acc.firstName = index;
      }
      if (/last/.test(headerName)) {
        acc.lastName = index;
      }
      if (/^st(ate)?$/.test(headerName)) {
        acc.state = index;
      }
      if (/^zip$/.test(headerName)) {
        acc.zip = index;
      }
      if (/^city$/.test(headerName)) {
        acc.city = index;
      }
      if (/address1?$/.test(headerName)) {
        acc.address1 = index;
      }
      return acc;
    },
    {
      firstName: -1,
      lastName: -1,
      state: -1,
      zip: -1,
      city: -1,
      address1: -1,
    }
  );
  Object.entries(indices).forEach(([key, value]) => {
    if (value === -1) {
      throw new Error(`Unable to find header for "${key}"`);
    }
  });

  return indices;
}

let count = 1;
function getId(index: number): string {
  return `${index}-${count++}`;
}

function isRecipientValid(recipient: RecipientPayload): boolean {
  return Boolean(
    recipient.firstName && recipient.lastName && isAddressValid(recipient)
  );
}

function parseMailingList(text: string): ParsedRecipients {
  const [headers, ...data] = text.split(/\r?\n/);
  const indices = parseHeaders(headers);
  return data.reduce(
    (acc, row, index) => {
      const parts = row.split(",");
      if (row.trim().length === 0) {
        return acc;
      }
      const recipient = {
        country: "US",
        address1: parts[indices.address1],
        address2: "",
        city: parts[indices.city],
        state: parts[indices.state],
        zip: parts[indices.zip],
        // add one to compensate for `headers` being destructured already, add one more to make 1-indexed
        id: getId(index + 2),
        firstName: parts[indices.firstName],
        lastName: parts[indices.lastName],
        hasPersonalizedMessage: false,
        personalizedMessage: "",
        addOnId: null,
        shippingServiceType:
          "usps_media_mail" as RecipientPayload["shippingServiceType"],
        shippingCost: 5,
        displayShippingCost: "$5.00",
      };
      if (isRecipientValid(recipient)) {
        acc.valid.push(recipient);
      } else {
        acc.skipped.push(recipient);
      }
      return acc;
    },
    {
      valid: [] as RecipientPayload[],
      skipped: [] as RecipientPayload[],
    } as ParsedRecipients
  );
}

export function useParseMailingList(
  fileStatus: FileReaderOutput
): MailingListOutput {
  const [parsedRecipients, setParsedRecipients] = React.useState({
    valid: [] as RecipientPayload[],
    skipped: [] as RecipientPayload[],
  } as ParsedRecipients);
  const [parsingError, setParsingError] = React.useState<Error | null>(null);

  React.useEffect(() => {
    if (fileStatus.status === STATUS.DONE) {
      try {
        const parsedRecipients = parseMailingList(fileStatus.result);
        setParsedRecipients(parsedRecipients);
      } catch (e) {
        setParsingError(
          e instanceof Error
            ? e
            : new Error(
                (e as Object).toString ? (e as Object).toString() : String(e)
              )
        );
      }
    }
  }, [fileStatus]);

  if (parsingError) {
    debugger;
    return {
      status: STATUS.ERROR,
      error: parsingError,
    };
  }

  if (fileStatus.status === STATUS.DONE) {
    return {
      status: STATUS.DONE,
      parsedRecipients,
    };
  } else {
    return fileStatus;
  }
}
