import React from "react";
import { Donation } from "../../../server/src/contracts/payment-intent";

interface DonationAmountProps {
  amounts: number[];
  donation: Donation | null;
  hasDarkBg?: boolean;
  onChange: (newDonation: Donation | null) => void;
}

export function DonationAmount({
  amounts,
  donation,
  hasDarkBg,
  onChange,
}: DonationAmountProps) {
  const [, setCustomDonation] = React.useState(() =>
    donation?.isCustom ? String(donation.amount) : ""
  );
  const handleCustomDonationChange = React.useCallback(
    (e) => {
      const donationParsed = +e.target.value;
      const amount = Number.isNaN(donationParsed) ? 0 : donationParsed;
      onChange({ amount, isCustom: true });
      setCustomDonation(e.target.value);
    },
    [onChange, setCustomDonation]
  );

  return (
    <div className="u-flex u-sm-flexCol">
      {amounts.map((amount) => {
        const isSelected =
          donation && donation.amount === amount && !donation.isCustom;
        return (
          <button
            key={amount}
            onClick={() => {
              if (isSelected) {
                onChange(null);
              } else {
                onChange({ amount, isCustom: false });
              }
            }}
            className={`donate-amount u-size1of4 u-sm-sizeFull u-marginRight2gu u-sm-marginRight0 u-sm-marginVert2gu u-sm-paddingVert2gu u-price u-flex u-flexAlignItemsCenter u-flexJustifyCenter${
              isSelected ? " donate-amount--selected" : ""
            }${hasDarkBg ? " donate-amount--dark-bg" : ""}`}
          >
            $ {amount}
          </button>
        );
      })}
      <div
        className={`donate-amount u-size1of4 u-sm-sizeFull u-marginRight2gu u-sm-marginRight0 u-sm-marginVert2gu u-paddingLeft2gu u-sm-paddingLeft0 u-sm-paddingVert2gu u-price u-flex u-flexAlignItemsCenter u-flexJustifyCenter${
          donation?.isCustom ? " donate-amount--selected" : ""
        }${hasDarkBg ? " donate-amount--dark-bg" : ""}`}
      >
        <label htmlFor="donate-custom-amount">$</label>
        <input
          type="text"
          className={`donate-amount__other u-price${
            donation?.isCustom ? " donate-amount__other--selected" : ""
          }${hasDarkBg ? " donate-amount__other--dark-bg" : ""}`}
          id="donate-custom-amount"
          name="donate-custom-amount"
          placeholder="Other"
          onFocus={handleCustomDonationChange}
          onChange={handleCustomDonationChange}
        />
      </div>
    </div>
  );
}
