import React from "react";
import { TransactionDTO } from "../../../../../server/src/contracts/transaction";
import { Table, TableHeader } from "../table";
import { ViewDate } from "../view-date";

const headers: TableHeader<TransactionDTO>[] = [
  {
    displayName: "Date",
    key: "date",
    Component: function TransactionDate({ data }) {
      return <ViewDate date={data.date} />;
    },
  },
  { displayName: "Status", key: "status" },
  { displayName: "Donation", key: "displayDonation" },
  { displayName: "Sub Total", key: "displaySubTotal" },
  { displayName: "Shipping", key: "displayShipping" },
  { displayName: "Tax", key: "displayTax" },
  { displayName: "Total", key: "displayTotal" },
];

export function TransactionTable({
  transactions,
}: {
  transactions: TransactionDTO[];
}) {
  return <Table headers={headers} idProp="id" rows={transactions} />;
}
