import React from "react";
import Modal from "react-modal";
import { Order } from "../../../../../server/src/contracts/orders";
import { createBulkMessage } from "../../home/who-is-receiving/create-personalized-message";

interface BulkMessageModalProps {
  orders: Order[];
  onRequestClose: () => void;
}

export function BulkMessageModal({
  orders,
  onRequestClose,
}: BulkMessageModalProps): JSX.Element {
  const [err, setError] = React.useState(false);
  const [blobUrl, setBlobUrl] = React.useState<URL | null>(null);

  React.useEffect(() => {
    const newBlobUrl = createBulkMessage(orders);
    if (newBlobUrl) {
      setBlobUrl(newBlobUrl);
    } else {
      setError(true);
    }
  }, [orders]);

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      style={{
        content: {
          display: "flex",
          flexDirection: "column",
          padding: 0,
          border: 0,
          width: window.innerWidth * 0.8,
          height: window.innerHeight * 0.8,
          margin: "0 auto",
        },
        overlay: {
          zIndex: 2,
          background: "rgba(82, 82, 82, 0.75)",
        },
      }}
      contentLabel="Personalized Message Preview"
    >
      {blobUrl ? (
        <iframe
          src={blobUrl.toString()}
          title="Personalize Message Preview"
          height="100%"
        />
      ) : (
        <h1>{err ? "Unable to generate PDF preview!" : "Loading..."}</h1>
      )}
    </Modal>
  );
}
