import { useLocation } from "react-router-dom";
import { PATH } from "../../path";
import {
  hasValidCustomerInfo,
  hasSelectedTract,
} from "../../common/order-validation";
import { ChooseYourTract } from "./choose-your-tract";
import { GetStarted } from "./get-started";
import { STEP, StepProps } from "./interfaces";
import { WhoIsMailing } from "./who-is-mailing";
import { WhoIsReceiving } from "./who-is-receiving";
import { withRequiredOrderInformation } from "./with-required-order-information";

export const steps: Array<{
  path: STEP;
  Component: React.ComponentType<StepProps>;
}> = [
  { path: STEP.GET_STARTED, Component: GetStarted },
  {
    path: STEP.WHO_IS_MAILING,
    Component: WhoIsMailing,
  },
  {
    path: STEP.CHOOSE_YOUR_TRACT,
    Component: withRequiredOrderInformation(
      hasValidCustomerInfo,
      ChooseYourTract
    ),
  },
  {
    path: STEP.WHO_IS_RECEIVING,
    Component: withRequiredOrderInformation(hasSelectedTract, WhoIsReceiving),
  },
];

export function useGetStepsHook(): Record<
  "prevStep" | "currentStep" | "nextStep",
  STEP | PATH
> {
  const location = useLocation();
  const currentStep =
    location.pathname === PATH.HOME
      ? STEP.GET_STARTED
      : (location.pathname.split("/") as STEP[])[2];
  const stepIndex = steps.findIndex((step) => step.path === currentStep);
  const [nextStep, prevStep] =
    stepIndex === -1
      ? [STEP.GET_STARTED, STEP.GET_STARTED]
      : [
          stepIndex < steps.length - 1
            ? steps[stepIndex + 1].path
            : PATH.ORDER_REVIEW,
          stepIndex === 0 ? STEP.GET_STARTED : steps[stepIndex - 1].path,
        ];

  return {
    prevStep,
    currentStep,
    nextStep,
  };
}
