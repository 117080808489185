import React from "react";
import { Address } from "../../../server/src/contracts/address";
import { MAX_LENGTH } from "../input-limits";
import { COUNTRY, CountrySelect } from "../pages/home/country-select";
import { countryOptions, StateSelect } from "../pages/home/state-select";
import "./billing-form.css";
import { TextInput } from "./text-input";

function useTextInputHook({
  addressKey,
  onChange,
}: {
  addressKey: keyof Address;
  onChange: BillingFormProps["onChange"];
}): React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> {
  const handleChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = React.useCallback(
    (e) => {
      onChange({
        [addressKey]: e.target.value,
      });
    },
    [onChange, addressKey]
  );

  return handleChange;
}

interface BillingFormProps {
  isDisabled?: boolean;
  billing: Address;
  onChange: (addressUpdate: Partial<Address>) => void;
}

export function BillingForm({
  isDisabled = false,
  billing,
  onChange,
}: BillingFormProps) {
  const handleAddress1Change = useTextInputHook({
    addressKey: "address1",
    onChange,
  });
  const handleAddress2Change = useTextInputHook({
    addressKey: "address2",
    onChange,
  });
  const handleCityChange = useTextInputHook({
    addressKey: "city",
    onChange,
  });
  const handleZipChange = useTextInputHook({
    addressKey: "zip",
    onChange,
  });

  const setState = React.useCallback(
    (newState: string) => {
      onChange({ state: newState });
    },
    [onChange]
  );

  const setCountry = React.useCallback(
    (newCountry: string) => {
      onChange({
        country: newCountry,
        state: countryOptions[newCountry as COUNTRY][0],
      });
    },
    [onChange]
  );

  return (
    <div className="billing-form rounded u-flex u-flexWrap u-flexJustifyBetween u-flexAlignItemsCenter">
      <TextInput
        id="billing-address-1"
        placeholder="Address 1"
        className="u-sizeFull"
        inputProps={{ disabled: isDisabled }}
        maxLength={MAX_LENGTH.Address1}
        value={billing.address1}
        onChange={handleAddress1Change}
      />
      <TextInput
        id="billing-address-2"
        placeholder="Address 2"
        className="u-sizeFull"
        inputProps={{ disabled: isDisabled }}
        maxLength={MAX_LENGTH.Address2}
        value={billing.address2}
        onChange={handleAddress2Change}
      />
      <CountrySelect
        country={billing.country}
        id="billing"
        onCountryChange={setCountry}
      />
      <TextInput
        id="billing-city"
        placeholder="City"
        className="u-sizeFill"
        inputProps={{ disabled: isDisabled }}
        maxLength={MAX_LENGTH.City}
        value={billing.city}
        onChange={handleCityChange}
      />
      <div className="u-sm-hidden billing-form__spacer" />
      <StateSelect
        isDisabled={isDisabled}
        className="u-size1of4"
        state={billing.state}
        country={billing.country as COUNTRY}
        onStateChange={setState}
        id="billing"
      />
      <div className="u-sm-hidden billing-form__spacer" />
      <TextInput
        id="billing-zip"
        placeholder="Zip"
        className="u-size1of4"
        inputProps={{
          disabled: isDisabled,
          inputMode: billing.country === COUNTRY.US ? "numeric" : undefined,
          pattern: billing.country === COUNTRY.US ? "[0-9]{5}" : undefined,
        }}
        maxLength={MAX_LENGTH.PostalCode}
        value={billing.zip}
        onChange={handleZipChange}
      />
      <div className="rule rule--marginBottom rule--marginTop rule--transparent u-sizeFull" />
    </div>
  );
}
