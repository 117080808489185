import React from "react";
import { SummaryTitle } from "../../common/summary-title";
import { Link } from "react-router-dom";
import { PATH } from "../../path";
import { BulkPrice } from "../../../../server/src/contracts/bulk-price";

function getBulkOptionsSentence(bulkPrices: BulkPrice[]): string {
  function getPrefix(index: number): string {
    if (index === 0) {
      return "";
    } else if (index + 1 === bulkPrices.length) {
      return ", or ";
    } else {
      return ", ";
    }
  }
  return bulkPrices.reduce(
    (sentence, bulkPrice, index) =>
      sentence + getPrefix(index) + bulkPrice.displayQuantity,
    ""
  );
}

export function TractPackPrompt({ bulkPrices }: { bulkPrices: BulkPrice[] }) {
  return (
    <>
      <SummaryTitle>Add Tracts to keep in your car or bag</SummaryTitle>
      <p>
        Buy a pack of {getBulkOptionsSentence(bulkPrices)} of our primary Gospel
        tract.
      </p>
      <Link to={PATH.PACK}>
        <button className="button button--secondary">Shop Tract Packs</button>
      </Link>
    </>
  );
}
