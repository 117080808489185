import React from "react";
import "./light-header.css";

interface LightHeaderProps {
  isCTA?: boolean;
  title: string | React.ReactElement;
  subTitle: string;
}

export function LightHeader({ isCTA, title, subTitle }: LightHeaderProps) {
  return (
    <div className={`light-header${isCTA ? " light-header--cta" : ""}`}>
      {typeof title === "string" ? <h2>{title}</h2> : title}
      <p
        className={`serif-heading ${isCTA ? "u-text-lightGray" : "u-opacity8"}`}
      >
        {subTitle}
      </p>
    </div>
  );
}
