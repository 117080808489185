import React from "react";
import { API } from "../../../path";
import { State, useLazyFetchHook } from "../../../utils/use-fetch-hook";

function stringify<Thing>(value: Thing): Thing | string {
  if (value instanceof File) {
    return value;
  } else if (value instanceof Object) {
    return JSON.stringify(value);
  } else {
    return value;
  }
}

export function useFormUploadHook<UploadData, ResponseType>(
  api: API,
  id?: string
): [State<UploadData, ResponseType>, (data: UploadData) => void] {
  const [uploadState, callApi] = useLazyFetchHook<UploadData, ResponseType>();

  const uploadForm = React.useCallback(
    (formUpload: UploadData) => {
      const formData = Object.entries(formUpload).reduce(
        (acc, [key, value]) => {
          if (value !== undefined) {
            if (value instanceof File) {
              acc.append(key, value);
            }
            if (value instanceof Array) {
              value.forEach((v) => {
                acc.append(key, stringify(v));
              });
            } else {
              acc.append(key, stringify(value));
            }
          }
          return acc;
        },
        new FormData()
      );

      if (id) {
        formData.append("id", id);
      }

      callApi({
        url: api,
        method: "post",
        formData,
        headers: {},
      });
    },
    [callApi, api, id]
  );

  return [uploadState, uploadForm];
}
