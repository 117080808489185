import React from "react";
import { Products } from "../home/interfaces";
import { RecipientUpdate } from "../../interfaces";
import { AddOnPreview } from "./add-on-preview";
import { RecipientReview } from "./recipient-review";
import { TractThumbnail } from "../../common/tract/thumbnail";
import { ShippingSelector } from "./shipping-selector";
import { TractOrder } from "../../../../server/src/contracts/payment-intent";
import { RecipientPayload } from "../../../../server/src/contracts/recipient";
import { Tract } from "../../../../server/src/contracts/tract";
import { PreviewContainer } from "./preview";
import { ShippingRate } from "../../../../server/src/contracts/shipping";
import { useGetShippingRatesHook } from "./use-get-shipping-rates-hook";

interface RecipientPreviewProps {
  products: Products;
  tract: Tract;
  recipient: RecipientPayload;
  tractOrder: TractOrder;
  onRecipientUpdate: (recipientUpdate: RecipientUpdate) => void;
  onRemoveRecipient: (tractId: string, recipientId: string) => void;
}

export function RecipientPreview({
  products,
  tract,
  recipient,
  tractOrder,
  onRecipientUpdate,
  onRemoveRecipient,
}: RecipientPreviewProps) {
  const [isEditing, setIsEditing] = React.useState(false);
  const toggleEditing = React.useCallback(() => {
    setIsEditing((p) => !p);
  }, [setIsEditing]);

  const handleShippingChange = React.useCallback(
    (shippingRate: ShippingRate) => {
      onRecipientUpdate({
        tractId: tract.id,
        recipientId: recipient.id,
        partialRecipient: {
          shippingServiceType: shippingRate.serviceType,
          shippingCost: shippingRate.shipmentCost,
          displayShippingCost: shippingRate.displayShipmentCost,
        },
      });
    },
    [onRecipientUpdate, tract.id, recipient.id]
  );

  const clearShippingRate = React.useCallback(() => {
    onRecipientUpdate({
      tractId: tract.id,
      recipientId: recipient.id,
      partialRecipient: {
        shippingServiceType: undefined,
        shippingCost: undefined,
        displayShippingCost: undefined,
      },
    });
  }, [onRecipientUpdate, tract.id, recipient.id]);

  const addOn = recipient.addOnId
    ? products.addOns.find(({ id }) => recipient.addOnId === id)
    : null;
  const addOnOunces = addOn ? addOn.ounces : 0;
  const shippingRatesState = useGetShippingRatesHook(
    recipient,
    1,
    addOnOunces,
    clearShippingRate
  );

  return (
    <PreviewContainer>
      <RecipientReview
        recipient={recipient}
        isEditing={isEditing}
        toggleEditing={toggleEditing}
        onRemove={onRemoveRecipient}
        onChange={onRecipientUpdate}
        tractId={tractOrder.tractId}
      />
      <div className="rule rule--gray u-marginVert3gu" />
      <div className="u-flex u-flexAlignItemsCenter">
        <TractThumbnail {...tract} />
        <p>
          {tract.title}: {tract.subTitle}
        </p>
        <p className="u-price u-flexExpandLeft u-paddingLeft2gu">
          {tract.displayPrice}
        </p>
      </div>
      {addOn && (
        <AddOnPreview
          addOn={addOn}
          recipientId={recipient.id}
          tractId={tract.id}
          onRecipientUpdate={onRecipientUpdate}
        />
      )}
      {!isEditing && (
        <ShippingSelector
          key={recipient.zip}
          onShippingChange={handleShippingChange}
          shippingRatesState={shippingRatesState}
          idPrefix={`recipient-${recipient.id}`}
          shippingCost={recipient.shippingCost}
          shippingServiceType={recipient.shippingServiceType}
        />
      )}
    </PreviewContainer>
  );
}
