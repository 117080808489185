import React from "react";
import { API } from "../../../../path";
import { useLazyFetchHook } from "../../../../utils/use-fetch-hook";
import { AddOn } from "../../../home/interfaces";
import { AddOnEditor } from "./add-on-editor";
import { AddOnTable } from "./add-on-table";
import { TabProps } from "../interfaces";

interface AddOnId {
  addOnId: AddOn["id"];
}

export function ManageAddOns({ products, refetch }: TabProps) {
  const [isAddingAddOn, setIsAddingAddOn] = React.useState(false);
  const [editingAddOn, setIsEditingAddOn] = React.useState<AddOn>();
  const [, deleteAddOn] = useLazyFetchHook<AddOnId, never>();

  const handleDelete = React.useCallback(
    (addOnId: string) => {
      deleteAddOn({
        method: "delete",
        url: API.ADD_ON,
        body: { addOnId },
      });
      refetch();
    },
    [deleteAddOn, refetch],
  );

  const handleEdit = React.useCallback(
    (addOnId: string) => {
      const addOn = products.addOns.find((t) => t.id === addOnId);
      if (addOn) {
        setIsEditingAddOn(addOn);
      }
    },
    [setIsEditingAddOn, products],
  );

  if (isAddingAddOn) {
    return (
      <AddOnEditor
        onCancel={() => {
          setIsAddingAddOn(false);
        }}
        onDone={() => {
          setIsAddingAddOn(false);
          refetch();
        }}
      />
    );
  }

  if (editingAddOn) {
    return (
      <AddOnEditor
        addOn={editingAddOn}
        onCancel={() => {
          setIsEditingAddOn(undefined);
        }}
        onDone={() => {
          setIsEditingAddOn(undefined);
          refetch();
        }}
      />
    );
  }

  return (
    <>
      <div className="u-flex u-flexAlignItemsCenter u-sizeFull">
        <h3>Add Ons</h3>
        <button
          className="button button--small button--primary"
          onClick={() => {
            setIsAddingAddOn(true);
          }}
        >
          Add Add On
        </button>
      </div>
      <div>
        {products.addOns.length > 0 ? (
          <AddOnTable
            addOns={products.addOns}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        ) : (
          <h4>No add ons!</h4>
        )}
      </div>
    </>
  );
}
