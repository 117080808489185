import React from "react";
import Modal from "react-modal";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { createPersonalizedMessage } from "./pages/home/who-is-receiving/create-personalized-message";

interface PersonalizedMessageModalProps {
  isImage?: boolean;
  message: string;
  orderNumber?: string;
  onRequestClose: () => void;
}

export function PersonalizedMessageModal({
  isImage = false,
  message,
  orderNumber,
  onRequestClose,
}: PersonalizedMessageModalProps): JSX.Element {
  const [err, setError] = React.useState(false);
  const [blobUrl, setBlobUrl] = React.useState<URL | null>(null);

  React.useEffect(() => {
    const newBlobUrl = createPersonalizedMessage(message, orderNumber);
    if (newBlobUrl) {
      setBlobUrl(newBlobUrl);
    } else {
      setError(true);
    }
  }, [message, orderNumber]);

  const heightFromWindow = window.innerHeight * 0.75;
  const widthFromHeightFromWindow = (heightFromWindow * 2) / 3;
  const maxWidth = window.innerWidth - 80;
  const width =
    widthFromHeightFromWindow < maxWidth ? widthFromHeightFromWindow : maxWidth;
  const height =
    widthFromHeightFromWindow < maxWidth
      ? heightFromWindow
      : (maxWidth * 3) / 2;

  return (
    <Modal
      isOpen
      onRequestClose={onRequestClose}
      style={{
        content: {
          display: "flex",
          flexDirection: "column",
          padding: 0,
          border: 0,
          width,
          height,
          margin: "0 auto",
        },
        overlay: {
          zIndex: 2,
          background: "rgba(82, 82, 82, 0.75)",
        },
      }}
      contentLabel="Personalized Message Preview"
    >
      {blobUrl && isImage ? (
        <Document file={blobUrl}>
          <Page pageNumber={1} height={height} />
        </Document>
      ) : blobUrl ? (
        <iframe
          src={blobUrl.toString()}
          title="Personalize Message Preview"
          height="100%"
        />
      ) : (
        <h1>{err ? "Unable to generate PDF preview!" : "Loading..."}</h1>
      )}
    </Modal>
  );
}
