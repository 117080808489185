import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import {
  OrderInformation,
  CreatePaymentIntentResponse,
  UpdatePaymentIntentPayload,
} from "../../../../server/src/contracts/payment-intent";
import { stripePromise } from "../../utils/stripe";
import { API, PATH } from "../../path";
import { useLightTheme } from "../home/light-theme";
import { STATUS, useLazyFetchHook } from "../../utils/use-fetch-hook";
import { CheckoutForm } from "./checkout-form";
import { PageProps } from "../../interfaces";
import { useGetProductsHook } from "../../common/product-service";
import { useNavigate } from "react-router-dom";
import { withFullPageRibbon } from "../../common/full-page";
import { isReadyForCheckout } from "../../common/order-validation";

function CheckoutBase({
  setTheme,
  orderInformation,
  paymentIntent,
  setPaymentIntent,
  setOrderInformation,
}: PageProps) {
  const productsState = useGetProductsHook();
  useLightTheme(setTheme);
  const navigate = useNavigate();

  const [initialPaymentIntent] = React.useState(() => paymentIntent);
  const [initialOrderInformation] = React.useState(() => orderInformation);

  const [paymentIntentCreation, createPaymentIntent] = useLazyFetchHook<
    OrderInformation,
    CreatePaymentIntentResponse
  >();

  const [paymentIntentUpdate, updatePaymentIntent] = useLazyFetchHook<
    UpdatePaymentIntentPayload,
    CreatePaymentIntentResponse
  >();

  React.useEffect(() => {
    if (!initialPaymentIntent) {
      createPaymentIntent({
        method: "post",
        url: API.PAYMENT_INTENT,
        body: { ...initialOrderInformation },
      });
    } else {
      updatePaymentIntent({
        method: "put",
        url: API.PAYMENT_INTENT,
        body: {
          ...initialOrderInformation,
          transactionId: initialPaymentIntent.transactionId,
          clientSecret: initialPaymentIntent.clientSecret,
          paymentIntentId: initialPaymentIntent.paymentIntentId,
        },
      });
    }
  }, [
    initialPaymentIntent,
    initialOrderInformation,
    updatePaymentIntent,
    createPaymentIntent,
  ]);

  React.useEffect(() => {
    if (paymentIntentCreation.status === STATUS.DONE) {
      setPaymentIntent(paymentIntentCreation.payload);
    }
  }, [paymentIntentCreation, setPaymentIntent]);

  React.useEffect(() => {
    if (paymentIntentUpdate.status === STATUS.DONE) {
      setPaymentIntent(paymentIntentUpdate.payload);
    }
  }, [paymentIntentUpdate, setPaymentIntent]);

  React.useEffect(() => {
    if (!isReadyForCheckout(initialOrderInformation)) {
      navigate(PATH.ORDER_REVIEW, { replace: true });
    }
  }, [initialOrderInformation, navigate]);

  const products =
    productsState.status === STATUS.DONE ? productsState.payload : undefined;

  if (productsState.status === STATUS.LOADING) {
    return <h3 className="u-text-offWhite step-header">Loading tracts...</h3>;
  }

  if (
    productsState.status === STATUS.ERROR ||
    paymentIntentCreation.status === STATUS.ERROR ||
    paymentIntentUpdate.status === STATUS.ERROR ||
    !products
  ) {
    return (
      <h3 className="u-text-black step-header">Uh oh! Something went wrong!</h3>
    );
  }

  if (
    !paymentIntent ||
    paymentIntentCreation.status === STATUS.LOADING ||
    paymentIntentUpdate.status === STATUS.LOADING
  ) {
    return <h3 className="u-text-black step-header">Loading...</h3>;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{ clientSecret: paymentIntent.clientSecret }}
    >
      <CheckoutForm
        orderInformation={orderInformation}
        setOrderInformation={setOrderInformation}
        paymentIntentResponse={paymentIntent}
        products={products}
      />
    </Elements>
  );
}

export const Checkout = withFullPageRibbon(CheckoutBase, PATH.CHECKOUT);
