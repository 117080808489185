enum SOCIAL_LINK {
  INSTAGRAM = "https://www.instagram.com/mailthegospel/",
  FACEBOOK = "https://facebook.com/relearn.org",
  TWITTER = "https://twitter.com/relearnhq",
  YOUTUBE = "https://www.youtube.com/channel/UCDjsrE6l1g7pP99EGmSlyxg",
  TIK_TOK = "http://www.tiktok.com/@mailthegospel",
}

interface SocialMediaLink {
  alt: string;
  src: string;
  href: SOCIAL_LINK;
}

export const socialMediaLinks: SocialMediaLink[] = [
  {
    alt: "Instagram",
    src: "/assets/social/instagram.png",
    href: SOCIAL_LINK.INSTAGRAM,
  },
  {
    alt: "YouTube",
    src: "/assets/social/youtube.png",
    href: SOCIAL_LINK.YOUTUBE,
  },
  {
    alt: "Facebook",
    src: "/assets/social/facebook.png",
    href: SOCIAL_LINK.FACEBOOK,
  },
  {
    alt: "Twitter",
    src: "/assets/social/twitter.png",
    href: SOCIAL_LINK.TWITTER,
  },
  {
    alt: "Tik-Tok",
    src: "/assets/social/tik-tok.png",
    href: SOCIAL_LINK.TIK_TOK,
  },
];
