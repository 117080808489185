import React from "react";
import Modal from "react-modal";
import { apiHost } from "../../../api-host";
import { STATUS } from "../../../utils/use-fetch-hook";
import { useMultiFileReader } from "../../../utils/use-file-reader";
import { useToggleHook } from "../../../utils/use-toggle-hook";
import { GalleryThumbnail } from "./gallery-thumbnail";
import { useModalSizeHook } from "./use-modal-size-hook";
import "./image-gallery.css";

export interface ThumbnailProps {
  index: number;
  type: "file" | "image";
}

interface TractImageGalleryProps {
  images: string[];
  className?: string;
  files?: File[];
  Thumbnail?: React.ComponentType<ThumbnailProps>;
  ThumbnailWrapper?: React.ComponentType<{ children: React.ReactNode }>;
}

const smallControlHeight = 70;
const prevNextSize = 60;

export function TractImageGallery({
  images,
  className,
  files = [],
  Thumbnail,
  ThumbnailWrapper = React.Fragment,
}: TractImageGalleryProps) {
  const [isModalOpen, toggleModalOpen, setIsModalOpen] = useToggleHook(false);
  const [imageIndex, setImageIndex] = React.useState<number | null>(null);
  const [fileIndex, setFileIndex] = React.useState<number | null>(null);

  const fileReaders = useMultiFileReader(files);

  const { size, isSmall } = useModalSizeHook();

  const viewImage = React.useCallback(
    (nextImageIndex: number) => {
      setImageIndex(nextImageIndex);
      setFileIndex(null);
      setIsModalOpen(true);
    },
    [setImageIndex, setIsModalOpen]
  );

  const viewFile = React.useCallback(
    (nextFileIndex: number) => {
      setImageIndex(null);
      setFileIndex(nextFileIndex);
      setIsModalOpen(true);
    },
    [setImageIndex, setIsModalOpen]
  );

  const viewPrevious = React.useCallback(() => {
    if (imageIndex !== null) {
      if (imageIndex === 0) {
        if (files.length !== 0) {
          setImageIndex(null);
          setFileIndex(files.length - 1);
        } else {
          setImageIndex(images.length - 1);
        }
      } else {
        setImageIndex(imageIndex - 1);
      }
    } else if (fileIndex !== null) {
      if (fileIndex === 0) {
        if (images.length !== 0) {
          setImageIndex(images.length - 1);
          setFileIndex(null);
        } else {
          setFileIndex(files.length - 1);
        }
      } else {
        setFileIndex(fileIndex - 1);
      }
    } else if (images.length !== 0) {
      setImageIndex(0);
      setFileIndex(null);
    } else if (files.length !== 0) {
      setImageIndex(null);
      setFileIndex(0);
    } else {
      setImageIndex(null);
      setFileIndex(null);
    }
  }, [setImageIndex, setFileIndex, imageIndex, fileIndex, images, files]);

  const viewNext = React.useCallback(() => {
    if (imageIndex !== null) {
      if (imageIndex === images.length - 1) {
        if (files.length !== 0) {
          setImageIndex(null);
          setFileIndex(0);
        } else {
          setImageIndex(0);
        }
      } else {
        setImageIndex(imageIndex + 1);
      }
    } else if (fileIndex !== null) {
      if (fileIndex === files.length - 1) {
        if (images.length !== 0) {
          setImageIndex(0);
          setFileIndex(null);
        } else {
          setFileIndex(0);
        }
      } else {
        setFileIndex(fileIndex + 1);
      }
    } else if (images.length !== 0) {
      setImageIndex(0);
      setFileIndex(null);
    } else if (files.length !== 0) {
      setImageIndex(null);
      setFileIndex(0);
    } else {
      setImageIndex(null);
      setFileIndex(null);
    }
  }, [setImageIndex, setFileIndex, imageIndex, fileIndex, images, files]);

  const selectedFileIndexReaderStatus =
    fileIndex !== null ? fileReaders[fileIndex] : null;
  const selectedFileIndexReaderResult =
    selectedFileIndexReaderStatus &&
    selectedFileIndexReaderStatus.status === STATUS.DONE
      ? selectedFileIndexReaderStatus.result
      : null;

  const imageSrc =
    imageIndex !== null && images[imageIndex]
      ? apiHost + images[imageIndex]
      : selectedFileIndexReaderResult;
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toggleModalOpen}
        style={{
          content: {
            display: "flex",
            flexDirection: "column",
            padding: 0,
            width: size,
            height: isSmall ? size + smallControlHeight : size,
            margin: "auto",
            zIndex: 4,
            border: "2px solid var(--white)",
            overflow: "inherit",
            inset: 0,
          },
          overlay: {
            background: "rgba(82, 82, 82, 0.75)",
            zIndex: 4,
          },
        }}
      >
        <button
          onClick={toggleModalOpen}
          className="button--close button--close-invert"
          style={{
            position: "absolute",
            right: -21,
            top: -21,
            zIndex: 4,
            backgroundColor: "var(--white)",
            borderRadius: "50%",
          }}
        />
        {imageSrc ? <img src={imageSrc} alt="" /> : <>Loading or Error</>}
        {isSmall ? (
          <div
            className="u-flex u-flexJustifyBetween"
            style={{
              height: smallControlHeight,
              padding: "5px 20px",
            }}
          >
            <button onClick={viewPrevious} className="button button--left" />
            <button onClick={viewNext} className="button button--right" />
          </div>
        ) : (
          <>
            <button
              onClick={viewPrevious}
              className="button button--left u-invert"
              style={{
                position: "absolute",
                top: `calc(50% - ${prevNextSize / 2}px)`,
                left: -100,
                overflow: "visible",
                height: prevNextSize,
                width: prevNextSize,
              }}
            />
            <button
              onClick={viewNext}
              className="button button--right u-invert"
              style={{
                position: "absolute",
                top: `calc(50% - ${prevNextSize / 2}px)`,
                right: -100,
                overflow: "visible",
                height: prevNextSize,
                width: prevNextSize,
              }}
            />
          </>
        )}
      </Modal>
      <div className={`u-flex u-flexJustifyBetween image-gallery ${className}`}>
        {images.map((src, index) => (
          <ThumbnailWrapper key={src + index}>
            <button className="image-gallery__thumbnail-button">
              <img
                src={apiHost + src}
                alt=""
                className="image-gallery__thumbnail"
                onClick={() => {
                  viewImage(index);
                }}
              />
            </button>
            {Thumbnail && <Thumbnail index={index} type="image" />}
          </ThumbnailWrapper>
        ))}
        {files?.map((_, index) => (
          <GalleryThumbnail
            key={index}
            readerResult={fileReaders[index]}
            onClick={() => {
              viewFile(index);
            }}
            index={index}
            Thumbnail={Thumbnail}
            ThumbnailWrapper={ThumbnailWrapper}
          />
        ))}
      </div>
    </>
  );
}
