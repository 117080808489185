import React from "react";
import { ThumbnailProps } from ".";
import { STATUS } from "../../../utils/use-fetch-hook";
import { FileReaderOutput } from "../../../utils/use-file-reader";

interface GalleryThumbnailProps {
  readerResult: FileReaderOutput;
  onClick: () => void;
  index: number;
  Thumbnail?: React.ComponentType<ThumbnailProps>;
  ThumbnailWrapper: React.ComponentType<{ children: React.ReactNode }>;
}

export function GalleryThumbnail({
  readerResult,
  onClick,
  index,
  Thumbnail,
  ThumbnailWrapper,
}: GalleryThumbnailProps) {
  return (
    <ThumbnailWrapper>
      <button className="image-gallery__thumbnail-button">
        {readerResult.status === STATUS.DONE ? (
          <img
            onClick={onClick}
            alt=""
            className="image-gallery__thumbnail"
            src={readerResult.result}
          />
        ) : readerResult.status === STATUS.ERROR ? (
          <>ERROR!</>
        ) : (
          <>LOADING</>
        )}
      </button>
      {Thumbnail && <Thumbnail index={index} type="file" />}
    </ThumbnailWrapper>
  );
}
