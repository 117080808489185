import React from "react";
import { Tract } from "../../../../server/src/contracts/tract";

export function TractTitle({ title, subTitle }: Tract) {
  const [begin, end] = subTitle.split("of");
  return (
    <p className="tract-title" style={{ margin: "6px 30px 40px" }}>
      {title}: {begin}
      {end ? (
        <>
          <i>of </i> {end}
        </>
      ) : (
        ""
      )}
    </p>
  );
}
