import React from "react";
import { API } from "../../path";
import { useUserState } from "../../user.context";
import { isStringAnEmail } from "../../utils/email";
import { STATUS, useLazyFetchHook } from "../../utils/use-fetch-hook";
import { TextInput } from "../../common/text-input";
import { useTextInputHook } from "../../common/use-text-input-hook";

interface SignInPayload {
  email: string;
  password: string;
}

interface SignInResponse {
  success: boolean;
}

export function SignInForm() {
  const userState = useUserState();
  const [email, setEmail] = useTextInputHook();
  const [password, setPassword] = useTextInputHook();
  const [hasDismissedError, setHasDismissedError] = React.useState(false);

  const [signInState, signIn] = useLazyFetchHook<
    SignInPayload,
    SignInResponse
  >();

  const isSuccess =
    signInState.status === STATUS.DONE && signInState.payload.success;
  React.useEffect(() => {
    if (isSuccess && email) {
      userState.update(email);
    }
  }, [isSuccess, userState, email]);

  const hasError =
    signInState.status === STATUS.ERROR ||
    (signInState.status === STATUS.DONE && !signInState.payload.success);
  React.useEffect(() => {
    if (hasError) {
      setHasDismissedError(false);
    }
  }, [hasError, setHasDismissedError]);

  const isLoading = signInState.status === STATUS.LOADING;
  return (
    <div className="rounded-section u-flexExpandBottom u-flexExpandTop u-flexExpand u-textCenter">
      <h4>Sign In</h4>
      {!hasDismissedError && hasError && (
        <div
          className="u-flex u-flexAlignItemsCenter notification notification--warning"
          style={{
            margin: "0px -20px 35px",
            width: "calc(100% + 40px)",
          }}
        >
          <div>Incorrect email or password</div>
          <button
            onClick={() => {
              setHasDismissedError(true);
            }}
            className="button--close u-flexExpandLeft"
          />
        </div>
      )}
      <TextInput
        inputProps={{ disabled: isLoading }}
        id="email"
        placeholder="Email"
        className="u-marginBottom4gu"
        onChange={setEmail}
        value={email}
      />
      <TextInput
        inputProps={{ disabled: isLoading }}
        id="password"
        type="password"
        placeholder="Password"
        className="u-marginBottom4gu"
        onChange={setPassword}
        value={password}
      />
      <button
        disabled={!email || !isStringAnEmail(email) || !password || isLoading}
        onClick={() => {
          signIn({
            method: "post",
            url: API.SIGN_IN,
            body: { email, password },
          });
        }}
        className="button button--primary"
      >
        Sign In
      </button>
    </div>
  );
}
