import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import { PageProps } from "../../interfaces";
import { ADMIN_PANEL_PATH } from "../../path";
import { useUserState } from "../../user.context";
import { useLightTheme } from "../home/light-theme";
import "../home/light-theme.css";
import { AdminNav } from "./admin-nav";
import { BulkGospel } from "./bulk-gospel";
import { CustomLiteratureTable } from "./custom-literature-table";
import { Manage } from "./manage";
import { SignInForm } from "./sign-in-form";
import { Stamps } from "./stamps";
import { ViewOrders } from "./view-orders";
import { Evangelists } from "./evangelists";
import { Donations } from "./donations";

const FOOTER_HEIGHT = 56;

function Container({
  children,
}: {
  children: React.ReactElement | React.ReactElement[];
}) {
  return (
    <div
      className="u-flex u-light u-sizeFull"
      style={{
        margin: "auto",
        overflow: "hidden",
        minHeight: `calc(100vh - ${FOOTER_HEIGHT}px`,
      }}
    >
      {children}
    </div>
  );
}

function usePageTitle(title: string): void {
  React.useEffect(() => {
    document.title = title;
  }, [title]);
}

const hasNewFeatures = localStorage.getItem("has-new-features") === "true";

export function AdminPanel({ setTheme }: PageProps) {
  usePageTitle("Mail the Gospel - Admin Panel");
  useLightTheme(setTheme);
  const userState = useUserState();

  if (!userState.email) {
    return (
      <Container>
        <SignInForm />
      </Container>
    );
  }
  return (
    <Container>
      <AdminNav hasNewFeatures={hasNewFeatures} />
      <Routes>
        <Route path={ADMIN_PANEL_PATH.MANAGE} element={<Manage />} />
        <Route path={ADMIN_PANEL_PATH.BULK_GOSPEL} element={<BulkGospel />} />
        <Route path={ADMIN_PANEL_PATH.ORDERS} element={<ViewOrders />} />
        <Route path={ADMIN_PANEL_PATH.STAMPS} element={<Stamps />} />
        {hasNewFeatures && (
          <>
            <Route
              path={ADMIN_PANEL_PATH.EVANGELISTS + "/*"}
              element={<Evangelists />}
            />
            <Route path={ADMIN_PANEL_PATH.DONATIONS} element={<Donations />} />
          </>
        )}
        <Route
          path={ADMIN_PANEL_PATH.CUSTOM_LITERATURE}
          element={<CustomLiteratureTable />}
        />
        <Route
          path="/"
          element={<Navigate to={ADMIN_PANEL_PATH.MANAGE} replace />}
        />
        <Route path="*" element={<h1>Page not found!</h1>} />
      </Routes>
    </Container>
  );
}
